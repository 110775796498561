import { connect } from 'react-redux';
import { setLeague, setServerTime, addMessage, setNetworkError, setContentLoading, setAppLoading } from '../../actions';
import mapToComponent from './HighscoresComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league
});

const mapDispatchToProps = dispatch => ({
  setServerTime: serverTime => dispatch(setServerTime(serverTime)),
  setLeague: league => dispatch(setLeague(league)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  setAppLoading: loading => dispatch(setAppLoading(loading)),
  setContentLoading: loading => dispatch(setContentLoading(loading))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
