import React from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Config from '../../../config';
import API from '../../../util/API';
import AvatarData from '../../../util/AvatarData';

// console.log(AvatarData);

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    const { auth } = this.props;

    this.state = {
      AvatarUser: auth && auth.avatar ? JSON.parse(auth.avatar) : AvatarData.AvatarDefault,
      Avatar: {},
      AvatarTemp: {}
    };
  }

  componentDidMount() {

    const { auth } = this.props;

    if (!auth) {
      return;
    }

    this.xmlns = 'http://www.w3.org/2000/svg';
    this.AvatarSVG = document.createElementNS(this.xmlns, 'svg');

    this.AvatarSVG.setAttributeNS(null, 'viewBox', '0 0 170 170');
    document.getElementById('avatar-preview').appendChild(this.AvatarSVG);

    this.createAvatar();
  }

  getColumns(array, nr) {
    let arrayClone = array.slice(0);
    let columns = [];

    while (arrayClone.length > 0) {
      columns.push(arrayClone.splice(0, nr || 3));
    }
    return columns;
  }

  // Create the initial avatar

  createAvatar() {

    let AvatarElements = AvatarData.AvatarElements;
    let AvatarDefault = AvatarData.AvatarDefault;
    let AvatarUser = this.state.AvatarUser;

    // Head
    this.createAvatarElement(AvatarElements.Neck[AvatarUser.Neck], 'Neck');
    this.createAvatarElement(AvatarElements.Head[AvatarUser.Head], 'Head');

    // Features
    this.createAvatarElement(AvatarElements.Freckles[AvatarUser.Freckles], 'Freckles');
    this.createAvatarElement(AvatarElements.Wrinkles[AvatarUser.Wrinkles], 'Wrinkles');
    this.createAvatarElement(AvatarElements.Spots[AvatarUser.Spots], 'Spots');

    // Face
    this.createAvatarElement(AvatarElements.Rouge[AvatarUser.Rouge || AvatarDefault.Rouge], 'Rouge');
    this.createAvatarElement(AvatarElements.Eyeshadow[AvatarUser.Eyeshadow || AvatarDefault.Eyeshadow], 'Eyeshadow');
    this.createAvatarElement(AvatarElements.Eyes[AvatarUser.Eyes], 'Eyes');
    this.createAvatarElement(AvatarElements.Eyebrows[AvatarUser.Eyebrows], 'Eyebrows');
    this.createAvatarElement(AvatarElements.Eyeliner[AvatarUser.Eyeliner || AvatarDefault.Eyeliner], 'Eyeliner');
    this.createAvatarElement(AvatarElements.Mouth[AvatarUser.Mouth], 'Mouth');
    this.createAvatarElement(AvatarElements.Glasses[AvatarUser.Glasses || AvatarDefault.Glasses], 'Glasses');

    // Shart and beard, shirt needs to be before beard
    this.createAvatarElement(AvatarElements.Shirt[AvatarUser.Shirt], 'Shirt');
    this.createAvatarElement(AvatarElements.Beard[AvatarUser.Beard || AvatarDefault.Beard], 'Beard');

    // Create hair
    this.createAvatarElement(AvatarElements.Hair[AvatarUser.Hair], 'Hair');

    // Create gradients
    this.createAvatarGradient('ShirtGradient');
    this.createAvatarGradient('HairGradient');
    this.createAvatarGradient('RougeGradient', {
      type: 'radialGradient',
      'stop-color1': '#ff66ff',
      'stop-color2': '#ff66ff'
    });
    this.createAvatarGradient('EyeshadowGradient');

    // Create the shirt colors html, then set colors
    // createShirtColors();
    this.setShirtColors();

    // Set Colors
    this.setHairColor();
    this.setSkinColor();
    this.setEyeshadowColor();
    this.setEyeshadowOpacity();
    this.setRougeOpacity();
  }

  setSex(sex) {
    let Avatar = this.state.Avatar;
    let AvatarUser = this.state.AvatarUser;

    sex = sex || AvatarUser.Sex;
    sex = (sex === 'swap') ? (AvatarUser.Sex === 'm' ? 'f' : 'm') : sex;

    AvatarUser.Sex = sex;

    if (Avatar.Shirt.Elements['Shirt' + AvatarUser.Shirt]['Type'] !== sex) {
      this.setAvatarElement('Shirt', sex === 'm' ? 1 : 2);
    }
    if (Avatar.Hair.Elements['Hair' + AvatarUser.Hair]['Type'] !== sex) {
      this.setAvatarElement('Hair', sex === 'm' ? 1 : 105);
    }
    if (Avatar.Eyebrows.Elements['Eyebrows' + AvatarUser.Eyebrows]['Type'] !== sex) {
      this.setAvatarElement('Eyebrows', sex === 'm' ? 1 : 3);
    }

    this.setAvatarElement('Beard', sex === 'f' ? 300 : 300);
    this.setAvatarElement('Eyeliner', sex === 'm' ? 300 : 300);
    this.setAvatarElement('Eyeshadow', sex === 'm' ? 300 : 300);
    this.setAvatarElement('Rouge', sex === 'm' ? 300 : 300);

    this.setState({AvatarUser});
  }

  // Create an avatar element and places it in group

  createAvatarElement(obj, group) {

    let Avatar = this.state.Avatar;
    let xmlns = this.xmlns;
    let AvatarSVG = this.AvatarSVG;
    let AvatarUser = this.state.AvatarUser;

    // Create group if not exists
    if (!Avatar[group]) {
      Avatar[group] = {
        Elements: {}
      };
      Avatar[group].Group = document.createElementNS(xmlns, 'g');
      Avatar[group].Group.setAttribute('id', group);
      AvatarSVG.appendChild(Avatar[group].Group);
    }

    if (obj) {
      // Place element in group
      Avatar[group].Elements[group + obj.id] = {
        Shapes: {}
      };
      Avatar[group].Elements[group + obj.id].Group = document.createElementNS(xmlns, 'g');
      Avatar[group].Elements[group + obj.id].Group.setAttribute('id', (group + obj.id));
      Avatar[group].Group.appendChild(Avatar[group].Elements[group + obj.id].Group);

      // Place shapes in element
      Object.entries(obj.Shapes).forEach(
        ([index, item]) => {
          Avatar[group].Elements[group + obj.id].Shapes[item.attr['class']] = document.createElementNS(xmlns, item.shape);
          Object.entries(item.attr).forEach(
            ([attr, value]) => {
              Avatar[group].Elements[group + obj.id].Shapes[item.attr['class']].setAttribute(attr, value);
            }
          );
          Avatar[group].Elements[group + obj.id].Group.appendChild(Avatar[group].Elements[group + obj.id].Shapes[item.attr['class']]);
        }
      );

      // Create the gradient element if needed
      if (obj.Gradients) {
        Object.entries(obj.Shapes).forEach(
          ([index, gradient]) => {
            this.createAvatarGradient(gradient.id, gradient.options);
          }
        );
      }

      // Set the alt
      if (AvatarUser.Alts && AvatarUser.Alts[group + obj.id]) {
        //setAlt(group, obj.id, AvatarUser.Alts[group + obj.id]);
      } else if (obj.Alts) {
        //setAlt(group, obj.id, 0);
      }
    }

    this.setState({Avatar});
  }

  // Create a gradient element and place it to avatar SVG

  createAvatarGradient(id, options) {

    let Avatar = this.state.Avatar;
    let xmlns = this.xmlns;
    let AvatarSVG = this.AvatarSVG;

    Avatar[id] = {};
    options = options || {};

    if (options.type && options.type === 'radialGradient') {
      Avatar[id].Gradient = document.createElementNS(xmlns, 'radialGradient');
      Avatar[id].Gradient.setAttributeNS(null, 'id', id);
      Avatar[id].Gradient.setAttributeNS(null, 'cx', options.cx || '50%');
      Avatar[id].Gradient.setAttributeNS(null, 'cy', options.cy || '50%');
      Avatar[id].Gradient.setAttributeNS(null, 'fx', options.fx || '50%');
      Avatar[id].Gradient.setAttributeNS(null, 'fy', options.fy || '50%');
      Avatar[id].Gradient.setAttributeNS(null, 'r', options.r || '50%');
    } else {
      Avatar[id].Gradient = document.createElementNS(xmlns, 'linearGradient');
      Avatar[id].Gradient.setAttributeNS(null, 'id', id);
      Avatar[id].Gradient.setAttributeNS(null, 'x1', options.x1 || '0%');
      Avatar[id].Gradient.setAttributeNS(null, 'x2', options.x2 || '0%');
      Avatar[id].Gradient.setAttributeNS(null, 'y1', options.y1 || '100%');
      Avatar[id].Gradient.setAttributeNS(null, 'y2', options.y2 || '0%');
    }

    Avatar[id].Bottom = document.createElementNS(xmlns, 'stop');
    Avatar[id].Bottom.setAttributeNS(null, 'offset', options.offset1 || '0%');
    Avatar[id].Bottom.setAttributeNS(null, 'stop-color', options['stop-color2'] || '#000000');
    if (options['stop-opacity2'] || parseInt(options['stop-opacity2'], 10) === 0) {
      Avatar[id].Bottom.setAttributeNS(null, 'stop-opacity', options['stop-opacity2']);
    }
    Avatar[id].Gradient.appendChild(Avatar[id].Bottom);

    Avatar[id].Top = document.createElementNS(xmlns, 'stop');
    Avatar[id].Top.setAttributeNS(null, 'offset', options.offset2 || '100%');
    Avatar[id].Top.setAttributeNS(null, 'stop-color', options['stop-color1'] || '#000000');
    if (options['stop-opacity1'] || parseInt(options['stop-opacity2'], 10) === 0) {
      Avatar[id].Top.setAttributeNS(null, 'stop-opacity', options['stop-opacity1']);
    }
    Avatar[id].Gradient.appendChild(Avatar[id].Top);

    AvatarSVG.appendChild(Avatar[id].Gradient);

    this.setState({Avatar});
  }

  // Set all colors of a shirt

  setShirtColors(id, preview) {

    let AvatarUser = this.state.AvatarUser;
    let AvatarElements = AvatarData.AvatarElements;

    id = id || AvatarUser.Shirt;

    Object.entries(AvatarElements.Shirt[id].Colors).forEach(
      ([part_id, item]) => {
        this.setShirtElementColor(id, part_id, null, preview);
      }
    );

    this.setState({AvatarUser});
  }

  // Sets the avatars shirt color

  setShirtElementColor(shirt_id, part_id, color_id, preview) {

    let Avatar = this.state.Avatar;
    let AvatarUser = this.state.AvatarUser;
    let AvatarElements = AvatarData.AvatarElements;
    let AvatarColors = AvatarData.AvatarColors;

    shirt_id = shirt_id || AvatarUser.Shirt;
    part_id = part_id || 'Shirt';

    // Only set colors if needed
    if (!AvatarElements.Shirt[shirt_id].Colors || !AvatarElements.Shirt[shirt_id].Colors[part_id]) {
      return false;
    }

    // If there is no color set, don't use default
    if (!color_id && AvatarUser.ShirtColor[shirt_id + '-' + part_id]) {
      color_id = AvatarUser.ShirtColor[shirt_id + '-' + part_id];

      // Otherwise use default
    } else if (!color_id) {
      color_id = AvatarElements.Shirt[shirt_id].Colors[part_id].DefaultColor;
    }

    var color = AvatarColors.Element[color_id] || AvatarColors.Element[AvatarElements.Shirt[shirt_id].Colors[part_id].DefaultColor];

    // Loop through elements to set the colors
    Object.entries(AvatarElements.Shirt[shirt_id].Colors[part_id].Elements).forEach(
      ([index, element_id]) => {

				switch(AvatarElements.Shirt[shirt_id].Colors[part_id].Type) {
					// Gradient color
					case 'Gradient':
            if (!Avatar['ShirtGradient-' + shirt_id + '-' + part_id]) {
              this.createAvatarGradient('ShirtGradient-' + shirt_id + '-' + part_id);
            }
            let colorTop = color;
            let colorBottom = color;
            if (AvatarElements.Shirt[shirt_id].Colors[part_id].Modifier) {
              switch (AvatarElements.Shirt[shirt_id].Colors[part_id].Modifier) {
                case 'Brightness':
                  colorTop = this.colorBrightness(color, (AvatarElements.Shirt[shirt_id].Colors[part_id].ModifierValueTop || 0));
                  colorBottom = this.colorBrightness(color, (AvatarElements.Shirt[shirt_id].Colors[part_id].ModifierValueBottom || 0));
                  break;
                default:
                  break;
              }
            }
            Avatar['ShirtGradient-' + shirt_id + '-' + part_id].Top.setAttribute('stop-color', colorTop);
            Avatar['ShirtGradient-' + shirt_id + '-' + part_id].Bottom.setAttribute('stop-color', colorBottom);
            break;

            // Solid color
          case 'Solid':
            var colorAdjusted = color;
            if (AvatarElements.Shirt[shirt_id].Colors[part_id].Modifier) {
              switch (AvatarElements.Shirt[shirt_id].Colors[part_id].Modifier) {
                case 'Brightness':
                  colorAdjusted = this.colorBrightness(color, (AvatarElements.Shirt[shirt_id].Colors[part_id].ModifierValue || 0));
                  break;
                default:
                  break;
              }
            }
            Avatar.Shirt.Elements['Shirt' + shirt_id].Shapes[element_id].setAttribute('fill', colorAdjusted);
            break;

          default:
            break;
        }
      }
    );

    // Save shirt color
    if (!preview) {
      if (!AvatarUser.ShirtColor || typeof(AvatarUser.ShirtColor) !== 'object') {
        AvatarUser.ShirtColor = {};
      }
      AvatarUser.ShirtColor[shirt_id + '-' + part_id] = color_id;
    }

    this.setState({Avatar, AvatarUser});
  }

  // Sets the avatars hair color

  setHairColor(id, preview, previewIdHair, previewIdBeard) {

    let Avatar = this.state.Avatar;
    let AvatarUser = this.state.AvatarUser;
    let AvatarDefault = AvatarData.AvatarDefault;
    let AvatarColors = AvatarData.AvatarColors;

    id = id || AvatarUser.HairColor;

    // Gradient
    Avatar.HairGradient.Top.setAttribute('stop-color', AvatarColors.Hair[id][0]);
    Avatar.HairGradient.Bottom.setAttribute('stop-color', AvatarColors.Hair[id][1]);

    // Stroke
    Avatar.Hair.Elements['Hair' + (previewIdHair || AvatarUser.Hair)].Shapes.Hair.setAttribute('stroke', AvatarColors.Hair[id][2]);

    // Extra stroke if exists
    if (Avatar.Hair.Elements['Hair' + (previewIdHair || AvatarUser.Hair)].Shapes.Stroke) {
      Avatar.Hair.Elements['Hair' + (previewIdHair || AvatarUser.Hair)].Shapes.Stroke.setAttribute('fill', AvatarColors.Hair[id][2]);
    }

    // Beard-stroke if beard exists
    if (Avatar.Beard && Avatar.Beard.Elements['Beard' + (previewIdBeard || AvatarUser.Beard || AvatarDefault.Beard)].Shapes.Beard) {
      Avatar.Beard.Elements['Beard' + (previewIdBeard || AvatarUser.Beard || AvatarDefault.Beard)].Shapes.Beard.setAttribute('stroke', AvatarColors.Hair[id][2]);
    }

    // Eyebrows
    Avatar.Eyebrows.Elements['Eyebrows' + AvatarUser.Eyebrows].Shapes.Eyebrows.setAttribute('fill', AvatarColors.Hair[id][3]);

    // Save colors
    if (!preview) {
      AvatarUser.HairColor = id;
      // TODO $('#avatar-color-Hair' + AvatarUser.HairColor).removeClass('active');
      // TODO $('#avatar-color-Hair' + id).addClass('active');
    }

    this.setState({Avatar, AvatarUser});
  }

  // Sets the avatars skin color

  setSkinColor(id) {
    let Avatar = this.state.Avatar;
    let AvatarUser = this.state.AvatarUser;
    let AvatarColors = AvatarData.AvatarColors;

    id = id || AvatarUser.SkinColor;

    // Set head color
    Avatar.Head.Elements['Head' + AvatarUser.Head].Shapes.Head.setAttribute('fill', AvatarColors.Skin[id][0]);

    // Set neck color and shadow opacity
    Avatar.Neck.Elements['Neck' + AvatarUser.Neck].Shapes.Neck.setAttribute('fill', AvatarColors.Skin[id][0]);
    Avatar.Neck.Elements['Neck' + AvatarUser.Neck].Shapes.Shadow.setAttribute('opacity', AvatarColors.Skin[id][1]);

    // Save skin color
    AvatarUser.SkinColor = id;
    this.setState({Avatar, AvatarUser});
  }


  // Sets the avatars eyeshadow color

  setEyeshadowColor(id, direction) {

    let Avatar = this.state.Avatar;
    let AvatarUser = this.state.AvatarUser;
    let AvatarColors = AvatarData.AvatarColors;
    let AvatarDefault = AvatarData.AvatarDefault;

    id = id || AvatarUser.EyeshadowColor || AvatarDefault.EyeshadowColor;

    var EyeshadowColorByIndex;

    if (!EyeshadowColorByIndex) {
      EyeshadowColorByIndex = [];
      var EyeshadowColorIndex;
      var nr = 0;
      Object.entries(AvatarColors.Eyeshadow).forEach(
        ([key, item]) => {
          key = parseInt(key, 10);
          EyeshadowColorByIndex.push(key);
          if (key === id) {
            EyeshadowColorIndex = nr;
          }
          nr++;
        }
      );
    }

    if (direction) {
      nr = EyeshadowColorIndex + (direction === 'next' ? 1 : -1);
      nr = (nr >= EyeshadowColorByIndex.length) ? 0 : (nr < 0 ? EyeshadowColorByIndex.length - 1 : nr);
      id = EyeshadowColorByIndex[nr];
    }
    EyeshadowColorIndex = EyeshadowColorByIndex.indexOf(id);

    // Gradient
    Avatar.EyeshadowGradient.Top.setAttribute('stop-color', AvatarColors.Eyeshadow[id][0]);
    Avatar.EyeshadowGradient.Bottom.setAttribute('stop-color', AvatarColors.Eyeshadow[id][1]);

    // Save eyeshadow color
    AvatarUser.EyeshadowColor = id;

    this.setState({Avatar, AvatarUser});
  }

  // Sets the avatars eyeshadow opacity

  setEyeshadowOpacity(id, direction) {

    let Avatar = this.state.Avatar;
    let AvatarUser = this.state.AvatarUser;
    let AvatarColors = AvatarData.AvatarColors;
    let AvatarDefault = AvatarData.AvatarDefault;

    id = id || AvatarUser.EyeshadowOpacity || AvatarDefault.EyeshadowOpacity;

    var EyeshadowOpacityByIndex;

    if (!EyeshadowOpacityByIndex) {
      EyeshadowOpacityByIndex = [];
      var EyeshadowOpacityIndex;
      var nr = 0;
      Object.entries(AvatarColors.EyeshadowOpacity).forEach(
        ([key, item]) => {
          key = parseInt(key, 10);
          EyeshadowOpacityByIndex.push(key);
          if (key === id) {
            EyeshadowOpacityIndex = nr;
          }
          nr++;
        }
      );
    }

    if (direction) {
      nr = EyeshadowOpacityIndex + (direction === 'next' ? 1 : -1);
      nr = (nr >= EyeshadowOpacityByIndex.length) ? 0 : (nr < 0 ? EyeshadowOpacityByIndex.length - 1 : nr);
      id = EyeshadowOpacityByIndex[nr];
    }
    EyeshadowOpacityIndex = EyeshadowOpacityByIndex.indexOf(id);

    // Gradient
    Avatar.EyeshadowGradient.Top.setAttribute('stop-opacity', AvatarColors.EyeshadowOpacity[id][0]);
    Avatar.EyeshadowGradient.Bottom.setAttribute('stop-opacity', AvatarColors.EyeshadowOpacity[id][1]);

    // Save eyeshadow color
    AvatarUser.EyeshadowOpacity = id;

    this.setState({Avatar, AvatarUser});
  }

  // Sets the avatars rouge opacity

  setRougeOpacity(id, direction) {

    let Avatar = this.state.Avatar;
    let AvatarUser = this.state.AvatarUser;
    let AvatarColors = AvatarData.AvatarColors;
    let AvatarDefault = AvatarData.AvatarDefault;

    id = id || AvatarUser.RougeOpacity || AvatarDefault.RougeOpacity;

    var RougeOpacityByIndex;

    if (!RougeOpacityByIndex) {
      RougeOpacityByIndex = [];
      var RougeOpacityIndex;
      var nr = 0;

      Object.entries(AvatarColors.RougeOpacity).forEach(
        ([key, item]) => {
          key = parseInt(key, 10);
          RougeOpacityByIndex.push(key);
          if (key === id) {
            RougeOpacityIndex = nr;
          }
          nr++;
        }
      );
    }

    if (direction) {
      nr = RougeOpacityIndex + (direction === 'next' ? 1 : -1);
      nr = (nr >= RougeOpacityByIndex.length) ? 0 : (nr < 0 ? RougeOpacityByIndex.length - 1 : nr);
      id = RougeOpacityByIndex[nr];
    }
    RougeOpacityIndex = RougeOpacityByIndex.indexOf(id);

    // Gradient
    Avatar.RougeGradient.Top.setAttribute('stop-opacity', AvatarColors.RougeOpacity[id][0]);
    Avatar.RougeGradient.Bottom.setAttribute('stop-opacity', AvatarColors.RougeOpacity[id][1]);

    // Save eyeshadow color
    AvatarUser.RougeOpacity = id;

    this.setState({Avatar, AvatarUser});
  }

  // Creates an Avatar or displays it if it already exists

  setAvatarElement(element, id, preview, ignore_dependencies) {

    let Avatar = this.state.Avatar;
    let AvatarElements = AvatarData.AvatarElements;
    let AvatarUser = this.state.AvatarUser;
    let AvatarColors = AvatarData.AvatarColors;
    let AvatarDefault = AvatarData.AvatarDefault;
    let AvatarDisable = AvatarData.AvatarDisable;
    let AvatarTemp = this.state.AvatarTemp;

    id = id || AvatarUser[element];

    // Create element it doesn't exists
    if (!Avatar[element] || !Avatar[element].Elements[element + id]) {
      this.createAvatarElement(AvatarElements[element][id], element);
    }

    // Disable or enable depending element
    if (!ignore_dependencies) {
      if (AvatarDisable[element] || AvatarDisable[element + id]) {
        var avatar_disable_elements = AvatarDisable[element] || AvatarDisable[element + id];
        Object.entries(avatar_disable_elements).forEach(
          ([disable_element, disable_options]) => {
            var disable_options_id = null;

            // Check what id you need to disable
            if (!disable_options.id && AvatarUser[disable_element]) {
              disable_options_id = AvatarUser[disable_element];
            } else if (AvatarUser[disable_element]) {
              disable_options_id = disable_options.id;
            }

            // Only disable if found id is there
            if (disable_options_id && AvatarUser[disable_element] === disable_options_id && AvatarUser[disable_element] !== disable_options.fallback) {
              this.setAvatarElement(disable_element, disable_options.fallback, preview, true);
            }
          });
      }
    }

    // Save element
    if (!preview) {
      Avatar[element].Elements[element + (AvatarUser[element] || AvatarDefault[element])].Group.setAttribute('display', 'none');
      Avatar[element].Elements[element + id].Group.setAttribute('display', '');

      // Check if the element exists, if not the fallback is id 0 (0!)
      // TODO $('.av-element-wrapper-' + element + '.active').removeClass('active');
      // TODO $('#av-element-wrapper-' + element + ($('#av-element-wrapper-' + element + id) ? id : 0)).addClass('active');

      AvatarUser[element] = id;
    }

    // Set colors
    switch (element) {
      case 'Hair':
        this.setHairColor(null, preview, id);
        break;
      case 'Beard':
        this.setHairColor(null, preview, null, id);
        break;
      case 'Eyebrows':
        Avatar.Eyebrows.Elements['Eyebrows' + id].Shapes.Eyebrows.setAttribute('fill', AvatarColors.Hair[AvatarUser.HairColor][3]);
        break;
      case 'Shirt':
        this.setShirtColors(id, preview);
        break;
      default:
        break;
    }

    this.setState({Avatar, AvatarUser, AvatarTemp});
  }

  saveAvatar() {

    const { txt, auth, setUser, setNetworkError, addMessage } = this.props;

    if (!auth) {
      return null;
    }

    this.state.saveTimeout && clearTimeout(this.state.saveTimeout);

    this.setState({saveTimeout: setTimeout(() => {
      API.fetch({
        url: Config.apiURL + '/saveAvatar',
        data: {
          avatar: this.state.AvatarUser
        },
        txt,
        auth,
        setUser,
        error: () => {
          addMessage(txt.error, 'error');
        },
        networkError: (error) => {
          setNetworkError(error);
        },
        success: (response) => {
          if (!response.success) {
            addMessage(txt.error, 'error');
          }
        }
      });
    }, 1200)});
  }

  getPreviewShapes(shapes) {
    let html = '';
    Object.entries(shapes).forEach(
      part => {
        if (part[1].Preview === 0) {
          return;
        }
        html += '<' + part[1].shape;
        Object.entries(part[1].attr).forEach(
          attr => {
            let value = attr[1];
            if (part[1].fillPreview && (attr[0] === 'fill' || attr[0] === 'stroke')) {
              value = part[1].fillPreview;
            }
            html += ' ' + attr[0] + '="' + value + '"';
          }
        );
        html += '/>';
      }
    );
    return html;
  }

  getColorColumns(obj) {
    const columns = this.getColumns(obj[1].Colors, obj[1].Rows);

    return (
      <div className="avatar-creator-colors-wrapper">
        {columns.map((column, columnIndex) => {
          return (
            <div className="avatar-creator-colors-column" key={columnIndex}>
              {column.map((colorId, index) => {
                var colorHex = AvatarData.AvatarColors.Element[colorId];
                return (
                  <div className={'avatar-creator-item avatar-creator-item-color' + (this.state.AvatarUser.ShirtColor[this.state.AvatarUser.Shirt + '-' + obj[0]] === colorId ? ' active' : '')} onClick={() => { this.setShirtElementColor(null, obj[0], colorId); this.saveAvatar(); }} key={index} style={{backgroundColor: colorHex}} />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  // Adjust color brightness

  colorBrightness(hex, percent) {
    hex = hex.replace(/^\s*#|\s*$/g, '');
    hex.length === 3 && (hex = hex.replace(/(.)/g, '$1$1'));
    percent = percent / 100 || 0;
    var rgb = '#',
      c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * percent)), 255)).toString(16);
      rgb += ('0' + c).slice(-2);
    }
    return rgb;
  }

  render() {

    const { txt, auth } = this.props;

    return (
      <div className="content-wrapper-align-top">
        {!auth ? (
          <Redirect to="/" />
        ) : (
          <div className="avatar-creator-wrapper">
            <div id="avatar-preview" />
            <Link id="avatar-preview-back" className="icon-left" to={'/user/' + auth.username}  />

            <div className="avatar-creator-items-wrapper" data-sex={this.state.AvatarUser.Sex || 'm'}>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineSex}
                </div>
                <div className="avatar-creator-items">
                  <div className={'avatar-creator-item avatar-creator-item-icon icon-identity-male' + (this.state.AvatarUser.Sex === 'm' ? ' active' : '')} onClick={() => { this.setSex('m'); this.saveAvatar(); }}></div>
                  <div className={'avatar-creator-item avatar-creator-item-icon icon-identity-female' + (this.state.AvatarUser.Sex === 'f' ? ' active' : '')} onClick={() => { this.setSex('f'); this.saveAvatar(); }}></div>
                  <div className={'avatar-creator-item avatar-creator-item-icon icon-identity-other' + (this.state.AvatarUser.Sex === 'o' ? ' active' : '')} onClick={() => { this.setSex('o'); this.saveAvatar(); }}></div>
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineSkinColor}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarColors.SkinColorOrder.map(
                    id => {
                      let item = AvatarData.AvatarColors.Skin[id];
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-color' + (parseInt(this.state.AvatarUser.SkinColor, 10) === parseInt(id, 10) ? ' active' : '')} key={id} onClick={() => { this.setSkinColor(id); this.saveAvatar(); }} style={{backgroundColor: item[0]}} />
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineEyes}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarElements.EyesOrder.map(
                    id => {
                      let item = AvatarData.AvatarElements.Eyes[id];
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Eyes, 10) === parseInt(item.id, 10) ? ' active' : '')} key={item.id} onClick={() => { this.setAvatarElement('Eyes', item.id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="60 51 50 50" dangerouslySetInnerHTML={{__html: this.getPreviewShapes(item.Shapes)}}></svg>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineEyebrows}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarElements.EyebrowsOrder.map(
                    id => {
                      let item = AvatarData.AvatarElements.Eyebrows[id];
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-' + item.Type + ' avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Eyebrows, 10) === parseInt(item.id, 10) ? ' active' : '')} key={item.id} onClick={() => { this.setAvatarElement('Eyebrows', item.id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="55 40 60 60" dangerouslySetInnerHTML={{__html: this.getPreviewShapes(item.Shapes)}}></svg>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineMouth}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarElements.MouthOrder.map(
                    id => {
                      let item = AvatarData.AvatarElements.Mouth[id];
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Mouth, 10) === parseInt(item.id, 10) ? ' active' : '')} key={item.id} onClick={() => { this.setAvatarElement('Mouth', item.id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="65 75 40 40" dangerouslySetInnerHTML={{__html: this.getPreviewShapes(item.Shapes)}}></svg>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineHair}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarElements.HairOrder.map(
                    id => {
                      let item = AvatarData.AvatarElements.Hair[id];
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-' + item.Type + ' avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Hair, 10) === parseInt(item.id, 10) ? ' active' : '')} key={item.id} onClick={() => { this.setAvatarElement('Hair', item.id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 10 130 130">
                            <path d={item.Shapes.Hair.attr.d} fill="#000"/>
                          </svg>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container avatar-creator-items-container-Beard container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineBeard}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarElements.BeardOrder.map(
                    id => {
                      let item = AvatarData.AvatarElements.Beard[id];
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Beard, 10) === parseInt(item.id, 10) ? ' active' : '')} key={item.id} onClick={() => { this.setAvatarElement('Beard', item.id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 10 130 130">
                            <path d={item.Shapes.Beard.attr.d} fill="#000"/>
                          </svg>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineHairColor}
                </div>
                <div className="avatar-creator-items">
                  {this.getColumns(AvatarData.AvatarColors.HairColorOrder).map(
                    (column, index) => {
                      return (
                        <div className="avatar-creator-color-column" key={index}>
                          {
                            column.map(
                              id => {
                                let item = AvatarData.AvatarColors.Hair[id];
                                return (
                                  <div className={'avatar-creator-item avatar-creator-item-color' + (parseInt(this.state.AvatarUser.HairColor, 10) === parseInt(id, 10) ? ' active' : '')} key={id} onClick={() => { this.setHairColor(id); this.saveAvatar(); }} style={{backgroundColor: item[0]}} />
                                );
                              }
                            )
                          }
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineShirt}
                </div>
                <div className="avatar-creator-items">
                  {Object.entries(AvatarData.AvatarElements.Shirt).map(
                    item => {
                      if (parseInt(item[1].Admin, 10) === 1) {
                        return null;
                      }
                      if (parseInt(item[1].Locked, 10) === 1) {
                        return null;
                      }
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-' + item[1].Type + ' avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Shirt, 10) === parseInt(item[1].id, 10) ? ' active' : '')} key={item[1].id} onClick={() => { this.setAvatarElement('Shirt', item[1].id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="30 75 110 110" dangerouslySetInnerHTML={{__html: this.getPreviewShapes(item[1].Shapes)}}></svg>
                        </div>
                      );
                    }
                  )}
                </div>
                {!this.state.AvatarUser.Shirt || !AvatarData.AvatarElements.Shirt[this.state.AvatarUser.Shirt].Colors ? (
                  null
                ) : (
                  Object.entries(AvatarData.AvatarElements.Shirt[this.state.AvatarUser.Shirt].Colors).map(
                    (shirt_colors, id) => {
                      return (
                        <div className="avatar-creator-subitems-container" key={id}>
                          <div className="avatar-creator-items-title">
                            {txt.pages.avatar.avatarShirtColorHeadlines[shirt_colors[1].Title]}
                          </div>
                          <div className="avatar-creator-items">
                            {this.getColorColumns(shirt_colors)}
                          </div>
                        </div>
                      );
                    }
                  )
                )}
              </div>
              <div className="avatar-creator-items-container avatar-creator-items-container-Makeup container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineMakeup}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarElements.EyelinerOrder.map(
                    id => {
                      let item = AvatarData.AvatarElements.Eyeliner[id];
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Eyeliner, 10) === parseInt(item.id, 10) ? ' active' : '')} key={item.id} onClick={() => { this.setAvatarElement('Eyeliner', item.id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="60 60 28 28">
                            <path d={item.Shapes.Eyeliner.attr.d} fill="#000"/>
                          </svg>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="avatar-creator-items-container container">
                <div className="avatar-creator-items-title">
                  {txt.pages.avatar.itemsHeadlineGlasses}
                </div>
                <div className="avatar-creator-items">
                  {AvatarData.AvatarElements.GlassesOrder.map(
                    id => {
                      let item = AvatarData.AvatarElements.Glasses[id];
                      if (parseInt(item.Locked, 10) === 1) {
                        return null;
                      }
                      return (
                        <div className={'avatar-creator-item avatar-creator-item-svg' + (parseInt(this.state.AvatarUser.Glasses, 10) === parseInt(item.id, 10) ? ' active' : '')} key={item.id} onClick={() => { this.setAvatarElement('Glasses', item.id); this.saveAvatar(); }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="52 42 66 66" dangerouslySetInnerHTML={{__html: this.getPreviewShapes(item.Shapes)}}></svg>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Page;
