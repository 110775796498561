import React from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../util/Helper';

class NotificationsComponent extends React.PureComponent {

  getNotificationHTML(userNotification, index) {

    const { txt, serverTime, auth, closeUserNav } = this.props;

    if (userNotification.match_time) {
      const userFavs = [auth.fav1, auth.fav2, auth.fav3];
      const favHome = userFavs.indexOf(userNotification.home_id) > -1;
      const favGuest = userFavs.indexOf(userNotification.guest_id) > -1;
      const favClub = favHome && favGuest ? 'both' : (favHome ? 'home' : 'guest');
      const matchClub1 = favClub === 'guest' ? 'guest' : 'home';
      const matchClub2 = matchClub1 === 'home' ? 'guest' : 'home';
      const matchLocation = matchClub1 === 'guest' ? 'Away' : 'AtHome';
      const result = userNotification.goals_home === userNotification.goals_guest ? 'draw' : (userNotification.goals_home > userNotification.goals_guest ? 'home' : 'guest');
      const resultText = result === 'draw' ? 'Draw' : (matchClub1 === result ? 'Won' : 'Lost');
      const notificationText = txt.userNav['NotificationMatch' + resultText]
        .replace('{0}', userNotification[matchClub1]['name_short_' + txt.id])
        .replace('{1}', favClub === 'both' || result === 'draw' ? 'neutral' : (favClub === result ? 'pos' : 'neg'))
        .replace('{2}', txt.userNav['NotificationMatch' + matchLocation])
        .replace('{3}', userNotification[matchClub2]['name_short_' + txt.id])
        .replace('{4}', userNotification.goals_home + ' : ' + userNotification.goals_guest);

      return (
        <Link to={'/match/' + userNotification.id} className={'user-menu-notification-wrapper' + (userNotification.new ? ' notification-new' : '')} key={index} onClick={() => { closeUserNav(); }}>
          <div className="user-menu-notification-icon user-menu-notification-icon-match">
            {favClub === 'both' ? (
              <div className="club-icon-draw-container">
                <div className={'club-icon club' + userNotification.home.id + ' club-icon-draw-home'} dangerouslySetInnerHTML={{__html: userNotification.home.icon + '<i/>'}}></div>
                <div className={'club-icon club' + userNotification.guest.id + ' club-icon-draw-guest'} dangerouslySetInnerHTML={{__html: userNotification.guest.icon + '<i/>'}}></div>
              </div>
            ) : (
              <div className={'club-icon club' + userNotification[favClub].id + ' club-icon-single'} dangerouslySetInnerHTML={{__html: userNotification[favClub].icon + '<i/>'}}></div>
            )}
          </div>
          <div className="user-menu-notification-content">
            <div className="user-menu-notification-time">{Helper.timeAgo(userNotification.match_time, txt, serverTime)}</div>
            <div className="user-menu-notification-subtitle" dangerouslySetInnerHTML={{__html: notificationText}}></div>
          </div>
        </Link>
      );
    } else {

      // TODO badge html

      return null;
    }
  }

  render() {
    const { txt, userNotifications } = this.props;

    const userNotificationsSet = Object.keys(userNotifications).length !== 0;

    return (
      <div className="user-menu-container user-menu-container-notifications">
        <div className="user-menu-title">
          {txt.userNav.HeaderMenuNotificationsTitle}
        </div>
        {userNotifications && userNotifications.notifications && userNotifications.notifications.length ? (
          <div className="user-menu-notifications-container">
            {userNotifications.notifications.map((userNotification, index) => {
              return this.getNotificationHTML(userNotification, index);
            })}
          </div>
        ) : (
          <div className="user-menu-error-message">
            {!userNotificationsSet ? null : (
              <div>
                {txt.userNav.HeaderMenuNotificationsNoNotifications}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default NotificationsComponent;
