import { connect } from 'react-redux';
import { removeMessages, removeMessage } from '../../actions';
import mapToComponent from './MessagesComponent';

const mapStateToProps = state => ({
  messages: state.messages
});

const mapDispatchToProps = dispatch => ({
  removeMessage: id => dispatch(removeMessage(id)),
  removeMessages: id => dispatch(removeMessages(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
