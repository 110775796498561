import React from 'react';
import Config from '../../../config';
import API from '../../../util/API';
import ContentLoading from '../../../util/ContentLoading';
import Matchday from '../../Matchday/MatchdayContainer';
import CupMatches from '../../CupMatches/CupMatchesContainer';
import News from '../../News/NewsComponent';
import Posts from '../../Posts/PostsContainer';
import Helper from '../../../util/Helper';


class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      league: null,
      matchday: null,
      matches: [],
      cupData: {},
      news: null,
      notice: null,
      networkError: false,
      reloadingPage: false,
      pageLoaded: false,
      list: 'posts',
      highscores: null
    };
  }

  componentDidMount() {
    const { setLocation, setLoginLocation, location, match } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);

    const notice = Helper.getNotice();
    if (notice) {
      this.setState({
        notice
      });
    }

    this.fetchLeague(match.params.league_url);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { setLocation, match } = this.props;

    if (match.params.league_url !== nextProps.match.params.league_url) {
      setLocation(nextProps.location);
      this.setState({pageLoaded: false});
      this.fetchLeague(nextProps.match.params.league_url);
    }
  }

  reloadPage(league_url) {
    if (!league_url) {
      const { league } = this.props;
      league && (league_url = league.League.league_url);
    }
    this.fetchLeague(league_url, true);
  }

  fetchLeague(league_url, reload) {
    const { txt, auth, league, setServerTime, setLeague, setMatches, setMatchdayChips, addMessage, setNetworkError, setContentLoading, setAppLoading } = this.props;

    API.fetch({
      url: Config.apiURL + '/league',
      data: {
        league_id: league_url || null,
        season_id: null
      },
      txt,
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        reload && this.setState({reloadingPage: true});
        !reload && ContentLoading.startContentLoading(league ? setContentLoading : setAppLoading);
      },
      complete: () => {
        reload && this.setState({reloadingPage: false});
        !reload && ContentLoading.endContentLoading(league ? setContentLoading : setAppLoading);
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        reload && error && addMessage(txt.error, 'error');
        this.setState({networkError: error});
        setNetworkError(error);
      },
      success: (response) => {

        if (!response.league) {
          return addMessage(txt.error, 'error');
        }

        setLeague(response.league);

        response.matchday_data && setMatches(response.matchday_data.matches);
        response.matchday_data && response.matchday && setMatchdayChips(response.league.Season.id, response.matchday, response.matchday_data.chips);

        response.cup_data && response.cup_data.matches && Object.keys(response.cup_data.matches).forEach(function(key, index) {
          setMatches(response.cup_data.matches[key]);
        });

        this.setState({
          pageLoaded: true,
          league: response.league,
          matchday: response.matchday ? parseInt(response.matchday, 10) : null,
          matches: response.matchday_data && response.matchday_data.matches ? JSON.parse(JSON.stringify(response.matchday_data.matches)) : [],
          cupData: response.cup_data ? response.cup_data : [],
          news: response.news || null,
          highscores: response.highscores || null
        });
      }
    });
  }

  render() {
    const { txt, league } = this.props;

    return (
      <div className="content-wrapper">
        {!this.state.pageLoaded ? null : (
          this.state.networkError ? (
            <div className="content-error-container">
              <h1>{txt.pageErrorHeadline}</h1>
              <h3>{txt.pageErrorSubline}</h3>
              <div onClick={() => { this.reloadPage(); }}>
                <div className="page-error-icon-container">
                  <div className={'page-error-icon icon-reload' + (this.state.reloadingPage ? ' rotating' : '')}></div>
                </div>
                <div className="page-error-try-again">{txt.pageErrorTryAgain}</div>
              </div>
            </div>
          ) : (
            <div>
              {this.state.notice ? Helper.showNotice(this.state.notice, txt) : null}
              <div className="content-container">
                <h1 className="centered">{league.League.type === 'worldcup' ? (league.League['name_' + txt.id] + ' ' + league.Season.name) : league.League['name_' + txt.id]}</h1>
              </div>
              {league.League.type === 'worldcup' ? (
                <CupMatches cupData={this.state.cupData} league_prop={this.state.league} />
              ) : (
                <Matchday matches_prop={this.state.matches} matchday_prop={this.state.matchday} league_prop={this.state.league} />
              )}
              <News news={this.state.news} />
              <Posts postsLeagueId={league.League.id} />
            </div>
          )
        )}
      </div>
    );
  }
}

export default Page;
