import { connect } from 'react-redux';
import { getUser, getLeague, setServerTime, setNetworkError, setMatches, setUserScore } from '../../actions';
import mapToComponent from './AppComponent';

const mapStateToProps = state => ({
  auth: state.auth,
  league: state.league,
  matches: state.matches,
  serverTime: state.serverTime,
  isNetworkError: state.networkError,
  contentLoading: state.contentLoading,
  appLoading: state.appLoading,
  navIsOpen: state.nav,
  subNavIsOpen: state.subNav,
  userNavIsOpen: state.userNav
});

const mapDispatchToProps = dispatch => ({
  setServerTime: serverTime => dispatch(setServerTime(serverTime)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  setMatches: matches => dispatch(setMatches(matches)),
  getUser: () => dispatch(getUser()),
  getLeague: league => dispatch(getLeague(league)),
  setUserScore: score => dispatch(setUserScore(score))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
