const en = {
  i18n: {
    id: 'en',
    name: 'English',

    error: 'Whoops! Something went wrong',
    networkError: 'Network Error',
    pageErrorHeadline: 'Whoops!',
    pageErrorSubline: 'We couldn\'t load this page',
    pageErrorTryAgain: 'Try again',
    contentErrorHeadline: 'Whoops!',
    contentErrorSubline: 'Something went wrong',

    bet: 'Bet!',
    bets: 'Bets',
    user: 'User',
    users: 'Users',
    points: 'Points',
    pointsShort: 'P',
    place: 'Place',
    jokerX2: '×2',
    jokerHt: 'Ht',
    matchdayNr: 'Matchday {0}',
    comments: 'Comments',
    highscores: 'Highscores',
    postponed: 'Postponed',
    PostponedShort: 'PPD',

    aet: 'AET',
    pso: 'PSO',

    matchdayWorldCup: {
      matchday1: 'Group Stage 1',
      matchday2: 'Group Stage 2',
      matchday3: 'Group Stage 3',
      matchday4: 'Round of 16',
      matchday5: 'Quarter Finals',
      matchday6: 'Semi Finals',
      matchday7: 'Place 3',
      matchday8: 'Final'
    },

    matchdayChampionsLeague: {
      matchday1: 'Group Stage 1',
      matchday2: 'Group Stage 2',
      matchday3: 'Group Stage 3',
      matchday4: 'Round of 16',
      matchday5: 'Quarter Finals',
      matchday6: 'Semi Finals',
      matchday7: 'Place 3',
      matchday8: 'Final'
    },

    date: {
      dayOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      dayOfWeekAbr: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthName: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthNameAbr: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      justNow: 'just now',
      minuteAgo: '1 minute ago',
      minutesAgo: '{0} minutes ago',
      hourAgo: '1 hour ago',
      hoursAgo: '{0} hours ago',
      secondsShort: 'sec',
      minutesShort: 'min',
      today: 'Today',
      tomorrow: 'Tomorrow',
      yesterday: 'Yesterday',
      Week: 'week',
      Weeks: 'weeks',
      WeekShort: 'week',
      WeeksShort: 'weeks',
      Day: 'day',
      DayShort: 'day',
      Days: 'days',
      DaysCountdown: 'days',
      DaysShort: 'days',
      Hour: 'hour',
      Hours: 'hours',
      HoursShort: 'hours',
      HourShort: 'hour',
      Minute: 'minute',
      Minutes: 'minutes',
      MinuteShort: 'min',
      MinutesShort: 'min',
      Second: 'second',
      Seconds: 'seconds',
      SecondShort: 'sec',
      SecondsShort: 'sec'
    },

    nav: {
      league: 'Dashboard',
      leagues: 'Leagues',
      highscores: 'Highscores',
      matches: 'Matches',
      table: 'Table',
      more: 'More',
      settings: 'Settings',
      signIn: 'Sign In',
      signUp: 'Sign Up',
      signOut: 'Sign Out',
      userScorePlayers: '{0} players'
    },

    subnav: {
      about: 'About KICKPros',
      contact: 'Contact',
      terms: 'Terms',
      privacy: 'Privacy Policy',
      imprint: 'Imprint'
    },

    userNav: {
      HeaderMenuGroupsNoPrivateGroupsNotice: 'You haven\'t joined any private groups.',
      HeaderMenuGroupsNoFanGroupsNotice: 'You haven\'t joined any fan groups.',
      HeaderMenuNotificationsNoNotifications: 'There are no notifications for you.',
      HeaderMenuNotificationsTitle: 'Notifications',
      NotificationMatchAtHome: 'at home',
      NotificationMatchAway: 'away',
      NotificationMatchWon: '<b>{0}</b> <b class="user-menu-notification-text-{1}">won</b> {2} against <b>{3}</b> <span style="white-space: nowrap">with <b>{4}</b></span>',
      NotificationMatchDraw: '<b>{0}</b> <b class="user-menu-notification-text-{1}">drew</b> {2} against <b>{3}</b> <span style="white-space: nowrap">with <b>{4}</b></span>',
      NotificationMatchLost: '<b>{0}</b> <b class="user-menu-notification-text-{1}">lost</b> {2} against <b>{3}</b> <span style="white-space: nowrap">with <b>{4}</b></span>'
    },

    notices: {
      AccountEmailActivated: 'Your email address was confirmed',
      AccountEmailUnsubscribed: 'Your email was removed from the list.',
      AccountEmailUnsubscribedError: 'Whoops, this link is expired…'
    },

    signIn: {
      buttonSignInWithFacebook: 'Sign In with Facebook',
      buttonSignInWithInstagram: 'Sign In with Instagram',
      textfieldNicknamePlaceholder: 'Your nickname',
      textfieldEmailPlaceholder: 'Email address',
      textfieldPasswordPlaceholder: 'Password',
      buttonSignIn: 'Sign In',
      buttonSignUp: 'Sign Up',
      linkSignIn: 'Already signed up? Sign in!',
      linkSignUp: 'Sign up with KICKPros',
      linkForgotPassword: 'Forgot your Password?',
      terms: 'By clicking Sign Up, you agree to our {0} and confirm that you have read our {1}.',
      termsPart0: 'Terms',
      termsPart1: 'Data Use Policy',
      signInErrorNoEmailAndPassword: 'Please type in your email address <span style="white-space: nowrap">and your password</span>',
      LoginErrorEmail: 'Please type in your email address',
      LoginErrorEmailValidate: 'Invalid email address',
      LoginErrorEmailRegistered: 'Email already registered',
      LoginErrorPassword: 'Please type in your password',
      LoginErrorSignUpDefault: 'Please type in your nickname, your email address and a password',
      LoginErrorSignUpDefaultLinkAccounts: 'Please type in your password',
      LoginErrorPasswordMin: 'Your password must have at least 6 characters',
      LoginErrorPasswordMax: 'Your Password can\'t have more than 50 characters',
      LoginErrorNickname: 'Please type in your nickname',
      LoginErrorNicknameMin: 'Your nickname must have at least 3 characters',
      LoginErrorNicknameMax: 'Your nickname can\'t have more than 30 characters',
      LoginErrorNicknameLetters: 'Your nickname can only contain letters and numbers',
      LoginErrorNicknameStartWithLetter: 'Your nickname has to start with a letter',
      LoginErrorNicknameEndWithLetterOrNumber: 'Your nickname has to end with a letter or number',
      LoginErrorUsername: 'Please type in your username',
      LoginErrorUsernameMin: 'Your username has to have at least 3 characters',
      LoginErrorUsernameMax: 'Your username can\'t have more than 30 characters',
      LoginErrorUsernameLetters: 'Your username can only contain letters and numbers',
      LoginErrorUsernameStartWithLetter: 'Your username has to start with a letter',
      LoginErrorUsernameEndWithLetterOrNumber: 'Your username has to end with a letter or number',
      LoginErrorUsernameRegistered: 'This username is already taken',
      LoginErrorEmailPassword: 'Wrong email or password',
      LoginErrorFacebook: 'Whoops! Sign In with Facebook failed',
      LoginErrorInstagram: 'Whoops! Sign In with Instagram failed',
      LoginSuccessInstagram: 'Almost done! Please fill in your email address and choose a password:',
      LoginLinkInstagramAccountHeadline: 'Sign In',
      LoginLinkInstagramAccountMessageLine1: 'This email address<br />is already registered with KICKPros.',
      LoginLinkInstagramAccountMessageLine2: 'You can sign in with your password to connect your account with Instagram:',
      ConnecWithInstagramDefaultError: 'Connecting accounts failed.',
      ConnecWithInstagramWrongPassword: 'Invalid password',
      ConnecWithInstagramCancelLink: 'Cancel'
    },

    recoverPassword: {
      headline: 'Recover Password',
      subline: 'We will send you an email with a link to change your password',
      textfieldEmailPlaceholder: 'Email address',
      submitButton: 'Send',
      linkSignUp: 'Remember your Password? Sign In!',
      ResetPasswordErrorEmptyEmail: 'Please type in your email address',
      ResetPasswordMessageSuccess: 'We sent you an email with a link to set a new password',
      ResetPasswordErrorEmail: 'This email address is not registered with us'
    },

    newPassword: {
      headline: 'Create a new password',
      subline: 'Combine letters, numbers and special characters to create a strong password',
      textfieldPasswordPlaceholder: 'New password',
      textfieldPassword2Placeholder: 'One more time please...',
      submitButton: 'Save',
      NewPasswordMessageSuccess: 'Password saved. You need to sign in with your new password from now on.',
      errorLinkExpiredHeadline: 'Whoops!',
      errorLinkExpiredSubline: 'This link is expired. You can request a new one on the {0}.',
      errorLinkExpiredSublinePart0: 'Recover Password page',
      NewPasswordErrorPassword2: 'The passwords do not match',
      LoginErrorPassword: 'Please type in your new password',
      LoginErrorPasswordMin: 'Your password must have at least 6 characters',
      LoginErrorPasswordMax: 'Your Password can\'t have more than 50 characters'
    },

    activateEmail: {
      ActivateEmailError: 'Whoops, this link is expired…',
      ActivateEmailError2: 'Go to your <a href="/account">Account Settings</a> to send a new link or to change your email address'
    },

    contact: {
      headline: 'Contact',
      sublineLine1: 'Questions, Suggestions or Feedback?',
      sublineLine2: 'Feel free to contact us at <a href="mailto:mail@kickpros.com">mail@kickpros.com</a>.',
      emailPlaceholder: 'Your email address',
      messagePlaceholder: 'Your message to KICKPros...',
      buttonSubmit: 'Send Message',
      errorNoMessage: 'Please type in your message',
      successMessageSent: 'Thanks, your message was sent'
    },

    posts: {
      postsInputPlaceholder: 'Write a comment...',
      youDeletedThisPost: 'Your comment was deleted',
      youReportedThisPost: 'You reported this comment',
      undo: 'Undo',
      noPostsYet: 'Be the first to write a comment...',
      viaTwitter: 'via Twitter',
      postsError: 'An error occured while loading comments...'
    },

    pages: {

      settings: {
        headline: 'Settings',
        headlineLanguage: 'Language'
      },

      account: {
        headline: 'Account settings',
        headlineEmailNotifications: 'Email notifications',
        emailNotificationsReminderShort: 'Reminder for missing bets',
        emailNotificationsPromotionShort: 'Reminder for competitions',
        emailNotificationsSummaryShort: 'Summary of your scores',
        emailNotificationsReminder: 'Reminder for missing bets',
        emailNotificationsPromotion: 'Reminder when new competitions are about to start',
        emailNotificationsSummary: 'Summary of your scores after a matchday',
        headlineLanguage: 'Language',
        chooseLanguage: 'Choose language',
        headlineNickname: 'Your name',
        headlineUsername: 'Username',
        headlineEmail: 'Email',
        headlinePassword: 'Password',
        placeholderYourPassword: 'Your Password',
        placeholderNewEmailAddress: 'New email address',
        placeholderPassword: 'Current password',
        placeholderPasswordNew: 'New password',
        cancel: 'Cancel',
        passwordChangedJustNow: 'Changed just now',
        passwordChangedToday: 'Changed today',
        passwordChangedDate: 'Changed on {0}',
        passwordChangedNever: 'Never changed',
        AccountErrorCurrentPassword: 'Invalid current password',
        confirmEmailAddress: 'Please confirm your email address',
        emailChangeSuccess: 'We\'ve sent you an email to confirm your new email address',
        deleteAccountLink: 'Delete account',
        deleteAccountHeadline1: 'Delete Account',
        deleteAccountHeadline2: 'Sad to see you leave us!',
        deleteAccountText: 'We will delete all your personal data. Type in your password, then click on »Delete Account«',
        deleteAccountBackButton: 'Cancel',
        deleteAccountSubmitButton: 'Delete Account',
        deleteAccountPasswordPlaceholder: 'Your password',
        DeleteAccountError: 'Falsches Passwort!'
      },

      PageNotFound: {
        headline: 'Whoops!',
        subline: 'We couldn\'t find this page'
      },

      standings: {
        headerClubName: 'Club',
        headerPlayed: 'Pld',
        headerWon: 'W',
        headerDraw: 'D',
        headerLost: 'L',
        headerGoals: 'Goals',
        headerGoalsDiff: 'GD',
        headerPoints: 'Pts',
        legendCl: 'Champions League',
        legendClQ: 'Champions League Qualification',
        legendEl: 'Europa League',
        legendElQ: 'Europa League Qualification',
        legendDwR: 'Relegation Match',
        legendDw: 'Relegation',
        headerGroup: 'Group {0}'
      },

      highscores: {
        headerHighscores: 'Highscores',
        headerMedalTally: 'Medal Tally',
        headerMatchdays: 'Best Matchdays',
        headerMatchday: 'Matchday {0}',
        players: 'Players',
        noHighscores: 'There are no highscores yet...'
      },

      match: {
        kickoffIn: 'Kick-off in',
        matchFeedPenaltyShort: 'PK',
        matchFeedOwnGoalShort: 'OG',
        result: {
          home: '{0} wins at home against {1} with <span>{2} to {3}</span>',
          guest: '{1} wins away against {0} with <span>{3} to {2}</span>',
          draw: '{0} and {1} draw <span>{2} : {3}</span>',
        },
        incident: {
          Halftime: 'Halftime',
          RegularGoalNoPlayer: 'Goal',
          PenaltyNoPlayer: 'Penalty Kick Goal',
          MissedPenaltyNoPlayer: 'Penalty Kick missed',
          OwnGoalNoPlayer: 'Own Goal',
          RegularGoal: 'Goal by {0}',
          Penalty: 'Penalty Kick Goal by {0}',
          MissedPenalty: 'Penalty Kick missed by {0}',
          OwnGoal: 'Own Goal by {0}',
          YellowCardNoPlayer: 'Yellow Card',
          YellowCard2NoPlayer: 'Second Yellow Card',
          RedCardNoPlayer: 'Red Card',
          YellowCard: 'Yellow Card for {0}',
          YellowCard2: 'Second Yellow Card for {0}',
          RedCard: 'Red Card for {0}',
          SubstitutesNoPlayer: 'Substitutes',
          Substitutes: '{0} for {1}'
        }
      },

      user: {
        userNotFoundHeadline: 'Whoops!',
        userNotFoundSubline: 'User not found...',
        userDeletedSubline: 'This user was deleted...',
        badgesTitle: 'Badges',
        leaguesTitle: 'Leagues',
        groupsTitle: 'Groups',
        privateGroupsTitle: 'Private Groups',
        publicGroupsTitle: 'Fan Groups'
      },

      avatar: {
        itemsHeadlineSex: 'Identity',
        itemsHeadlineSkinColor: 'Skin color',
        itemsHeadlineEyes: 'Eyes',
        itemsHeadlineEyebrows: 'Eyebrows',
        itemsHeadlineMouth: 'Mouth',
        itemsHeadlineHair: 'Haircut',
        itemsHeadlineBeard: 'Beard',
        itemsHeadlineHairColor: 'Hair color',
        itemsHeadlineShirt: 'Shirt',
        itemsHeadlineMakeup: 'Makeup',
        itemsHeadlineGlasses: 'Glasses',
        avatarShirtColorHeadlines: {
          AvatarCreatorTitleShirtColor: 'Shirt color',
          AvatarCreatorTitleSuitColor: 'Suit Color',
          AvatarCreatorTitleSuitShirtColor: 'Shirt color',
          AvatarCreatorTitleSuitTieColor: 'Tie color'
        }
      }
    },

    placeBet: {
      errorSavingBet: 'Your bet could not be saved',
      freeShields: '{0} FREE',
      halftimeBetTitle: 'Halftime Bet',
      halftimeBetLead: '{0} leads',
      halftimeBetRemis: 'Remis',
      yourBetTitle: 'Your bet',
      scoreAfter90min: '90 min',
      overtimeNotice: 'Extra Time',
      penaltyShootOutNotice: 'Penalty Shoot Out',
      overtimeNoticeFT: 'After Extra Time',
      penaltyShootOutNoticeFT: 'Penalty Shoot Out',
      tendenciesTitle: 'Bet Tendencies',
      toBeDecidedNotice: 'The teams for this match are not decided yet',
      knockoutNotice: '<b>Knockout match:</b> Place your bet for the result after 90 minutes play time.'
    }
  }
};

export default en;
