import React from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../util/Helper';
import BetBox from '../BetBox/BetBoxContainer';

class Element extends React.PureComponent {

  render() {
    const { txt, auth, serverTime, match, showOnlyTime } = this.props;

    return (
      <div className={'matchday-match' + (Helper.matchIsLive(match, serverTime) ? ' match-live' : (Helper.matchIsStarting(match, serverTime) ? ' match-starting' : ''))}>
        <Link className="matchday-match-link" to={'/match/' + match.id}>
          <div className={'matchday-club-container' + (Helper.teamDefeated('home', match) ? ' defeated' : '') + (match.home.id === 0 ? ' club-tbd' : '')}>
            <div className={'matchday-club-icon club-icon club' + match.home_id + (Helper.favClub(auth, match.home_id) ? ' fav' : '')} dangerouslySetInnerHTML={{__html: match.home.icon + '<i></i>'}}></div>
            <div className="club-name matchday-club-name">{match.home['name_' + txt.id]}</div>
            <div className="club-name matchday-club-name-short">{match.home['name_short_' + txt.id]}</div>
            <div className="club-name matchday-club-name-tiny">{match.home.name_tiny}</div>
          </div>
          <div className={'matchday-club-container' + (Helper.teamDefeated('guest', match) ? ' defeated' : '') + (match.guest.id === 0 ? ' club-tbd' : '')}>
            <div className={'matchday-club-icon club-icon club' + match.guest_id + (Helper.favClub(auth, match.guest_id) ? ' fav' : '')} dangerouslySetInnerHTML={{__html: match.guest.icon + '<i></i>'}}></div>
            <div className="club-name club-name matchday-club-name">{match.guest['name_' + txt.id]}</div>
            <div className="club-name matchday-club-name-short">{match.guest['name_short_' + txt.id]}</div>
            <div className="club-name matchday-club-name-tiny">{match.guest.name_tiny}</div>
          </div>
          <div className={Helper.matchStarted(match, serverTime) ? 'matchday-matchscore' : 'matchday-matchtime'}>
            {Helper.matchTimeOrScore(match, serverTime, txt, !!showOnlyTime)}
          </div>
        </Link>
        <div className="matchday-bet-container">
          <BetBox type={parseInt(match.finished, 10) === 0 ? 'bet' : 'points'} matchObj={match} bet={match.bets && match.bets.length ? match.bets[0] : null} clickable={true} user_id={auth ? auth.id : null} />
        </div>
      </div>
    );
  }
}

export default Element;
