import React from 'react'
import API from '../../util/API'
import Config from '../../config'
import Helper from '../../util/Helper'
import Transition from 'react-transition-group/Transition'
import { CSSTransition } from 'react-transition-group'

class PlaceBetComponent extends React.PureComponent {

  constructor(props) {
    super(props)

    const initialPlaceBet = {
      id: null,
      match_id: null,
      bet_home: 0,
      bet_guest: 0,
      bet_advance: 'home',
      joker_x2: 0,
      joker_ht: '',
      joker_ht_chips: 0,
      shields: 0,
      tendencies: 0
    }

    this.state = {
      serverTime: null,
      match: null,
      initialPlaceBet,
      placeBet: initialPlaceBet,
      placeBetTimeout: null,
      placeBetRequest: null,
      placeBetSuccess: null,
      placeBetGoToMatchRequest: null,
      innerOverlay: null,
      innerOverlayFormDataRequest: {},
      innerOverlayData: {
        form: {},
        tendencies: {}
      },
      matchdayChipsError: false,
      getMatchdayChipsRequest: {},
      addShieldsRequest: null,
      shieldChipsError: null
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    // Abort if a timeout to save a bet is running
    if (this.state.placeBetTimeout) {
      return
    }

    const { txt, auth, league, matches, matchdayChips, placeBet, serverTime, addMessage, setNetworkError, setMatchdayChips } = nextProps

    // The placeBet property is mandatory
    if (placeBet) {

      // Construct from initial clean placeBet
      let statePlaceBet = JSON.parse(JSON.stringify(this.state.initialPlaceBet))

      if (!this.state.match || this.state.match.id !== placeBet.match_id) {
        this.closeInnerOverlay()
      }

      // Override match id
      statePlaceBet.match_id = placeBet.match_id

      // Override clean placeBet with the one from the match, if given
      if (matches[placeBet.match_id] && matches[placeBet.match_id].bets && matches[placeBet.match_id].bets.length) {
        statePlaceBet = JSON.parse(JSON.stringify(matches[placeBet.match_id].bets[0]))
      }

      const matchday = matches[placeBet.match_id].matchday

      if (
        (!matchdayChips[league.Season.id] || matchdayChips[league.Season.id][matchday] === undefined) &&
        !this.state.getMatchdayChipsRequest[matchday]
      ) {
        API.fetch({
          url: Config.apiURL + '/getMatchdayChips',
          data: {
            season_id: league.Season.id,
            matchday: matchday
          },
          auth,
          league,
          beforeSend: () => {
            let stateObj = {}
            stateObj[matchday] = true
            this.setState({
              getMatchdayChipsRequest: {
                ...this.state.getMatchdayChipsRequest,
                stateObj
              }
            })
          },
          complete: () => {
            let stateObj = {}
            stateObj[matchday] = false
            this.setState({
              getMatchdayChipsRequest: {
                ...this.state.getMatchdayChipsRequest,
                stateObj
              }
            })
          },
          error: () => {
            addMessage(txt.error, 'error')
          },
          networkError: (error) => {
            setNetworkError(error)
          },
          success: (response) => {
            if (response.success) {
              setMatchdayChips(league.Season.id, matchday, response.chips)
              this.forceUpdate()
            }
          }
        })
      }

      this.setState({
        placeBet: statePlaceBet,
        match: matches[placeBet.match_id]
      }, () => {
        // Save initial bet as 0:0 is not possible to select
        if (
          matches[placeBet.match_id] &&
          matches[placeBet.match_id].home_id &&
          matches[placeBet.match_id].guest_id &&
          serverTime &&
          !Helper.matchStarted(matches[placeBet.match_id], serverTime) &&
          (!matches[placeBet.match_id].bets || (matches[placeBet.match_id].bets && !matches[placeBet.match_id].bets.length))
        ) {
          this.placeBet('initial')
        }
      })
    }

    serverTime && this.setState({serverTime})
  }

  placeBet(type, value, value2) {

    if (!this.state.match.home_id || !this.state.match.guest_id) {
      return
    }

    const { txt, auth, league, matches, matchdayChips, addMessage, setNetworkError, setMatches, setMatchdayChips, serverTime } = this.props

    if (this.state.placeBetRequest || Helper.matchStarted(this.state.match, serverTime)) {
      return
    }

    if (type === 'goals' && this.state.placeBet['bet_' + value2] + value < 0) {
      return
    }

    if (type === 'joker-ht' && this.getHalftimeButtonStatus(value, value2) !== true) {
      return
    }

    let placeBet = this.state.placeBet
    let timeoutAPI = 0
    const matchday = matches[placeBet.match_id].matchday
    const chips = Helper.getMatchdayChips(matchdayChips, league.Season.id, matchday)

    switch (type) {
      case 'initial':
        timeoutAPI = 400
        break
      case 'goals':
        placeBet['bet_' + value2] = Math.max(0, placeBet['bet_' + value2] + value)
        timeoutAPI = 800
        break
      case 'joker-x2':
        if (placeBet.joker_x2) {
          placeBet.joker_x2 = 0
          setMatchdayChips(league.Season.id, matchday, chips + 5)
        } else if (chips >= 5) {
          placeBet.joker_x2 = 1
          setMatchdayChips(league.Season.id, matchday, chips - 5)
        } else {
          this.showChipsError()
          return
        }
        break
      case 'joker-ht':
        if (value2 === 'minus') {
          placeBet.joker_ht_chips = Math.max(0, parseInt(placeBet.joker_ht_chips, 10) - 1)
          if (placeBet.joker_ht_chips <= 0) {
            placeBet.joker_ht = ''
          }
          setMatchdayChips(league.Season.id, matchday, chips + 1)
        } else if (chips >= 1) {
          placeBet.joker_ht_chips = Math.min(3, parseInt(placeBet.joker_ht_chips, 10) + 1)
          placeBet.joker_ht = value
          setMatchdayChips(league.Season.id, matchday, chips - 1)
        } else {
          this.showChipsError()
          return
        }
        timeoutAPI = 800
        break
      default:
        break
    }

    this.setState({
      placeBet
    })

    this.state.placeBetTimeout && clearTimeout(this.state.placeBetTimeout)
    this.state.placeBetSuccess && clearTimeout(this.state.placeBetSuccess)
    this.setState({
      placeBetTimeout: null,
      placeBetSuccess: null
    })

    this.setState({placeBetTimeout: setTimeout(() => {
      API.fetch({
        url: Config.apiURL + '/saveBet',
        data: {
          bet: this.state.placeBet,
          season_id: league.Season.id,
          matchday: matchday
        },
        auth,
        league,
        beforeSend: () => {
          this.setState({placeBetRequest: true})
        },
        complete: () => {
          this.setState({placeBetRequest: false})
        },
        error: () => {
          addMessage(txt.error, 'error')
        },
        networkError: (error) => {
          setNetworkError(error)
        },
        success: (response) => {

          if (!response.success) {
            addMessage(txt.placeBet.errorSavingBet, 'error')
          } else {
            let match = matches[response.bet.match_id]
            match.bets = [response.bet]
            match.tendency_draw = response.match.tendency_draw
            match.tendency_home = response.match.tendency_home
            match.tendency_guest = response.match.tendency_guest
            setMatches(JSON.parse(JSON.stringify([match])))
            setMatchdayChips(league.Season.id, matchday, response.chips)

            this.setState({
              placeBet: response.bet,
              placeBetSuccess: setTimeout(() => { this.setState({placeBetSuccess: null}) }, 300),
              placeBetTimeout: null
            })
          }
        }
      })
    }, timeoutAPI)})
  }

  goToMatch(direction) {
    if (this.state.placeBetGoToMatchRequest) {
      return
    }

    const { txt, auth, matches, league, addMessage, setNetworkError, setMatches, openPlaceBet } = this.props

    API.fetch({
      url: Config.apiURL + '/getNextMatch',
      data: {
        direction: direction,
        match_id: this.state.placeBet.match_id
      },
      auth,
      league,
      beforeSend: () => {
        this.setState({placeBetGoToMatchRequest: true})
        this.closeInnerOverlay()
      },
      complete: () => {
        this.setState({placeBetGoToMatchRequest: false})
      },
      error: () => {
        addMessage(txt.error, 'error')
      },
      networkError: (error) => {
        setNetworkError(error)
      },
      success: (response) => {
        if (!response.success) {
          addMessage(txt.error, 'error')
        } else {
          if (!matches[response.match.id]) {
            setMatches([response.match])
          }
          openPlaceBet(response.match.id)
        }
      }
    })
  }

  toggleInnerOverlay(type) {
    if (!this.state.match.home_id || !this.state.match.guest_id) {
      return
    }
    this[this.state.innerOverlay === type ? 'closeInnerOverlay' : 'openInnerOverlay'](type)
  }

  openInnerOverlay(type) {
    if (this.state.placeBetGoToMatchRequest) {
      return
    }

    switch (type) {
      case 'form':
        !this.state.innerOverlayData[type][this.state.match.id] && this.getInnerOverlayFormData()
        break
      default:
        break
    }
    this.setState({innerOverlay: type})
  }

  closeInnerOverlay() {
    this.setState({innerOverlay: null})
  }

  getInnerOverlayFormData() {
    if (this.state.innerOverlayFormDataRequest[this.state.match.id]) {
      return
    }

    const { txt, auth, league, addMessage, setNetworkError} = this.props

    API.fetch({
      url: Config.apiURL + '/clubForm/' + this.state.match.id,
      auth,
      league,
      beforeSend: () => {
        let stateObj = {}
        stateObj[this.state.match.id] = true
        this.setState({
          innerOverlayFormDataRequest: {
            ...this.state.innerOverlayFormDataRequest,
            stateObj
          }
        })
      },
      complete: () => {
        let stateObj = {}
        stateObj[this.state.match.id] = false
        this.setState({
          innerOverlayFormDataRequest: {
            ...this.state.innerOverlayFormDataRequest,
            stateObj
          }
        })
      },
      error: () => {
        addMessage(txt.error, 'error')
      },
      networkError: (error) => {
        setNetworkError(error)
      },
      success: (response) => {
        if (!response.success) {
          addMessage(txt.error, 'error')
        } else {
          let innerOverlayData = this.state.innerOverlayData
          innerOverlayData.form[response.match.id] = response.data
          this.setState(innerOverlayData)
        }
      }
    })
  }

  showChipsError() {
    this.setState({matchdayChipsError: true})
    setTimeout(() => { this.setState({matchdayChipsError: false}) }, 400)
  }

  getHalftimeButtonStatus(result, option) {

    if (!this.state.placeBet) {
      return null
    }

    if (this.state.placeBet.joker_ht && result !== this.state.placeBet.joker_ht) {
      return false
    }
    switch (option) {
      case 'plus':
        if (this.state.placeBet.joker_ht_chips >= 3) {
          return false
        }
        break
      case 'minus':
        if (this.state.placeBet.joker_ht_chips <= 0) {
          return false
        }
        break
      default:
        return null
    }
    return true
  }

  getHalftimeButtonStatusClassName(result, option) {
    return this.getHalftimeButtonStatus(result, option) === false ? ' inactive' : ''
  }

  getHalftimeBetTitle () {

    const { txt } = this.props

    if (this.state.placeBet.joker_ht === 'draw') {
      return txt.placeBet.halftimeBetRemis
    }

    return txt.placeBet.halftimeBetTitle
  }

  getTendencyAmount(match, bet, stateBet, result, round) {

    const { serverTime } = this.props

    const matchStarted = Helper.matchStarted(match, serverTime)

    const tendencies = {
      home: parseInt(match.tendency_home, 10),
      draw: parseInt(match.tendency_draw, 10),
      guest: parseInt(match.tendency_guest, 10)
    }
    tendencies.total = tendencies.home + tendencies.draw + tendencies.guest

    const resultStateBet = Helper.matchResult(stateBet.bet_home, stateBet.bet_guest)

    if (tendencies.total === 0) {
      if (!matchStarted && result === resultStateBet) {
        return 100
      }
      return 0
    }

    if (!matchStarted) {
      const resultBet = Helper.matchResult(stateBet.bet_home, stateBet.bet_guest)
      tendencies[resultBet]--
      tendencies[resultStateBet]++
    }

    const amount = Math.round(tendencies[result] / tendencies.total * 100 * 100) / 100
    return round ? Math.round(amount) : amount
  }

  getTendencyBonusPointsClassName(points) {
    return points > 0 ? 'green' : 'gray'
  }

  getTendencyBonusPointsText(points) {
    const { txt } = this.props
    return points > 0 ? ('+ ' + points + ' ' + txt.pointsShort) : ('+ 0 ' + txt.pointsShort)
  }

  getPlaceBetPoints(match, bet, stateBet, serverTime) {
    return Helper.placeBetPoints(match, bet, stateBet, serverTime).total
  }

  isKnockoutMatch(match) {
    if (match && ['round-of-16', 'quarterfinals', 'place3', 'semi-finals', 'final'].indexOf(match.type) !== -1) {
      return true
    }
    return false
  }

  /*
  getAdvanceStatus(club, match, bet) {

    if (Helper.matchStarted) {
      return match.goals_home
    }

    console.log(club, match, bet);

    return ' a';
  }
  */

  setUserSetting(id, value) {

    const { auth, setUser } = this.props

    API.fetch({
      url: Config.apiURL + '/userSetting',
      data: {
        id,
        value
      },
      auth,
      setUser
    })
  }

  render() {

    const { txt, auth, serverTime, league, matches, matchdayChips, placeBet } = this.props

    let isKnockoutMatch = false
    let knockoutNoticeRemoved = false

    if (placeBet && placeBet.match_id) {
      isKnockoutMatch = this.isKnockoutMatch(matches[placeBet.match_id])

      if (isKnockoutMatch && auth && auth.settings) {
        try {
          let authSettings = JSON.parse(auth.settings)
          if (authSettings.knockoutNoticeRemoved) {
            knockoutNoticeRemoved = true
          }
        } catch (e) {}
      }
    }

    let matchStarted = false
    let matchFinished = false
    let bet = null

    if (placeBet && placeBet.match_id) {
      matchStarted = Helper.matchStarted(matches[placeBet.match_id], serverTime)
      matchFinished = parseInt(matches[placeBet.match_id].finished, 10) === 1
      bet = matches[placeBet.match_id].bets && matches[placeBet.match_id].bets.length ? matches[placeBet.match_id].bets[0] : null
    }

    return (
      <Transition in={placeBet && placeBet.active} timeout={200}>
        {state => (
          <div className={'place-bet-wrapper ' + state}>
            {!matchStarted && placeBet && placeBet.match_id && matches[placeBet.match_id].home_id && matches[placeBet.match_id].guest_id && isKnockoutMatch && !knockoutNoticeRemoved ? (
              <div className="place-bet-knockout-notice">
                <span className="place-bet-knockout-notice-text" dangerouslySetInnerHTML={{__html: txt.placeBet.knockoutNotice}}></span>
                <span className="place-bet-knockout-notice-button" onClick={() => { this.setUserSetting('knockoutNoticeRemoved', 1)}}>OK</span>
              </div>
            ) : null}
            {placeBet && placeBet.match_id ? (
              <div className={'place-bet-container' +
                (!matches[placeBet.match_id].home_id || !matches[placeBet.match_id].guest_id ? ' place-bet-tbd' : '') +
                (this.isKnockoutMatch(matches[placeBet.match_id]) ? ' place-bet-knockout' : '') +
                (matches[placeBet.match_id].overtime ? ' place-bet-overtime' : '') +
                (matches[placeBet.match_id].penalty_shootout ? ' place-bet-penalty-shootout' : '')
              }>
                {matches[placeBet.match_id].home_id && matches[placeBet.match_id].guest_id && matchStarted && bet ? (
                  <div className="place-bet-score-bet place-bet-score-bet-goals">
                    <div className="place-bet-score-bet-title">{txt.placeBet.yourBetTitle}</div>
                    <div className="place-bet-score-bet-goals">{bet.bet_home} : {bet.bet_guest}</div>
                  </div>
                ) : null}
                {matches[placeBet.match_id].home_id && matches[placeBet.match_id].guest_id && matchStarted && bet && (matches[placeBet.match_id].overtime || matches[placeBet.match_id].penalty_shootout) ? (
                  <div className="place-bet-score-bet place-bet-score-bet-90min">
                    <div className="place-bet-score-bet-title">{txt.placeBet.scoreAfter90min}</div>
                    <div className="place-bet-score-bet-goals">{matches[placeBet.match_id].goals_home} : {matches[placeBet.match_id].goals_guest}</div>
                  </div>
                ) : null}
                {matches[placeBet.match_id].home_id && matches[placeBet.match_id].guest_id && matchStarted && (matches[placeBet.match_id].overtime || matches[placeBet.match_id].penalty_shootout) ? (
                  <div className={'place-bet-overtime-notice' + (Helper.matchIsLive(matches[placeBet.match_id], serverTime) ? ' match-live' : '')} dangerouslySetInnerHTML={{__html: txt.placeBet[(matches[placeBet.match_id].penalty_shootout ? 'penaltyShootOut' : 'overtime') + 'Notice' + (matches[placeBet.match_id].finished ? 'FT' : '')]}} />
                ) : null}
                <div className="place-bet-content">
                  <div className="place-bet-clubs">
                    <div className="place-bet-club">
                      <div className={'place-bet-club-name' + (!matches[placeBet.match_id].home_id ? ' club-tbd' : '')}>{matches[placeBet.match_id].home['name_short_' + txt.id]}</div>
                      <div className={'club-icon club' + (matches[placeBet.match_id].home_id)} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].home.icon + '<i></i>'}}></div>
                    </div>
                      {this.state.match.home_id && this.state.match.guest_id ? (
                        matchStarted ? (
                          <div className={'place-bet-score' + (Helper.matchIsLive(matches[placeBet.match_id], serverTime) ? ' match-live' : '')}>
                            <div className="place-bet-score-home">
                              {Helper.teamGoals('home', matches[placeBet.match_id])}
                            </div>
                            <div className="place-bet-score-ht">({matches[placeBet.match_id].goals_home_ht} : {matches[placeBet.match_id].goals_guest_ht})</div>
                            <div className="place-bet-score-guest">
                              {Helper.teamGoals('guest', matches[placeBet.match_id])}
                            </div>
                          </div>
                        ) : (
                          <div className={'place-bet-input-container' + (this.state.placeBetSuccess ? ' bet-saved' : '')}>
                            <div className="place-bet-input-home">
                              <div className="icon-up place-bet-button" onTouchStart={() => { this.placeBet('goals', 1, 'home') }}></div>
                              <div className="place-bet-input">{this.state.placeBet.bet_home}</div>
                              <div className={'icon-down place-bet-button' + (this.state.placeBet.bet_home <= 0 ? ' disabled' : '')} onTouchStart={() => { this.placeBet('goals', -1, 'home') }}></div>
                            </div>
                            <div className="place-bet-input-devider">:</div>
                            <div className="place-bet-input-guest">
                              <div className="icon-up place-bet-button" onTouchStart={() => { this.placeBet('goals', 1, 'guest') }}></div>
                              <div className="place-bet-input">{this.state.placeBet.bet_guest}</div>
                              <div className={'icon-down place-bet-button' + (this.state.placeBet.bet_guest <= 0 ? ' disabled' : '')} onTouchStart={() => { this.placeBet('goals', -1, 'guest') }}></div>
                            </div>
                          </div>
                        )
                      ) : null}
                    <div className="place-bet-club">
                      <div className={'place-bet-club-name' + (!matches[placeBet.match_id].guest_id ? ' club-tbd' : '')}>{matches[placeBet.match_id].guest['name_short_' + txt.id]}</div>
                      <div className={'club-icon club' + (matches[placeBet.match_id].guest_id)} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].guest.icon + '<i></i>'}}></div>
                    </div>
                  </div>
                </div>
                {/*this.isKnockoutMatch(matches[placeBet.match_id]) ? (
                  <div className={'place-bet-knockout-choose-winner-container'}>
                    <div className={'place-bet-knockout-choose-winner-home' + this.getAdvanceStatus('home', matches[placeBet.match_id], this.state.placeBet)}>
                      Winner
                    </div>
                    <div className={'place-bet-knockout-choose-winner-icon icon-swap' + (!Helper.matchStarted(matches[placeBet.match_id]) && this.state.placeBet.bet_home === this.state.placeBet.bet_guest ? ' active' : '')}></div>
                    <div className={'place-bet-knockout-choose-winner-guest' + this.getAdvanceStatus('guest', matches[placeBet.match_id], this.state.placeBet)}>
                      Loser
                    </div>
                  </div>
                ): null*/}
                <div className="place-bet-points-container">
                  <div className={'bet-box bet-box-large place-bet-points' + (matchStarted && !bet ? ' bet-zero bet-missed icon-denied' : (!matchFinished ? ' bet-goals' : (bet && bet.points > 0 ? ' bet-scored' : ' bet-zero')))}>
                    {bet && (bet.medal_gold || bet.medal_silver || bet.medal_bronze) ? (
                      <div className={'medal medal-' + (bet.medal_gold ? 'gold' : bet.medal_silver ? 'silver' : 'bronze')} />
                    ) : null}
                    <span className="place-bet-points-label">{txt.points}</span>
                    <span>{matchStarted && !bet ? '' : (matchFinished ? bet.points : (this.getPlaceBetPoints(matches[placeBet.match_id], bet, this.state.placeBet, serverTime)))}</span>
                    {/*!matchStarted ? (
                      <div className={'shield shield-' + Helper.shieldClass(bet ? bet.shields : 0)}></div>
                    ) : null*/}
                  </div>
                </div>
                <div className="place-bet-jokers">
                  <div className={'joker joker-x2' + (!this.state.placeBet.joker_x2 ? ' joker-off' : '')} onClick={() => { this.placeBet('joker-x2') }}>{txt.jokerX2}</div>
                  <div className={'joker joker-ht' + (!this.state.placeBet.joker_ht ? ' joker-off' : '')} onClick={() => { !matchStarted && this.toggleInnerOverlay('jokerHt') }}>{txt.jokerHt}</div>
                </div>
                <Transition in={this.state.innerOverlay !== null} timeout={200}>
                  {state => (
                    <div className={'place-bet-inner-overlay-container place-bet-inner-overlay-container-' + this.state.innerOverlay + ' ' + state}>
                      <div className="place-bet-inner-overlay-pointer"></div>

                      <div className="place-bet-inner-overlay place-bet-inner-overlay-tendencies">
                        <div className="place-bet-tendencies-unlocked">
                          <div className="place-bet-tendencies-unlocked-title-container">
                            <div className="place-bet-tendencies-unlocked-title">{txt.placeBet.tendenciesTitle}</div>
                            <div className={'place-bet-tendencies-unlocked-bonus-points ' + this.getTendencyBonusPointsClassName(Helper.tendencyPoints(this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, Helper.matchResult(this.state.placeBet.bet_home, this.state.placeBet.bet_guest)) / 100))}>
                              {this.getTendencyBonusPointsText(Helper.tendencyPoints(this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, Helper.matchResult(this.state.placeBet.bet_home, this.state.placeBet.bet_guest)) / 100))}
                            </div>
                          </div>
                          <div className="place-bet-tendencies-unlocked-wrapper">
                            <div className="place-bet-tendencies-unlocked-container">
                              <div className={'place-bet-tendencies-row' + (Helper.matchResult(this.state.placeBet.bet_home, this.state.placeBet.bet_guest) === 'home' ? ' active' : '')}>
                                <div className={'club-icon club' + matches[placeBet.match_id].home_id} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].home.icon + '<i></i>'}}></div>
                                <div className="place-bet-tendencies-status-bar-wrapper">
                                  <div className="place-bet-tendencies-status-bar-container"></div>
                                  <div className="place-bet-tendencies-status-bar" style={{width: 'calc(' + this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, 'home') + '% + 2px)'}}></div>
                                </div>
                                <div className='place-bet-tendencies-status-amount'>{this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, 'home', true)}%</div>
                              </div>
                              <div className={'place-bet-tendencies-row' + (Helper.matchResult(this.state.placeBet.bet_home, this.state.placeBet.bet_guest) === 'draw' ? ' active' : '')}>
                                <div className="club-icon-draw-container">
                                  <div className={'club-icon club' + matches[placeBet.match_id].home_id + ' club-icon-draw-home'} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].home.icon + '<i></i>'}}></div>
                                  <div className={'club-icon club' + matches[placeBet.match_id].guest_id + ' club-icon-draw-guest'} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].guest.icon + '<i></i>'}}></div>
                                </div>
                                <div className="place-bet-tendencies-status-bar-wrapper">
                                  <div className="place-bet-tendencies-status-bar-container"></div>
                                  <div className="place-bet-tendencies-status-bar" style={{width: 'calc(' + this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, 'draw') + '% + 2px)'}}></div>
                                </div>
                                <div className='place-bet-tendencies-status-amount'>{this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, 'draw', true)}%</div>
                              </div>
                              <div className={'place-bet-tendencies-row' + (Helper.matchResult(this.state.placeBet.bet_home, this.state.placeBet.bet_guest) === 'guest' ? ' active' : '')}>
                                <div className={'club-icon club' + matches[placeBet.match_id].guest_id} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].guest.icon + '<i></i>'}}></div>
                                <div className="place-bet-tendencies-status-bar-wrapper">
                                  <div className="place-bet-tendencies-status-bar-container"></div>
                                  <div className="place-bet-tendencies-status-bar" style={{width: 'calc(' + this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, 'guest') + '% + 2px)'}}></div>
                                </div>
                                <div className='place-bet-tendencies-status-amount'>{this.getTendencyAmount(matches[placeBet.match_id], bet, this.state.placeBet, 'guest', true)}%</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="place-bet-inner-overlay place-bet-inner-overlay-jokerHt">
                        <div className="place-bet-inner-overlay-jokerHt-header">
                          <div className={'place-bet-inner-overlay-jokerHt-headline' + (this.state.placeBet.joker_ht ? ' active' : '')}>
                          {this.getHalftimeBetTitle()}
                          </div>
                          <div className="place-bet-inner-overlay-jokerHt-chips">
                            <div className={'chip' + (!bet || parseInt(bet.joker_ht_chips, 10) < 3 ? ' grayscale' : '')}></div>
                            <div className={'chip' + (!bet || parseInt(bet.joker_ht_chips, 10) < 2 ? ' grayscale' : '')}></div>
                            <div className={'chip' + (!bet || parseInt(bet.joker_ht_chips, 10) < 1 ? ' grayscale' : '')}></div>
                          </div>
                          <div className="place-bet-inner-overlay-jokerHt-close-button icon-x" onClick={() => { this.closeInnerOverlay()}}></div>
                        </div>

                        <div className="place-bet-inner-overlay-jokerHt-content">
                          <div className="place-bet-inner-overlay-jokerHt-bets">
                            <div className="place-bet-inner-overlay-jokerHt-bet">
                              <div className={'club-icon club' + matches[placeBet.match_id].home_id + (this.state.placeBet.joker_ht && this.state.placeBet.joker_ht !== 'home' ? ' grayscale' : '')} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].home.icon + '<i></i>'}}></div>
                              <div className="place-bet-inner-overlay-jokerHt-buttons">
                                <div className={'icon-plus halftime-button button-count button-plus' + this.getHalftimeButtonStatusClassName('home', 'plus')} onTouchStart={() => { this.placeBet('joker-ht', 'home', 'plus') }}></div>
                                <div className={'icon-minus halftime-button button-count button-minus' + this.getHalftimeButtonStatusClassName('home', 'minus')} onTouchStart={() => { this.placeBet('joker-ht', 'home', 'minus') }}></div>
                              </div>
                            </div>
                            <div className="place-bet-inner-overlay-jokerHt-bet">
                              <div className={'club-icon-draw-container' + (this.state.placeBet.joker_ht && this.state.placeBet.joker_ht !== 'draw' ? ' grayscale' : '')}>
                                <div className={'club-icon club' + matches[placeBet.match_id].home_id + ' club-icon-draw-home'} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].home.icon + '<i></i>'}}></div>
                                <div className={'club-icon club' + matches[placeBet.match_id].guest_id + ' club-icon-draw-guest'} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].guest.icon + '<i></i>'}}></div>
                              </div>
                              <div className="place-bet-inner-overlay-jokerHt-buttons">
                                <div className={'icon-plus halftime-button button-count button-plus' + this.getHalftimeButtonStatusClassName('draw', 'plus')} onTouchStart={() => { this.placeBet('joker-ht', 'draw', 'plus') }}></div>
                                <div className={'icon-minus halftime-button button-count button-minus' + this.getHalftimeButtonStatusClassName('draw', 'minus')} onTouchStart={() => { this.placeBet('joker-ht', 'draw', 'minus') }}></div>
                              </div>
                            </div>
                            <div className="place-bet-inner-overlay-jokerHt-bet">
                              <div className={'club-icon club' + matches[placeBet.match_id].guest_id + (this.state.placeBet.joker_ht && this.state.placeBet.joker_ht !== 'guest' ? ' grayscale' : '')} dangerouslySetInnerHTML={{__html: matches[placeBet.match_id].guest.icon + '<i></i>'}}></div>
                              <div className="place-bet-inner-overlay-jokerHt-buttons">
                                <div className={'icon-plus halftime-button button-count button-plus' + this.getHalftimeButtonStatusClassName('guest', 'plus')} onTouchStart={() => { this.placeBet('joker-ht', 'guest', 'plus') }}></div>
                                <div className={'icon-minus halftime-button button-count button-minus' + this.getHalftimeButtonStatusClassName('guest', 'minus')} onTouchStart={() => { this.placeBet('joker-ht', 'guest', 'minus') }}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {bet && (
                        <div className="place-bet-inner-overlay place-bet-inner-overlay-shields">
                          <div className="place-bet-add-shields-wrapper">
                            {/*
                            <div className="place-bet-add-shields-preview">
                              <div className={'shield shield-' + Helper.shieldClass(bet.shields)}></div>
                            </div>
                            */}
                            <div className="place-bet-add-shields-container">
                              <div className="place-bet-add-shields-row" onClick={() => { this.addShields(bet.id, '-1') }}>
                                <div className={'place-bet-add-shields-button place-bet-add-shields-button-power-shield button-green' + (auth && parseInt(auth.free_power_shields, 10) <= 0 && auth.coins < 500 ? ' disabled' : '')}>∞</div>
                                <div className={'place-bet-add-shields-icon-shield shield shield-level5' + (auth && parseInt(auth.free_power_shields, 10) < 1 && auth.coins < 500 ? ' grayscale' : '')}></div>
                                <div className="place-bet-add-shields-spacer"></div>
                                {auth && parseInt(auth.free_power_shields, 10) >= 1 ? (
                                  <div className="place-bet-add-shields-free-container">
                                    {txt.placeBet.freeShields.replace('{0}', auth.free_power_shields)}
                                  </div>
                                ) : (
                                  <CSSTransition
                                    in={this.state.shieldChipsError === 500}
                                    timeout={{
                                      enter: 400,
                                      exit: 0
                                    }}
                                    classNames='shake-small'
                                  >
                                    <div className="place-bet-add-shields-price-container">
                                      <div className="place-bet-add-shields-coin-amount">-500</div>
                                      <div className="place-bet-add-shields-icon-coins icon-coins"></div>
                                    </div>
                                  </CSSTransition>
                                )}
                              </div>
                              <div className="place-bet-add-shields-row" onClick={() => { this.addShields(bet.id, 10) }}>
                                <div className={'place-bet-add-shields-button button-green' + (auth && parseInt(auth.free_shields, 10) < 10 && auth.coins < 70 ? ' disabled' : '')}>+10</div>
                                {/*<div className={'place-bet-add-shields-icon-shield shield shield-' + Helper.shieldClass(bet.shields + 10) + (auth && parseInt(auth.free_shields, 10) < 10 && auth.coins < 70 ? ' grayscale' : '')}></div>*/}
                                <div className="place-bet-add-shields-spacer"></div>
                                {auth && parseInt(auth.free_shields, 10) >= 10 ? (
                                  <div className="place-bet-add-shields-free-container">
                                    {txt.placeBet.freeShields.replace('{0}', auth.free_shields)}
                                  </div>
                                ) : (
                                  <CSSTransition
                                    in={this.state.shieldChipsError === 70}
                                    timeout={{
                                      enter: 400,
                                      exit: 0
                                    }}
                                    classNames='shake-small'
                                  >
                                    <div className="place-bet-add-shields-price-container">
                                      <div className="place-bet-add-shields-coin-amount">-70</div>
                                      <div className="place-bet-add-shields-icon-coins icon-coins"></div>
                                    </div>
                                  </CSSTransition>
                                )}
                              </div>
                              <div className="place-bet-add-shields-row" onClick={() => { this.addShields(bet.id, 5) }}>
                                <div className={'place-bet-add-shields-button button-green' + (auth && parseInt(auth.free_shields, 10) < 5 && auth.coins < 40 ? ' disabled' : '')}>+5</div>
                                {/*<div className={'place-bet-add-shields-icon-shield shield shield-' + Helper.shieldClass(bet.shields + 5) + (auth && parseInt(auth.free_shields, 10) < 5 && auth.coins < 40 ? ' grayscale' : '')}></div>*/}
                                <div className="place-bet-add-shields-spacer"></div>
                                {auth && parseInt(auth.free_shields, 10) >= 5 ? (
                                  <div className="place-bet-add-shields-free-container">
                                    {txt.placeBet.freeShields.replace('{0}', auth.free_shields)}
                                  </div>
                                ) : (
                                  <CSSTransition
                                    in={this.state.shieldChipsError === 40}
                                    timeout={{
                                      enter: 400,
                                      exit: 0
                                    }}
                                    classNames='shake-small'
                                  >
                                    <div className="place-bet-add-shields-price-container">
                                      <div className="place-bet-add-shields-coin-amount">-40</div>
                                      <div className="place-bet-add-shields-icon-coins icon-coins"></div>
                                    </div>
                                  </CSSTransition>
                                )}
                              </div>
                              <div className="place-bet-add-shields-row" onClick={() => { this.addShields(bet.id, 1) }}>
                                <div className={'place-bet-add-shields-button button-green' + (auth && parseInt(auth.free_shields, 10) < 1 && auth.coins < 10 ? ' disabled' : '')}>+1</div>
                                {/*<div className={'place-bet-add-shields-icon-shield shield shield-' + Helper.shieldClass(bet.shields + 1) + (auth && parseInt(auth.free_shields, 10) < 1 && auth.coins < 10 ? ' grayscale' : '')}></div>*/}
                                <div className="place-bet-add-shields-spacer"></div>
                                {auth && parseInt(auth.free_shields, 10) >= 1 ? (
                                  <div className="place-bet-add-shields-free-container">
                                    {txt.placeBet.freeShields.replace('{0}', auth.free_shields)}
                                  </div>
                                ) : (
                                  <CSSTransition
                                    in={this.state.shieldChipsError === 10}
                                    timeout={{
                                      enter: 400,
                                      exit: 0
                                    }}
                                    classNames='shake-small'
                                  >
                                    <div className="place-bet-add-shields-price-container">
                                      <div className="place-bet-add-shields-coin-amount">-10</div>
                                      <div className="place-bet-add-shields-icon-coins icon-coins"></div>
                                    </div>
                                  </CSSTransition>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="place-bet-inner-overlay place-bet-inner-overlay-form">
                        {!this.state.innerOverlayData.form[this.state.match.id] ? (
                          <div></div>
                        ) : (
                          <div className="place-bet-form-wrapper">
                            <div className="place-bet-form-latest-matches-container place-bet-form-latest-matches-container-home">
                              {this.state.innerOverlayData.form[this.state.match.id].home.map((match, index) => {
                                return (
                                  <div className="place-bet-form-latest-matches" key={index}>
                                    <div className={'club-icon club' + match.home_id + (match.home_id !== matches[placeBet.match_id].home_id ? ' defeated grayscale' : '')}><img src={'/img/clubs/' + match.home_id + '.svg'} alt=""/><i/></div>
                                    <div className={'place-bet-form-latest-matches-result' + (
                                      (this.state.match.home.id === match.home_id && match.goals_home < match.goals_guest) ||
                                      (this.state.match.home.id === match.guest_id && match.goals_home > match.goals_guest) ? ' red' :
                                      (this.state.match.home.id === match.home_id && match.goals_home > match.goals_guest) ||
                                      (this.state.match.home.id === match.guest_id && match.goals_home < match.goals_guest) ? ' green' : ''
                                    )}>{match.goals_home} : {match.goals_guest}</div>
                                    <div className={'club-icon club' + match.guest_id + (match.guest_id !== matches[placeBet.match_id].home_id ? ' defeated grayscale' : '')}><img src={'/img/clubs/' + match.guest_id + '.svg'} alt=""/><i/></div>
                                  </div>
                                )
                              })}
                            </div>

                            <div className="place-bet-form-vs-matches-container">
                                <div className="place-bet-form-vs-matches-rows">
                                {this.state.innerOverlayData.form[this.state.match.id].vs.map((match, index) => {
                                  return (
                                    <div className="place-bet-form-vs-matches-row" key={index}>
                                      <div className="place-bet-form-vs-matches-time">{Helper.fullDate(match.match_time, serverTime, txt)}</div>
                                      <div className="place-bet-form-vs-matches">
                                        <div className={'club-icon club' + match.home_id + (match.goals_home < match.goals_guest ? ' defeated grayscale' : '')}><img src={'/img/clubs/' + match.home_id + '.svg'} alt=""/><i/></div>
                                        <div className="place-bet-form-vs-matches-result">{match.goals_home} : {match.goals_guest}</div>
                                        <div className={'club-icon club' + match.guest_id + (match.goals_home > match.goals_guest ? ' defeated grayscale' : '')}><img src={'/img/clubs/' + match.guest_id + '.svg'} alt=""/><i/></div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>

                            <div className="place-bet-form-latest-matches-container place-bet-form-latest-matches-container-guest">
                              {this.state.innerOverlayData.form[this.state.match.id].guest.map((match, index) => {
                                return (
                                  <div className="place-bet-form-latest-matches" key={index}>
                                    <div className={'club-icon club' + match.home_id + (match.home_id !== matches[placeBet.match_id].guest_id ? ' defeated grayscale' : '')}><img src={'/img/clubs/' + match.home_id + '.svg'} alt=""/><i/></div>
                                    <div className={'place-bet-form-latest-matches-result' + (
                                      (this.state.match.guest.id === match.home_id && match.goals_home < match.goals_guest) ||
                                      (this.state.match.guest.id === match.guest_id && match.goals_home > match.goals_guest) ? ' red' :
                                      (this.state.match.guest.id === match.home_id && match.goals_home > match.goals_guest) ||
                                      (this.state.match.guest.id === match.guest_id && match.goals_home < match.goals_guest) ? ' green' : ''
                                    )}>{match.goals_home} : {match.goals_guest}</div>
                                    <div className={'club-icon club' + match.guest_id + (match.guest_id !== matches[placeBet.match_id].guest_id ? ' defeated grayscale' : '')}><img src={'/img/clubs/' + match.guest_id + '.svg'} alt=""/><i/></div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  )}
                </Transition>
                {!matches[placeBet.match_id].home_id || !matches[placeBet.match_id].guest_id ? (
                  <div className="place-bet-tbd-notice">
                    {txt.placeBet.toBeDecidedNotice}
                  </div>
                ) : null}
                <div className="place-bet-footer">
                  <div className={'place-bet-nav place-bet-nav-prev icon-left' + (false ? ' disabled' : '')} onClick={() => { this.goToMatch('prev') }}></div>
                  {this.state.match.home_id && this.state.match.guest_id ? (
                    <div className={'place-bet-nav place-bet-nav-action place-bet-nav-form icon-meter' + (this.state.innerOverlay === 'form' ? ' active' : '')} onClick={() => { this.toggleInnerOverlay('form') }}></div>
                  ) : null}
                  {this.state.match.home_id && this.state.match.guest_id ? (
                    <div className={'place-bet-nav place-bet-nav-action place-bet-nav-tendencies icon-bet-trends' + (this.state.innerOverlay === 'tendencies' ? ' active' : '')} onClick={() => { this.toggleInnerOverlay('tendencies') }}></div>
                  ) : null}
                  {/*this.state.match.home_id && this.state.match.guest_id && !matchStarted && bet ? (
                    <div className={'place-bet-nav place-bet-nav-action place-bet-nav-shields icon-shield' + (this.state.innerOverlay === 'shields' ? ' active' : '')} onClick={() => { parseInt(bet.shields, 10) !== -1 && this.toggleInnerOverlay('shields') }}>
                      <div className="place-bet-nav-shields-amount">{parseInt(bet.shields, 10) === -1 ? '∞' : bet.shields}</div>
                      {this.state.innerOverlay === null && parseInt(bet.shields, 10) !== -1 && (
                        <div className="place-bet-nav-shields-icon-plus icon-plus"></div>
                      )}
                    </div>
                      ) : null*/}
                  <div className="place-bet-nav-spacer"></div>
                  {this.state.match.home_id && this.state.match.guest_id && !matchStarted ? (
                    <CSSTransition
                      in={this.state.matchdayChipsError}
                      timeout={{
                        enter: 400,
                        exit: 0
                      }}
                      classNames='shake'
                    >
                      <div className="place-bet-chips-container chips-container">
                        <div className="chips-number">{Helper.getMatchdayChips(matchdayChips, league.Season.id, matches[placeBet.match_id].matchday)}</div>
                        <div className="chip"></div>
                      </div>
                    </CSSTransition>
                  ) : null}
                  <div className={'place-bet-nav place-bet-nav-next icon-right' + (false ? ' disabled' : '')} onClick={() => { this.goToMatch('next') }}></div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </Transition>
    )
  }
}

export default PlaceBetComponent;
