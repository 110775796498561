import { connect } from 'react-redux';
import { setLocation, setLoginLocation, addMessage, setNetworkError, setLeague, setUser, setServerTime, setContentLoading, setAppLoading } from '../../../actions';
import mapToComponent from './UserComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league
});

const mapDispatchToProps = dispatch => ({
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setServerTime: serverTime => dispatch(setServerTime(serverTime)),
  setLeague: league => dispatch(setLeague(league)),
  setUser: user => dispatch(setUser(user)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  setAppLoading: loading => dispatch(setAppLoading(loading)),
  setContentLoading: loading => dispatch(setContentLoading(loading)),
  setLocation: location => dispatch(setLocation(location)),
  setLoginLocation: location => dispatch(setLoginLocation(location)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
