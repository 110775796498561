import { connect } from 'react-redux';
import { setServerTime, addMessage, setNetworkError, setMatches } from '../../actions';
import mapToComponent from './CupMatchesComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league,
  serverTime: state.serverTime,
  matches: state.matches
});

const mapDispatchToProps = dispatch => ({
  setMatches: matches => dispatch(setMatches(matches)),
  setServerTime: serverTime => dispatch(setServerTime(serverTime)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
