const location = (state = null, action) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return action.location &&
        action.location.pathname &&
        action.location.pathname.substr(1) ? action.location.pathname.substr(1) : 'league';
    case 'GET_LOCATION':
      return state;
    default:
      return state;
  }
};

export default location;