import React from 'react';

class Page extends React.PureComponent {
  
  componentDidMount() {
    const { setLocation, location } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
  }

  render() {
    const { txt } = this.props;

    return (
      <div className="content-wrapper">
        <div className="content-error-container">
          <h1>{txt.pages.PageNotFound.headline}</h1>
          <h3>{txt.pages.PageNotFound.subline}</h3>
        </div>
      </div>
    );
  }
}

export default Page;
