import uuid from 'uuid';

const messages = (state = [], action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return [
        ...state,
        {
          className: action.className || null,
          message: action.message,
          id: uuid()
        }
      ];
    case 'REMOVE_MESSAGE':
      return state.filter(message => {
        if (message.id === action.id) {
          return false;
        }
        return true;
      });
    case 'REMOVE_MESSAGES':
      if (action.id) {
        return state.filter(message => {
          if (message.id === action.id) {
            return true;
          }
          return false;
        });
      }
      return [];
    default:
      return state;
  }
};

export default messages;