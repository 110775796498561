import React from 'react';
import Config from '../../../config';
import API from '../../../util/API';
import Helper from '../../../util/Helper';
import ContentError from '../../ContentError/ContentErrorContainer';
import ContentLoading from '../../../util/ContentLoading';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      standings: []
    };
  }

  componentDidMount() {
    const { setLocation, setLoginLocation, location } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);
    this.fetchTable();
  }

  fetchTable() {
    const { txt, auth, league, setLeague, setServerTime, addMessage, setNetworkError, setContentLoading, setAppLoading } = this.props;

    API.fetch({
      url: Config.apiURL + '/standings',
      txt,
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        ContentLoading.startContentLoading(league ? setContentLoading : setAppLoading);
      },
      complete: () => {
        ContentLoading.endContentLoading(league ? setContentLoading : setAppLoading);
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        response.league && setLeague(response.league);

        response.standings && response.standings.length && this.setState({
          standings: response.standings
        });
      }
    });
  }

  render() {
    const { txt, auth, league } = this.props;

    const standingsStatus = {
      // Standings Bundesliga
      1: {
        table: {
          1: 'cl',
          2: 'cl',
          3: 'cl',
          4: 'clq',
          5: 'el',
          6: 'elq',
          16: 'dwr',
          17: 'dw',
          18: 'dw'
        },
        legend: [
          {id: 'cl', name: txt.pages.standings.legendCl},
          {id: 'clq', name: txt.pages.standings.legendClQ},
          {id: 'el', name: txt.pages.standings.legendEl},
          {id: 'elq', name: txt.pages.standings.legendElQ},
          {id: 'dwr', name: txt.pages.standings.legendDwR},
          {id: 'dw', name: txt.pages.standings.legendDw},
        ]
      },
      // Standings Premier League
      3: {
        table: {
          1: 'cl',
          2: 'cl',
          3: 'cl',
          4: 'clq',
          5: 'el',
          18: 'dw',
          19: 'dw',
          20: 'dw',
        },
        legend: [
          {id: 'cl', name: txt.pages.standings.legendCl},
          {id: 'clq', name: txt.pages.standings.legendClQ},
          {id: 'el', name: txt.pages.standings.legendEl},
          {id: 'elq', name: txt.pages.standings.legendElQ},
          {id: 'dw', name: txt.pages.standings.legendDw},
        ]
      }
    };

    let played = 0;
    if (this.state.standings.length) {
      this.state.standings.forEach(item => {
        if (item.played > played) {
          played = item.played;
        }
      });
    }

    return (
      <div className="content-wrapper">
        {!league ? (
          <ContentError />
        ) : (
          <div>
            <div className="content-container">
              <h1>{league.League['name_' + txt.id]}</h1>
            </div>

            <div className="standings-wrapper">
              {this.state.standings.length ? (
                <div className="standings-header">
                  <div className="standing-place"></div>
                  <div className="standing-change"></div>
                  <div className="standing-club-icon"></div>
                  <div className="standing-club-name-container">{txt.pages.standings.headerClubName}</div>
                  <div className="standing-played">{txt.pages.standings.headerPlayed}</div>
                  <div className="standing-won">{txt.pages.standings.headerWon}</div>
                  <div className="standing-draw">{txt.pages.standings.headerDraw}</div>
                  <div className="standing-lost">{txt.pages.standings.headerLost}</div>
                  <div className="standing-goals">{txt.pages.standings.headerGoals}</div>
                  <div className="standing-goals-diff">{txt.pages.standings.headerGoalsDiff}</div>
                  <div className="standing-points">{txt.pages.standings.headerPoints}</div>
                </div>
              ) : null}

              {this.state.standings.length ? (
                <div className="standing-rows">
                  {this.state.standings.map((standing, index) => {
                    return (
                      <div className={'standing-row standing-row' + (index + 1)} key={index}>
                        <div className={'standing-place' + (standingsStatus[league.League.id] && standingsStatus[league.League.id].table[standing.place] ? ' standing-status-' + standingsStatus[league.League.id].table[standing.place] : '')}>{standing.place}</div>
                        <div className="standing-change">{standing.place < standing.place_last ? <div className="icon-caret-up"></div> : (standing.place > standing.place_last ? <div className="icon-caret-down"></div> : '')}</div>
                        <div className="standing-club-icon"><div className={'club-icon club' + standing.club.id + (Helper.favClub(auth, standing.club.id) ? ' fav' : '')} dangerouslySetInnerHTML={{__html: standing.club.icon + '<i></i>'}}></div></div>
                        <div className={'standing-club-name-container' + (Helper.favClub(auth, standing.club.id) ? ' fav' : '')}>
                          <div className="club-name standing-club-name">{standing.club['name_' + txt.id]}</div>
                          <div className="club-name standing-club-name-short">{standing.club['name_short_' + txt.id]}</div>
                          <div className="club-name standing-club-name-tiny">{standing.club.name_tiny}</div>
                        </div>
                        <div className={'standing-played' + (standing.played < played ? ' standing-played-less' : '')}>{standing.played}</div>
                        <div className="standing-won">{standing.won}</div>
                        <div className="standing-draw">{standing.draw}</div>
                        <div className="standing-lost">{standing.lost}</div>
                        <div className="standing-goals">{standing.goals}:{standing.goals_received}</div>
                        <div className="standing-goals-diff">{standing.goals_diff}</div>
                        <div className="standing-points">{standing.points}</div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>

            <div className="standings-legend">
              {standingsStatus[league.League.id] ? (
                standingsStatus[league.League.id].legend.map((standing, index) => {
                  return (
                    <div className="standings-legend-row" key={index}>
                      <div className={'standings-legend-status standing-status-' + standing.id}></div>
                      <div className="standings-legend-label">{standing.name}</div>
                    </div>
                  );
                })
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Page;
