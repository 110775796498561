import React from 'react';

const ContentEN = () => (
  <div className="content-wrapper-text">
  <div className="content-text">
    <h1>KICKPros Point System</h1>

    <p>
      When a match is finished you can get <b>Match Points</b> and <b>Bet Trend Points</b>.
    </p>

    <h2>Match Points</h2>

    <p>
      You can get up to 15 match points for a bet
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left point-system-points green">8 P</div>
    <div className="point-system-col-right">
      <b>Match outcome</b>
      <p>
        You will get 8 points when you bet the outcome of a match correctly (winning team or draw)
      </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left point-system-points green">3 P</div>
    <div className="point-system-col-right">
      <b>Goal difference</b>
      <p>
        When the goal difference of your bet is correct you will get 3 points, e.g. your bet is 2 : 3, match outcome is 1 : 2
      </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left point-system-points green">2 P</div>
    <div className="point-system-col-right">
      <b>Amount of goals</b>
      <p>
        When you bet the amount of goals for the home or guest team correctly, you will get 2 points respectively
      </p>
    </div>
    </div>

    <h2>Bet Trend Points</h2>

    <p>
      Bet trends tell you how many players have bet on which match outcome.
    </p>
    <p>
      You can get bonus points when you bet on the outcome on which less than 45% of other players have bet on.
      The fewer players have bet on the same outcome as you, the more bonus points you can get.
    </p>
    <p>
      You can get a maximum of 8 bet trend points.
    </p>

    <h2>Medals</h2>
    <p>
      Great bets get awarded with medals
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-medal medal-gold"></div></div>
    <div className="point-system-col-right">
      <b>Gold Medal</b>
      <p>
        Awarded for a completely correct bet
      </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-medal medal-silver"></div></div>
    <div className="point-system-col-right">
      <b>Silver Medal</b>
      <p>
        Awarded for a correct goal difference
      </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-medal medal-bronze"></div></div>
    <div className="point-system-col-right">
      <b>Bronze Medal</b>
      <p>
        Awarded when you bet the winner and one goal amount correctly
      </p>
    </div>
    </div>

    <h2>Shields</h2>

    <p>
      Hide your bets with a shield
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-shield shield"></div></div>
    <div className="point-system-col-right">
        <p>
          By default, your bets are visible to anyone. Every 20 hours you can hide one of your bets with a shield.
        </p>
    </div>
    </div>

    <h2>Knockout Matches</h2>

    <p>
      All bets on KICKPros are bet on the result <b className="b500">after 90 minutes</b>
    </p>

    <p>
      This is also the case for knockout matches, in which the result might be determined by extra time or penalty shootout.
    </p>

    <h2>Chips</h2>

    <p>
      Each matchday of a <b className="b500">league game</b> (e.g. Premier League), you get 5 free chips.
      In a <b className="b500">cup game</b> (e.g. Champions League, World Cup), you start with 10 chips.
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-chip chip"></div></div>
    <div className="point-system-col-right">
      <b>Earn more chips</b>
      <p>
        You get more chips for your medals (5 for gold, 3 for silver and 1 for bronze).
      </p>

      <p>
        In league games, chips are only valid for the respective matchday and can not be accumulated.
      </p>
    </div>
    </div>

    <h2>Jokers</h2>

    <p>
      You can spend your chips on jokers
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-joker joker joker-x2">×2</div></div>
    <div className="point-system-col-right">
      <b>Double Joker (5 chips)</b>
      <p>
        Doubles the total amount of points (including trend points and halftime joker points)
      </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-joker joker joker-ht">Ht</div></div>
    <div className="point-system-col-right">
      <b>Halftime Joker (1 to 3 chips)</b>
      <p>
        You can set up to 3 chips on the halftime result. If bet correctly, you get one point for each chip.
        With a bet trend of up to 10% you get 2 points per chip
      </p>
    </div>
    </div>
  </div>
  </div>
);

export default ContentEN;
