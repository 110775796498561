const league = (state = null, action) => {
  switch (action.type) {
    case 'SET_LEAGUE':
      try {
        localStorage.setItem('league', JSON.stringify(action.league));
        return action.league;
      } catch (e) {
        return null;
      }
    case 'GET_LEAGUE':
      try {
        return state || JSON.parse(localStorage.getItem('league'));
      } catch (e) {
        return null;
      }
    default:
      return state;
  }
};

export default league;