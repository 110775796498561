import Helper from './Helper';
import Config from '../config';

const API = {

  isFetching: {},

  fetch: function ({ url, data = {}, txt, auth, setUser, setServerTime, setLeague, league, beforeSend, complete, success, error, networkError }) {

    if (this.isFetching[url]) {
      return;
    }

    this.isFetching[url] = true;

    beforeSend && beforeSend();

    if (auth && !data.user) {
      data.user = {
        id: auth.id,
        apiKey: auth.apiKey
      };
    }

    if (league) {
      !data.league_id && data.league_id !== null && (data.league_id = league.League.id);
      !data.season_id && data.season_id !== null && (data.season_id = league.Season.id);
    }

    txt && (data.language = txt.id);

    // TODO Pass language

    fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data || {})
    })
    .then(
      response => {
        networkError && networkError(false);
        complete && complete();
        this.isFetching[url] = false;

        if (response.status !== 200) {
          error && error();
          return;
        }

        response.json().then(response => {
          response.auth && setUser && Helper.shouldAuthUpdate(response.auth, auth) && setUser(response.auth);
          response.league && setLeague && Helper.shouldLeagueUpdate(response.league, league) && setLeague(response.league);
          response.server_time && setServerTime && setServerTime({
            date: response.server_time,
            timezoneOffset: response.timezone_offset ? parseInt(response.timezone_offset, 10) : 0
          });
          success && success(response);
        })
        .catch((err) => {
          Config.debug && console.log(err);
          error && error();
        });
      }
    )
    .catch((err) => {
      Config.debug && console.log(err);
      this.isFetching[url] = false;
      complete && complete();
      networkError && networkError(true);
    });
  }
};

export default API;
