// Auth

export const getUser = () => ({type: 'GET_USER'});
export const setUser = user => ({type: 'SET_USER', user});

// User Nav Items

export const setUserScore = score => ({type: 'SET_USER_SCORE', score});
export const setUserGroups = groups => ({type: 'SET_USER_GROUPS', groups});
export const setUserNotifications = notifications => ({type: 'SET_USER_NOTIFICATIONS', notifications});

// Location

export const getLocation = () => ({type: 'GET_LOCATION'});
export const setLocation = location => ({type: 'SET_LOCATION', location});
export const setLoginLocation = location => ({type: 'SET_LOGIN_LOCATION', location});

// Place Bet

export const openPlaceBet = match_id => ({type: 'OPEN_PLACE_BET', match_id});
export const closePlaceBet = () => ({type: 'CLOSE_PLACE_BET'});

// Server Time

export const setServerTime = serverTime => ({type: 'SET_SERVER_TIME', serverTime});

// Localization

export const getLanguage = () => ({type: 'GET_LANGUAGE'});
export const setLanguage = language => ({type: 'SET_LANGUAGE', language});

// Navigation

export const openNav = () => ({type: 'OPEN_NAV'});
export const closeNav = () => ({type: 'CLOSE_NAV'});
export const toggleNav = () => ({type: 'TOGGLE_NAV'});

// Sub navigation

export const openSubNav = id => ({type: 'OPEN_SUBNAV', id});
export const closeSubNav = () => ({type: 'CLOSE_SUBNAV'});
export const toggleSubNav = id => ({type: 'TOGGLE_SUBNAV', id});

// User navigation

export const openUserNav = id => ({type: 'OPEN_USERNAV', id});
export const closeUserNav = () => ({type: 'CLOSE_USERNAV'});
export const toggleUserNav = id => ({type: 'TOGGLE_USERNAV', id});

// League

export const getLeague = () => ({type: 'GET_LEAGUE'});
export const setLeague = league => ({type: 'SET_LEAGUE', league});

// Matches

export const setMatches = matches => ({type: 'SET_MATCHES', matches});

// Matchday chips

export const setMatchdayChips = (season_id, matchday, chips) => ({type: 'SET_MATCHDAY_CHIPS', season_id, matchday, chips});

// Messages

export const addMessage = (message, className) => ({type: 'ADD_MESSAGE', message, className});
export const removeMessage = id => ({type: 'REMOVE_MESSAGE', id});
export const removeMessages = id => ({type: 'REMOVE_MESSAGES', id});

// Network Error

export const setNetworkError = error => ({type: 'SET_NETWORK_ERROR', error});

// App / Content Loading

export const setAppLoading = loading => ({type: 'SET_APP_LOADING', loading});
export const setContentLoading = loading => ({type: 'SET_CONTENT_LOADING', loading});
