import React from 'react';
import IsolatedScroll from 'react-isolated-scroll';
import { Link } from 'react-router-dom';
import Helper from '../../util/Helper';

class SubNavComponent extends React.PureComponent {

  render() {
    const { txt, league, location, activeSubNav, closeNav, closeSubNav } = this.props;

    // const navWorldCuptitle = txt && txt.id === 'de' ? 'WM 2018' : 'World Cup 2018';

    return (
      <IsolatedScroll className="subnav-wrapper">
        <div className="subnav-container">

          <div className={'subnav-items' + (activeSubNav === 'leagues' ? ' active' : '')}>
            <Link to="/league/1.Bundesliga" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'league/1.Bundesliga') || (Helper.isLocation(location, 'league') && league && league.League.name_url === '1.Bundesliga') ? ' active' : '')}><div className="nav-club-icon league-icon league1"><img src="/img/leagues/1.svg" alt="" /><i /></div>1. Bundesliga</Link>
            <Link to="/league/LaLiga" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'league/LaLiga') || (Helper.isLocation(location, 'league') && league && league.League.name_url === 'LaLiga') ? ' active' : '')}><div className="nav-club-icon league-icon league10"><img src="/img/leagues/10.svg" alt="" /><i /></div>La Liga</Link>
            <Link to="/league/PremierLeague" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'league/PremierLeague') || (Helper.isLocation(location, 'league') && league && league.League.name_url === 'PremierLeague') ? ' active' : '')}><div className="nav-club-icon league-icon league3"><img src="/img/leagues/3.svg" alt="" /><i /></div>Premier League</Link>
            {/* <Link to="/league/ChampionsLeague" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'league/ChampionsLeague') || (Helper.isLocation(location, 'league') && league && league.League.name_url === 'ChampionsLeague') ? ' active' : '')}><div className="nav-club-icon league-icon league2"><img src="/img/leagues/4.svg" alt="" /><i /></div>Champions League</Link> */}
          </div>

          <div className={'subnav-items' + (activeSubNav === 'more' ? ' active' : '')}>
            <Link to="/about" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'about') ? ' active' : '')}><div className="nav-icon icon-info"></div>{txt.subnav.about}</Link>
            <Link to="/contact" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'contact') ? ' active' : '')}><div className="nav-icon icon-email"></div>{txt.subnav.contact}</Link>
            <Link to="/terms" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'terms') ? ' active' : '')}><div className="nav-icon icon-terms"></div>{txt.subnav.terms}</Link>
            <Link to="/privacy" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'privacy') ? ' active' : '')}><div className="nav-icon icon-privacy"></div>{txt.subnav.privacy}</Link>
            <Link to="/imprint" onClick={() => { closeNav(); closeSubNav(); }} className={'subnav-item' + (Helper.isLocation(location, 'imprint') ? ' active' : '')}><div className="nav-icon icon-imprint"></div>{txt.subnav.imprint}</Link>
          </div>

        </div>
      </IsolatedScroll>
    );
  }
}

export default SubNavComponent;
