import { connect } from 'react-redux';
import { setUser, setMatches, setMatchdayChips, addMessage, setNetworkError, openPlaceBet } from '../../actions';
import mapToComponent from './PlaceBetComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league,
  serverTime: state.serverTime,
  matches: state.matches,
  matchdayChips: state.matchdayChips,
  placeBet: state.placeBet
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  openPlaceBet: match_id => dispatch(openPlaceBet(match_id)),
  setMatches: matches => dispatch(setMatches(matches)),
  setMatchdayChips: (season_id, matchday, chips) => dispatch(setMatchdayChips(season_id, matchday, chips)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
