import { connect } from 'react-redux';
import { closeNav, closeSubNav } from '../../actions';
import mapToComponent from './NavOverlayComponent';

const mapStateToProps = state => ({
  navIsOpen: state.nav
});

const mapDispatchToProps = dispatch => ({
  closeNav: () => dispatch(closeNav()),
  closeSubNav: () => dispatch(closeSubNav())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
