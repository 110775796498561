import React from 'react';
import Config from '../../../config';
import API from '../../../util/API';
import Helper from '../../../util/Helper';
import ContentError from '../../ContentError/ContentErrorContainer';
import ContentLoading from '../../../util/ContentLoading';
import Match from '../../Match/MatchContainer';

class Page extends React.PureComponent {

  constructor(props)
  {
    super(props);

    this.state = {
      matchdays: [],
      matchesLoading: false,
      blockAutoLoading: false
    };
  }

  componentDidMount() {
    const { location, setLocation, setLoginLocation } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);
    this.fetchMatches();

    window.onscroll = window.onresize = () => {
      this.autoLoadMatches();
    };
  }

  componentWillUnmount() {
    window.onscroll = window.onresize = null;
  }

  fetchMatches(load_more = false, load_more_position = null) {
    if (this.state.matchesLoading) {
      return;
    }

    if (parseInt(load_more, 10) === 0 || (load_more && document.querySelector('.matches-matchday-wrapper' + load_more))) {
      return;
    }

    const { txt, auth, league, setLeague, setMatches, setServerTime, addMessage, setNetworkError, setContentLoading, setAppLoading } = this.props;

    API.fetch({
      url: Config.apiURL + '/getMatches',
      data: {
        load_more: load_more || null,
        load_more_position: load_more_position || null
      },
      txt,
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        !load_more && this.setState({matchesLoading: true});
        !this.state.matchdays.length && ContentLoading.startContentLoading(league ? setContentLoading : setAppLoading);
      },
      complete: () => {
        !load_more && this.setState({matchesLoading: false});
        !this.state.matchdays.length && ContentLoading.endContentLoading(league ? setContentLoading : setAppLoading);
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        response.league && setLeague(response.league);

        if (!response.matchdays || !response.matchdays.length) {
          return;
        }
        
        let matches = [];
        response.matchdays.forEach(matchday => {
          matchday.dates && matchday.dates.length && matchday.dates.forEach(date => {
            date.matches && date.matches.length && (matches = matches.concat(date.matches));
          });
        });
        setMatches(matches);

        if (load_more) {
          if (document.querySelector('.matches-matchday-wrapper' + load_more)) {
            this.autoLoadMatches();
            return;
          }

          this.setState({blockAutoLoading: true});
          setTimeout(() => {
            this.setState({blockAutoLoading: false});
            this.autoLoadMatches();
          }, 200);

          let stateMatchdays = this.state.matchdays;
          if (load_more_position === 'top') {
            stateMatchdays = response.matchdays.concat(stateMatchdays);
          } else {
            stateMatchdays = stateMatchdays.concat(response.matchdays);
          }

          this.setState({matchdays: stateMatchdays});

          if (load_more && load_more_position === 'top') {
            this.scrollToMatchday(load_more);
          }
        } else {
          this.setState({matchdays: this.state.matchdays.concat(response.matchdays)});
        }

        if (!load_more && response.matchdays.length >= 3) {
          this.scrollToMatchday(response.matchdays[1].matchday);
        }
        this.autoLoadMatches();
      }
    });
  }

  scrollToMatchday(matchday) {
    const matchday_wrapper = document.querySelector('.matches-matchday-wrapper' + matchday);

    if (!matchday_wrapper) {
      return;
    }
    window.scrollTo(0, Helper.offsetTop(matchday_wrapper) - 45 - 20);
  }

  autoLoadMatches() {
    if (!this.state.matchdays.length || this.state.blockAutoLoading) {
      return;
    }

    const loading_row_top = document.querySelector('.matches-loading-top');
    const loading_row_bottom = document.querySelector('.matches-loading-bottom');

    if (!loading_row_top && !loading_row_bottom) {
      return;
    }

    const window_top = window.pageYOffset || document.documentElement.scrollTop;
    const window_height = window.innerHeight || document.documentElement.clientHeight;
    const window_bottom = window_top + window_height;

    const loading_row_bottom_offset = Helper.offsetTop(loading_row_bottom);

    if (window_bottom > loading_row_bottom_offset) {
      this.fetchMatches(this.state.matchdays[this.state.matchdays.length - 1].matchday + 1, 'bottom');
      return;
    }

    const loading_row_top_offset = Helper.offsetTop(loading_row_top);

    if (window_top < loading_row_top_offset) {
      this.fetchMatches(this.state.matchdays[0].matchday - 1, 'top');
      return;
    }
  }

  render() {
    const { txt, league, matches, serverTime } = this.props;

    return (
      <div className="content-wrapper-align-top">
        {!league ? (
          <ContentError />
        ) : (
          <div>
            {this.state.matchdays.length ? (
              <div>
                {this.state.matchdays[0].matchday >= 2 ? (
                  <div className="matches-loading-wrapper">
                    <div className="content-container">
                      <h2 className="centered">{Helper.getMatchdayName(txt, this.state.matchdays[0].matchday - 1, league)}</h2>
                    </div>
                    <div className="matches-loading-container matches-loading-top spinner"></div>
                  </div>
                ) : null}
                {this.state.matchdays.map((matchday, index) => {
                  return (
                    <div className={'matches-matchday-wrapper matches-matchday-wrapper' + (matchday.matchday)} key={index}>
                      <div className="content-container">
                        <h2 className="centered">{Helper.getMatchdayName(txt, matchday.matchday, league)}</h2>
                      </div>
                      <div className="matches-matchday-container">
                        {matchday.dates.length ? (
                          matchday.dates.map((date, index2) => {
                            return (
                              <div className="matches-date-wrapper" key={index2}>
                                <div className="matches-date-title-container">
                                  <div className="matches-date-title">
                                    {Helper.matchTimeTitle(date.date, serverTime, txt)}
                                  </div>
                                </div>
                                {date.matches.length ? (
                                  <div className="matches-date-container">
                                    {date.matches.map((match, index3) => {
                                      return (
                                        <Match match={matches[match.id] || match} key={index3} showOnlyTime={true} />
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>
                            );
                          })
                        ) : null}
                      </div>
                    </div>
                  );
                })}
                {this.state.matchdays[this.state.matchdays.length - 1].matchday < league.Season.matchdays ? (
                  <div className="matches-loading-wrapper">
                    <div className="content-container">
                      <h2 className="centered">{Helper.getMatchdayName(txt, this.state.matchdays[this.state.matchdays.length - 1].matchday + 1, league)}</h2>
                    </div>
                    <div className="matches-loading-container matches-loading-bottom spinner"></div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Page;
