import React from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../util/Helper';

class FooterComponent extends React.PureComponent {

  render() {

    const { location, closeUserNav } = this.props;

    return (
      <div className="footer-wrapper">
        <div className="footer-container">
          <Link className={'footer-nav-link icon-home' + (Helper.isLocation(location, ['league', '/', 'index.html']) ? ' active' : '')} to="/" onClick={() => { closeUserNav(); }}></Link>
          <Link className={'footer-nav-link icon-highscores' + (Helper.isLocation(location, 'highscores') ? ' active' : '')} to="/highscores" onClick={() => { closeUserNav(); }}></Link>
          <Link className={'footer-nav-link icon-pitch' + (Helper.isLocation(location, 'matches') ? ' active' : '')} to="/matches" onClick={() => { closeUserNav(); }}></Link>
          <Link className={'footer-nav-link icon-list' + (Helper.isLocation(location, 'table') ? ' active' : '')} to="/table" onClick={() => { closeUserNav(); }}></Link>
          <Link className={'footer-nav-link icon-football'} to="/"></Link>
        </div>
      </div>
    );
  }
}

export default FooterComponent;