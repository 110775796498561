import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Header, Footer and Navigation
import Header from '../Header/HeaderContainer';
import Footer from '../Footer/FooterContainer';
import Nav from '../Nav/NavContainer';
import NavOverlay from '../Nav/NavOverlayContainer';
import SubNav from '../Nav/SubNavContainer';

// Pages
import User from '../Page/User/UserContainer';
import League from '../Page/League/LeagueContainer';
import Table from '../Page/Table/TableContainer';
import Match from '../Page/Match/MatchContainer';
import Group from '../Page/Group/GroupContainer';
import Matches from '../Page/Matches/MatchesContainer';
import Highscores from '../Page/Highscores/HighscoresContainer';
import Settings from '../Page/Settings/SettingsContainer';
import Account from '../Page/Account/AccountContainer';
import SignIn from '../Page/SignIn/SignInContainer';
import Activate from '../Page/Activate/ActivateContainer';
import Unsubscribe from '../Page/Unsubscribe/UnsubscribeContainer';
import SignUp from '../Page/SignUp/SignUpContainer';
import RecoverPassword from '../Page/RecoverPassword/RecoverPasswordContainer';
import NewPassword from '../Page/NewPassword/NewPasswordContainer';
import AvatarCreator from '../Page/AvatarCreator/AvatarCreatorContainer';
import About from '../Page/About/AboutContainer';
import Contact from '../Page/Contact/ContactContainer';
import Terms from '../Page/Terms/TermsContainer';
import Privacy from '../Page/Privacy/PrivacyContainer';
import Imprint from '../Page/Imprint/ImprintContainer';
import PageNotFound from '../Page/PageNotFound/PageNotFoundContainer';

// Other components
import Fetch from '../Fetch/FetchContainer';
import Messages from '../Messages/MessagesContainer';
import PlaceBet from '../PlaceBet/PlaceBetContainer';
import PlaceBetOverlay from '../PlaceBet/PlaceBetOverlayContainer';
import NetworkError from '../NetworkError/NetworkErrorContainer';

class AppComponent extends React.PureComponent {

  componentDidMount() {
    const { auth, getUser, league, getLeague } = this.props;
    !auth && getUser();
    !league && getLeague();
  }

  render() {
    const { navIsOpen, subNavIsOpen, userNavIsOpen, isNetworkError, appLoading, contentLoading } = this.props;

    let wrapperClassName = 'app-wrapper';
    navIsOpen && (wrapperClassName += ' nav-open');
    subNavIsOpen && (wrapperClassName += ' subnav-open');
    userNavIsOpen && (wrapperClassName += ' usernav-open');
    isNetworkError && (wrapperClassName += ' network-error');
    appLoading && (wrapperClassName += ' app-loading');
    appLoading === 'spinner' && (wrapperClassName += ' app-loading-spinner');
    contentLoading && (wrapperClassName += ' content-loading');
    contentLoading === 'spinner' && (wrapperClassName += ' content-loading-spinner');

    return (
        <Router>
          <div className={wrapperClassName}>
            <NetworkError />
            <Nav />
            <SubNav />
            <Header />
            <Footer />
            <div className="app-container">
              <NavOverlay />
              <div className="page-wrapper">
                <div className="page-loader" />
                <div className="page-container">
                  <Switch>
                    <Route exact path="/" component={League} />
                    <Route exact path="/index.html" component={League} />
                    <Route exact path="/league" component={League} />
                    <Route exact path="/league/:league_url" component={League} />
                    <Route exact path="/table" component={Table} />
                    <Route exact path="/highscores" component={Highscores} />
                    <Route exact path="/matches" component={Matches} />
                    <Route exact path="/match/:match_id" component={Match} />
                    <Route exact path="/user/:username" component={User} />
                    <Route exact path="/avatar-creator" component={AvatarCreator} />
                    <Route exact path="/group/:group_id" component={Group} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/contact" component={Contact} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/imprint" component={Imprint} />
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/account" component={Account} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/signin" component={SignIn} />
                    <Route exact path="/signin/:hash" component={SignIn} />
                    <Route exact path="/signin/:hash/:redirect" component={SignIn} />
                    <Route exact path="/unsubscribe/:hash/:type" component={Unsubscribe} />
                    <Route exact path="/activate/:hash" component={Activate} />
                    <Route exact path="/recover-password" component={RecoverPassword} />
                    <Route exact path="/new-password/:hash" component={NewPassword} />
                    <Route component={PageNotFound} />
                  </Switch>
                </div>
              </div>
            </div>
            <Messages />
            <PlaceBetOverlay />
            <PlaceBet />
            <Fetch />
          </div>
        </Router>
    );
  }
}

export default AppComponent;
