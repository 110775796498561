import React from 'react';
import { Link } from 'react-router-dom';

const ContentEN = () => (
  <div className="content-wrapper-text">
  <div className="content-text">
    <h1>Imprint</h1>
    <p>
      The websites under kickpros.com including the KICKPros Android and iPhone app and the services of these applications are being offered to you by:
    </p>

    <p>
      <b className="b500">Stephan Wagner</b><br />
      Philipp-Haering-Str. 8<br />
      86157 Augsburg<br />
      Germany<br />
      +49 176 45783473<br />
      <a href="mailto:stephanwagner.me@gmail.com">stephanwagner.me@gmail.com</a>
    </p>

    <h2>Signup</h2>

    <p>
      When you register a new account with KICKPros, you agree to our <Link to="/terms">Terms</Link> and that you have read our <Link to="/privacy">Privacy Policy</Link>.
    </p>

    <h2>Disclaimer</h2>

    <p>
      The websites of KICKPros are being created with the utmost care and are regularly updated. The update process on our sites (in particular game results, points and highscores) is mainly done by automated programs of own or external services, e.g. <a href="https://www.openligadb.de" target="_blank" rel="noopener noreferrer">openLigaDB</a> or <a href="https://www.sportmonks.com" target="_blank" rel="noopener noreferrer">SportMonks</a>.
    </p>
    <p>
      Despite regular and careful checks of the presented information, we cannot guarantee its accuracy or completeness. KICKPros therefore cannot be held responsible for damages (tangible or intangible type) which result from a direct or indirect usage of these websites and application.
    </p>

    <h2>National Leagues and Clubs</h2>

    <p>
      We would like to explicitly remark that KICKPros does not have any relation to any football national league nor to any of the teams of the aforementioned. The icons and names of the teams on KICKPros have been designed similarly to the official logos and team names to ensure the best possible usability for our visitors. We do however want to point out that the teams on our sites, albeit appearing similar to those of official national leagues, are to be regarded entirely fictional.
    </p>
    <p>
      If you think we violated copyright or trademark rights, please contact us under <a href="mailto:mail@kickpros.com">mail@kickpros.com</a>.
    </p>
  </div>
  </div>
);

export default ContentEN;
