const de = {
  i18n: {
    id: 'de',
    name: 'Deutsch',

    error: 'Hoppla! Da ist was schief gelaufen',
    networkError: 'Netzwerkfehler',
    pageErrorHeadline: 'Hoppla!',
    pageErrorSubline: 'Wir konnten diese Seite nicht laden',
    pageErrorTryAgain: 'Nochmal versuchen',
    contentErrorHeadline: 'Hoppla!',
    contentErrorSubline: 'Da ist was schief gelaufen',

    bet: 'Tipp!',
    bets: 'Tipps',
    user: 'Mitglied',
    users: 'Mitglieder',
    points: 'Punkte',
    pointsShort: 'P',
    place: 'Platz',
    jokerX2: '×2',
    jokerHt: 'Hz',
    matchdayNr: 'Spieltag {0}',
    comments: 'Kommentare',
    highscores: 'Highscores',
    postponed: 'Verlegt',
    PostponedShort: 'Verl.',

    aet: 'n.V.',
    pso: 'i.E.',

    matchdayWorldCup: {
      matchday1: 'Gruppenphase 1',
      matchday2: 'Gruppenphase 2',
      matchday3: 'Gruppenphase 3',
      matchday4: 'Achtelfinale',
      matchday5: 'Viertelfinale',
      matchday6: 'Halbfinale',
      matchday7: 'Platz 3',
      matchday8: 'Finale'
    },

    matchdayChampionsLeague: {
      matchday1: 'Gruppenphase 1',
      matchday2: 'Gruppenphase 2',
      matchday3: 'Gruppenphase 3',
      matchday4: 'Achtelfinale',
      matchday5: 'Viertelfinale',
      matchday6: 'Halbfinale',
      matchday7: 'Platz 3',
      matchday8: 'Finale'
    },

    date: {
      dayOfWeek: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      dayOfWeekAbr: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthName: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'July', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      monthNameAbr: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      justNow: 'gerade eben',
      minuteAgo: 'vor 1 Minute',
      minutesAgo: 'vor {0} Minuten',
      hourAgo: 'vor 1 Stunde',
      hoursAgo: 'vor {0} Stunden',
      secondsShort: 'sek',
      minutesShort: 'min',
      today: 'Heute',
      tomorrow: 'Morgen',
      yesterday: 'Gestern',
      Week: 'Woche',
      Weeks: 'Wochen',
      WeekShort: 'Wo.',
      WeeksShort: 'Wo.',
      Day: 'Tag',
      DayShort: 'Tag',
      Days: 'Tage',
      DaysCountdown: 'Tagen',
      DaysShort: 'Tg.',
      Hour: 'Stunde',
      Hours: 'Stunden',
      HourShort: 'Std',
      HoursShort: 'Std',
      Minute: 'Minute',
      Minutes: 'Minuten',
      MinuteShort: 'Min',
      MinutesShort: 'Min',
      Second: 'Sekunde',
      Seconds: 'Sekunden',
      SecondShort: 'Sek',
      SecondsShort: 'Sek'
    },

    nav: {
      league: 'Übersicht',
      leagues: 'Ligen',
      highscores: 'Highscores',
      matches: 'Spiele',
      table: 'Tabelle',
      more: 'Mehr',
      settings: 'Einstellungen',
      signIn: 'Anmelden',
      signUp: 'Registrieren',
      signOut: 'Abmelden',
      userScorePlayers: '{0} Spieler'
    },

    subnav: {
      about: 'Über KICKPros',
      contact: 'Kontakt',
      terms: 'Nutzungsbedingungen',
      privacy: 'Datenschutzerklärung',
      imprint: 'Impressum'
    },

    userNav: {
      HeaderMenuGroupsNoPrivateGroupsNotice: 'Du bist noch keiner privaten Gruppe beigetreten.',
      HeaderMenuGroupsNoFanGroupsNotice: 'Du bist noch keiner Fan-Gruppe beigetreten.',
      HeaderMenuNotificationsNoNotifications: 'Keine Benachrichtigungen vorhanden.',
      HeaderMenuNotificationsTitle: 'Benachrichtigungen',
      NotificationMatchAtHome: 'zu Hause',
      NotificationMatchAway: 'auswärts',
      NotificationMatchWon: '<b>{0}</b> <b class="user-menu-notification-text-{1}">gewinnt</b> {2} gegen <b>{3}</b> <span style="white-space: nowrap">mit <b>{4}</b></span>',
      NotificationMatchDraw: '<b>{0}</b> spielt {2} gegen <b>{3}</b> <span style="white-space: nowrap"><b>{4}</b></span> <b class="user-menu-notification-text-{1}">unentschieden</b>',
      NotificationMatchLost: '<b>{0}</b> <b class="user-menu-notification-text-{1}">verliert</b> {2} gegen <b>{3}</b> <span style="white-space: nowrap">mit <b>{4}</b></span>'
    },

    notices: {
      AccountEmailActivated: 'Deine E-Mail Adresse wurde bestätigt',
      AccountEmailUnsubscribed: 'Deine E-Mail Adresse wurde abgemeldet.',
      AccountEmailUnsubscribedError: 'Ups, dieser Link ist bereits abgelaufen…'
    },

    signIn: {
      buttonSignInWithFacebook: 'Mit Facebook anmelden',
      buttonSignInWithInstagram: 'Mit Instagram anmelden',
      textfieldNicknamePlaceholder: 'Dein Name',
      textfieldEmailPlaceholder: 'E-Mail Adresse',
      textfieldPasswordPlaceholder: 'Passwort',
      buttonSignIn: 'Anmelden',
      buttonSignUp: 'Registrieren',
      linkSignIn: 'Bereits registriert? Einloggen!',
      linkSignUp: 'Neuen Account erstellen',
      linkForgotPassword: 'Passwort vergessen?',
      terms: 'Durch Klicken auf Registrieren stimmst du unseren {0} zu und bestätigst, dass du unsere {1} gelesen hast.',
      termsPart0: 'Nutzungs&shy;bedingungen',
      termsPart1: 'Datenschutz&shy;erklärung',
      signInErrorNoEmailAndPassword: 'Bitte gib deine E-Mail Adresse <span style="white-space: nowrap">und dein Passwort ein</span>',
      LoginErrorEmail: 'Bitte deine E-Mail Adresse eingeben',
      LoginErrorEmailValidate: 'Ungültige E-Mail Adresse',
      LoginErrorEmailRegistered: 'E-Mail ist bereits registriert',
      LoginErrorPassword: 'Bitte dein Passwort eingeben',
      LoginErrorSignUpDefault: 'Bitte gib deinen Namen, deine E-Mail Adresse und ein Passwort ein',
      LoginErrorSignUpDefaultLinkAccounts: 'Bitte gib dein Passwort ein',
      LoginErrorPasswordMin: 'Das Passwort muss mindestens 6 Zeichen enthalten',
      LoginErrorPasswordMax: 'Das Passwort darf maximal 50 Zeichen enthalten',
      LoginErrorNickname: 'Bitte deinen Namen eingeben',
      LoginErrorNicknameMin: 'Dein Name muss mindestens 3 Zeichen enthalten',
      LoginErrorNicknameMax: 'Dein Name darf maximal 30 Zeichen enthalten',
      LoginErrorNicknameLetters: 'Dein Name darf nur Buchstaben und Zahlen enthalten',
      LoginErrorNicknameStartWithLetter: 'Dein Name muss mit einem Buchstaben beginnen',
      LoginErrorNicknameEndWithLetterOrNumber: 'Dein Name muss mit einem Buchstaben oder einer Zahl enden',
      LoginErrorUsername: 'Bitte gib deinen Nutzernamen ein',
      LoginErrorUsernameMin: 'Dein Nutzername muss mindestens 3 Zeichen enthalten',
      LoginErrorUsernameMax: 'Dein Nutzername darf maximal 30 Zeichen enthalten',
      LoginErrorUsernameLetters: 'Dein Nutzername darf nur Buchstaben und Zahlen enthalten',
      LoginErrorUsernameStartWithLetter: 'Dein Nutzername muss mit einem Buchstaben beginnen',
      LoginErrorUsernameEndWithLetterOrNumber: 'Dein Nutzername muss mit einem Buchstaben oder einer Zahl enden',
      LoginErrorUsernameRegistered: 'Dieser Nutzername ist bereits vergeben',
      LoginErrorEmailPassword: 'Ungültige E-Mail Adresse oder Passwort',
      LoginErrorFacebook: 'Hoppla! Anmelden mit Facebook hat fehlgeschlagen',
      LoginErrorInstagram: 'Hoppla! Anmelden mit Instagram hat fehlgeschlagen',
      LoginSuccessInstagram: 'Gleich geschafft! Bitte gib deine E-Mail Adresse ein und wähle ein Passwort:',
      LoginLinkInstagramAccountHeadline: 'Anmelden',
      LoginLinkInstagramAccountMessageLine1: 'Diese E-Mail Adresse<br />ist bereits bei KICKPros registriert.',
      LoginLinkInstagramAccountMessageLine2: 'Du kannst dich einloggen und dein Benutzerkonto mit Instagram verknüpfen:',
      ConnecWithInstagramDefaultError: 'Verknüpfung fehlgeschlagen.',
      ConnecWithInstagramWrongPassword: 'Ungültiges Passwort',
      ConnecWithInstagramCancelLink: 'Abbrechen'
    },

    recoverPassword: {
      headline: 'Passwort zurücksetzen',
      subline: 'Wir schicken dir einen Link zum Ändern deines Passwortes zu',
      textfieldEmailPlaceholder: 'E-Mail Adresse',
      submitButton: 'Abschicken',
      linkSignUp: 'Passwort bekannt? Anmelden!',
      ResetPasswordErrorEmptyEmail: 'Bitte gib deine E-Mail Adresse ein',
      ResetPasswordMessageSuccess: 'Wir haben dir den Link für ein neues Passwort zugeschickt',
      ResetPasswordErrorEmail: 'Diese E-Mail Adresse ist uns nicht bekannt'
    },

    newPassword: {
      headline: 'Neues Passwort vergeben',
      subline: 'Für ein sicheres Passwort, kombiniere Buchstaben mit Zahlen und Sonderzeichen',
      textfieldPasswordPlaceholder: 'Neues Passwort',
      textfieldPassword2Placeholder: 'Und nochmal eingeben...',
      submitButton: 'Speichern',
      NewPasswordMessageSuccess: 'Passwort gespeichert. Du musst dich ab jetzt mit deinem neuen Passwort einloggen.',
      errorLinkExpiredHeadline: 'Hoppla!',
      errorLinkExpiredSubline: 'Dieser Link ist abgelaufen. Auf der {0} kannst du dir einen neuen schicken.',
      errorLinkExpiredSublinePart0: 'Passwort zurücksetzen Seite',
      NewPasswordErrorPassword2: 'Die Passwörter stimmen nicht überein',
      LoginErrorPassword: 'Bitte dein neues Passwort eingeben',
      LoginErrorPasswordMin: 'Das Passwort muss mindestens 6 Zeichen enthalten',
      LoginErrorPasswordMax: 'Das Passwort darf maximal 50 Zeichen enthalten'
    },

    activateEmail: {
      ActivateEmailError: 'Ups, dieser Link ist bereits abgelaufen…',
      ActivateEmailError2: 'Bei deinen <a href="/account">Kontoeinstellungen</a> kannst du dir einen neuen Link schicken oder deine E-Mail Adresse ändern'
    },

    contact: {
      headline: 'Contact',
      sublineLine1: 'Questions, Suggestions or Feedback?',
      sublineLine2: 'Feel free to contact us at <a href="mailto:mail@kickpros.com">mail@kickpros.com</a>.',
      emailPlaceholder: 'Your email address',
      messagePlaceholder: 'Your message to KICKPros...',
      buttonSubmit: 'Send Message',
      errorNoMessage: 'Bitte gib deine Nachricht ein',
      successMessageSent: 'Vielen Dank, deine Nachricht wurde abgeschickt'
    },

    posts: {
      postsInputPlaceholder: 'Kommentar schreiben...',
      youDeletedThisPost: 'Dein Kommentar wurde gelöscht',
      youReportedThisPost: 'Du hast dieses Kommentar gemeldet',
      undo: 'Rückgängig',
      noPostsYet: 'Gib als erster ein Kommentar ab...',
      viaTwitter: 'via Twitter',
      postsError: 'Kommentare konnten nicht geladen werden...'
    },

    pages: {

      settings: {
        headline: 'Einstellungen',
        headlineLanguage: 'Sprache'
      },

      account: {
        headline: 'Konto Einstellungen',
        headlineEmailNotifications: 'E-Mail Benachrichtigungen',
        emailNotificationsReminderShort: 'Tipp-Erinnerung',
        emailNotificationsPromotionShort: 'Liga-Erinnerung',
        emailNotificationsSummaryShort: 'Punkte-Zusammenfassung',
        emailNotificationsReminder: 'Erinnerung an noch nicht abgegebene Tipps',
        emailNotificationsPromotion: 'Erinnerung wenn neue Wettbewerbe starten',
        emailNotificationsSummary: 'Zusammenfassung deiner Punkte nach einem Spieltag',
        headlineLanguage: 'Sprache',
        chooseLanguage: 'Sprache wählen',
        headlineNickname: 'Dein Name',
        headlineUsername: 'Nutzernamen',
        headlineEmail: 'E-Mail',
        headlinePassword: 'Passwort',
        placeholderYourPassword: 'Dein Passwort',
        placeholderNewEmailAddress: 'Neue E-Mail Adresse',
        placeholderPassword: 'Aktuelles Passwort',
        placeholderPasswordNew: 'Neues Passwort',
        cancel: 'Abbrechen',
        passwordChangedJustNow: 'Gerade eben geändert',
        passwordChangedToday: 'Heute zuletzt geändert',
        passwordChangedDate: 'Zuletzt geändert am {0}',
        passwordChangedNever: 'Noch nie geändert',
        AccountErrorCurrentPassword: 'Ungültiges aktuelles Passwort',
        confirmEmailAddress: 'Bitte bestätige deine E-Mail Adresse',
        emailChangeSuccess: 'Wir haben dir eine E-Mail geschickt um deine neue E-Mail Adresse zu bestätigen',
        deleteAccountLink: 'Mitgliedschaft beenden',
        deleteAccountHeadline1: 'Account löschen',
        deleteAccountHeadline2: 'Schade dass du uns verlässt!',
        deleteAccountText: 'Alle deine personenbezogenen Daten werden gelöscht. Gib dein Passwort ein und klicke dann auf »Account löschen«',
        deleteAccountBackButton: 'Abbrechen',
        deleteAccountSubmitButton: 'Account löschen',
        deleteAccountPasswordPlaceholder: 'Dein Passwort',
        DeleteAccountError: 'Falsches Passwort!'
      },

      PageNotFound: {
        headline: 'Hoppla!',
        subline: 'Diese Seite scheint es nicht zu geben'
      },

      standings: {
        headerClubName: 'Klub',
        headerPlayed: 'Sp',
        headerWon: 'S',
        headerDraw: 'U',
        headerLost: 'N',
        headerGoals: 'Tore',
        headerGoalsDiff: 'Td',
        headerPoints: 'Pkt',
        legendCl: 'Champions League',
        legendClQ: 'Champions League Qualifikation',
        legendEl: 'Europa League',
        legendElQ: 'Europa League Qualifikation',
        legendDwR: 'Relegation',
        legendDw: 'Abstieg',
        headerGroup: 'Gruppe {0}'
      },

      highscores: {
        headerHighscores: 'Highscores',
        headerMedalTally: 'Medaillenspiegel',
        headerMatchdays: 'Beste Spieltage',
        headerMatchday: 'Spieltag {0}',
        players: 'Spieler',
        noHighscores: 'Noch keine Highscores vorhanden...'
      },

      match: {
        kickoffIn: 'Anstoß in',
        matchFeedPenaltyShort: '11',
        matchFeedOwnGoalShort: 'ET',
        result: {
          home: '{0} gewinnt zu Hause gegen {1} mit {2} zu {3}',
          guest: '{1} gewinnt auswärts gegen {0} mit {3} zu {2}',
          draw: '{0} und {1} trennen sich {2} : {3} unentschieden',
        },
        incident: {
          Halftime: 'Halbzeit',
          RegularGoalNoPlayer: 'Tor',
          PenaltyNoPlayer: 'Elfmeter-Tor',
          MissedPenaltyNoPlayer: 'Elfmeter verschossen',
          OwnGoalNoPlayer: 'Eigentor',
          RegularGoal: 'Tor von {0}',
          Penalty: 'Elfmeter-Tor von {0}',
          MissedPenalty: 'Elfmeter verschossen von {0}',
          OwnGoal: 'Eigentor von {0}',
          YellowCardNoPlayer: 'Gelbe Karte',
          YellowCard2NoPlayer: 'Gelb-Rot',
          RedCardNoPlayer: 'Rote Karte',
          YellowCard: 'Gelbe Karte für {0}',
          YellowCard2: 'Gelb-Rot für {0}',
          RedCard: 'Rote Karte für {0}',
          SubstitutesNoPlayer: 'Auswechslung',
          Substitutes: '{0} für {1}'
        }
      },

      user: {
        userNotFoundHeadline: 'Hoppla!',
        userNotFoundSubline: 'Mitglied nicht gefunden...',
        userDeletedSubline: 'Dieses Mitglied wurde gelöscht...',
        badgesTitle: 'Abzeichen',
        leaguesTitle: 'Ligen',
        groupsTitle: 'Gruppen',
        privateGroupsTitle: 'Private Gruppen',
        publicGroupsTitle: 'Fan-Gruppen'
      },

      avatar: {
        itemsHeadlineSex: 'Identität',
        itemsHeadlineSkinColor: 'Hautfarbe',
        itemsHeadlineEyes: 'Augen',
        itemsHeadlineEyebrows: 'Augenbrauen',
        itemsHeadlineMouth: 'Mund',
        itemsHeadlineHair: 'Frisur',
        itemsHeadlineBeard: 'Bart',
        itemsHeadlineHairColor: 'Haarfarbe',
        itemsHeadlineShirt: 'Shirt',
        itemsHeadlineMakeup: 'Makeup',
        itemsHeadlineGlasses: 'Glasses',
        avatarShirtColorHeadlines: {
          AvatarCreatorTitleShirtColor: 'Shirt-Farbe',
          AvatarCreatorTitleSuitColor: 'Farbe Anzug',
          AvatarCreatorTitleSuitShirtColor: 'Farbe Hemd',
          AvatarCreatorTitleSuitTieColor: 'Farbe Krawatte'
        }
      }

    },

    placeBet: {
      errorSavingBet: 'Dein Tipp konnte nicht gespeichert werden',
      freeShields: '{0} FREE',
      halftimeBetTitle: 'Halbzeit Tipp',
      halftimeBetLead: '{0} führt',
      halftimeBetRemis: 'Unentschieden',
      yourBetTitle: 'Dein Tipp',
      scoreAfter90min: '90 Min',
      overtimeNotice: 'Verlängerung',
      penaltyShootOutNotice: 'Elfmeterschießen',
      overtimeNoticeFT: 'nach Verlängerung',
      penaltyShootOutNoticeFT: 'im Elfmeterschießen',
      tendenciesTitle: 'Tipp Tendenzen',
      toBeDecidedNotice: 'Für dieses Spiel stehen die Teams noch nicht fest',
      knockoutNotice: '<b>KO-Spiel:</b> Tippe auf das Ergebnis nach 90 Minuten Spielzeit.'
    }
  }
};

export default de;
