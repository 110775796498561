import React from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Config from '../../config';
import API from '../../util/API';
import { Link } from 'react-router-dom';
import NotificationsContainer from './NotificationsContainer';

class UserNavComponent extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      userNavReceiving: false
    };
  }

  getScrollableElement() {
    return document.querySelector('.user-menu-notifications-container');
  }

  disableBodyScroll() {
    const targetElement = this.getScrollableElement();
    targetElement && disableBodyScroll(targetElement);
  }

  componentDidMount() {
    const { txt, auth, serverTime, league, setUserScore, setUserGroups, setUserNotifications, addMessage } = this.props;

    this.disableBodyScroll();

    // TODO better caching option
    // TODO cancel request on unmount

    if (!this.state.userNavReceiving) {
      API.fetch({
        url: Config.apiURL + '/navItems',
        txt,
        auth,
        league,
        serverTime,
        beforeSend: () => {
          this.setState({userNavReceiving: true});
        },
        complete: () => {
          this.setState({userNavReceiving: false});
        },
        success: (response) => {
          if (response.success && response.data) {
            response.data.user_score && setUserScore(response.data.user_score || {});
            response.data.user_groups && setUserGroups(response.data.user_groups || {});
            response.data.user_notifications && setUserNotifications(response.data.user_notifications || {});
            this.disableBodyScroll();
          }
        },
        error: () => {
          addMessage(txt.error, 'error');
        },
      });
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  renderUserNavScore() {
    const { txt, userScore, league } = this.props;

    const userScoreSet = Object.keys(userScore).length !== 0;

    return (
      <div className={'user-menu-container user-menu-container-score' + (!userScoreSet ? ' user-menu-fetching' : '')}>
        <div className="user-menu-title">
          {league.League['name_' + txt.id]}
        </div>
        <div className="user-menu-score">
          <div className="user-score-place">
            {userScore.place || '-'}.{' '}
            <span className="user-score-place-txt">{txt.place}</span>
          </div>
          <div className="user-score-points">
            {!userScoreSet || parseInt(userScore.points, 10) === 0 ? '0' : userScore.points}{' '}
            <span className="user-score-points-txt">{txt.points}</span>
          </div>
          <div className="user-score-medals">
            <div className="user-menu-score-medal-text medal-color-gold">{userScore.medal_gold}</div><div className="user-menu-score-medal medal-gold"></div>
            <div className="user-menu-score-medal-text medal-color-silver">{userScore.medal_silver}</div><div className="user-menu-score-medal medal-silver"></div>
            <div className="user-menu-score-medal-text medal-color-bronze">{userScore.medal_bronze}</div><div className="user-menu-score-medal medal-bronze"></div>
          </div>
        </div>
        <div className="user-menu-title">
          {userScoreSet ? txt.nav.userScorePlayers.replace('{0}', userScore.season_players) : ''}
        </div>
      </div>
    );
  }

  renderUserNavGroups() {

    const { txt, closeUserNav, userGroups } = this.props;

    const userGroupsSet = Object.keys(userGroups).length !== 0;

    return (
      <div className="user-menu-container user-menu-container-groups">
        <div className="user-menu-title">
          {txt.pages.user.privateGroupsTitle}
        </div>
        {userGroups.rivalGroup && userGroups.rivalGroup.length ? (
          <div className="user-menu-groups-container">
            {userGroups.rivalGroup.map((userGroup, index) => {
              console.log(userGroup);

              // TODO RIVAL GROUP????

              return (
                <Link to={'/group/' + userGroup.hashId} className="menu-link menu-link-club-icon" key={index} onClick={() => { closeUserNav(); }}>
                  <div className="group-icon group-icon-private icon-groups group-icon-color1"></div>
                  {userGroup.name}
                  <div className="user-menu-group-place">{userGroup.place}. <span className="points-text">{txt.place}</span></div>
                </Link>
              );
            })}
          </div>
        ) : null}
        {userGroups.privateGroups && userGroups.privateGroups.length ? (
          <div className="user-menu-groups-container">
            {userGroups.privateGroups.map((userGroup, index) => {
              return (
                <Link to={'/group/' + userGroup.hashId} className="menu-link menu-link-club-icon" key={index} onClick={() => { closeUserNav(); }}>
                  <div className="group-icon group-icon-private icon-groups group-icon-color1"></div>
                  {userGroup.name}
                  <div className="user-menu-group-place">{userGroup.place}. <span className="points-text">{txt.place}</span></div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div className="user-menu-error-message">
            {!userGroupsSet ? null : (
              <div>
                {txt.userNav.HeaderMenuGroupsNoPrivateGroupsNotice}
              </div>
            )}
          </div>
        )}
        <div className="user-menu-title">
          {txt.pages.user.publicGroupsTitle}
        </div>
        {userGroups.publicGroups && userGroups.publicGroups.length ? (
          <div className="user-menu-groups-container">
            {userGroups.publicGroups.map((userGroup, index) => {
              return (
                <Link to={'/group/' + userGroup.hashId} className="menu-link menu-link-club-icon" key={index} onClick={() => { closeUserNav(); }}>
                  <div className={'club-icon club' + userGroup.club_id}><img src={'/img/clubs/' + userGroup.club_id + '.svg'} alt=""/><i/></div>
                  {userGroup['name_' + txt.id]}
                  <div className="user-menu-group-place">{userGroup.place}. <span className="points-text">{txt.place}</span></div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div className="user-menu-error-message">
            {!userGroupsSet ? null : (
              <div>
                {txt.userNav.HeaderMenuGroupsNoFanGroupsNotice}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { userNav } = this.props;

    return (
      <div className={'user-menu-wrapper' + (userNav ? ' active' : '')} data-active-user-menu={userNav}>
        <div className="user-menu-pointer"></div>
        {this.renderUserNavScore()}
        {this.renderUserNavGroups()}
        <NotificationsContainer/>
      </div>
    );
  }
}

export default UserNavComponent;
