import React from 'react';
import { Link } from 'react-router-dom';

const ContentEn = () => (
  <div className="content-wrapper-text">
  <div className="content-text">
    <h1>Statement of Rights and Responsibilities</h1>

    <div style={{fontSize: '12px', color: '#aaa', marginTop: '-5px', marginBottom: '25px'}}>Last updated on 25. May 2018</div>

    <p>
      The following statement of rights and responsibilities serve as our terms and conditions.
      By using KICKPros or accessing it you are agreeing to this declaration or the respective updated version shown in the section “Changes”.
    </p>
    <ol>
      <li>
        <b>Privacy</b>
        <p>
          Your privacy is important to us. In our <Link to="/privacy">Privacy Policy</Link> we have detailed important information on how we collect and use your information.
          We encourage you to read our data use policy.
        </p>
      </li>
      <li>
        <b>Security</b>
        <p>
          Although we are doing our best to ensure security on KICKPros, we cannot guarantee it.
          In this regards, please also be aware of your responsibilities in this matter:
        </p>
        <ul>
          <li>You will not post unauthorized commercial advertisement (i.e. Spam) on KICKPros.</li>
          <li>You will not obtain login details or access an account that belongs to a different individual.</li>
          <li>You will refrain from terrorising, intimidating or bullying of any of the other users.</li>
          <li>You will not post hate speeches, threatening or pornographic content; or content that encourages violence.</li>
          <li>You will not use KICKPros to engage in unlawful, misleading, malicious or discriminatory activities.</li>
          <li>You will refrain from activities that will block, overload or impair the optimal functionality or rather the image of KICKPros, e.g. denial-of-service-attacks, or which could disturb the offer or any other functionality of KICKPros.</li>
          <li>You will not support nor encourage any breaches to this declaration or our guidelines.</li>
        </ul>
      </li>
      <li>
        <b>Registration and security of accounts</b>
        <p>
          The following details some of the responsibilities that you enter with us in regards to the registration and preservation of the security of your account:
        </p>
        <ul>
          <li>You will only register one personal account.</li>
          <li>You will not set up a profile for someone else without their permission.</li>
          <li>Should we block your account, you will not open a new one without our explicit permission.</li>
          <li>You will not use KICKPros if you are less than 13 years old and do not have the permission of your parents.</li>
          <li>Your contact details are correct and you will keep them up to date.</li>
          <li>You will not transfer your account without our prior permission.</li>
        </ul>
      </li>
      <li>
        <b>Rights of other users</b>
        <p>
          We respect the rights of others, and expect you to do the same.
        </p>
        <ul>
          <li>You will not post content or engage in activities on KICKPros that endanger other people’s rights or might infringe the law.</li>
          <li>We reserve the right to remove all content and information that you have posted on KICKPros should we feel that they contravene this declaration or guidelines.</li>
        </ul>
      </li>
      <li>
        <b>Changes</b>
        <p>
          We can change this declaration at any time, but will inform you about it on this site a minimum of 2 weeks in advance.
        </p>
        </li>
        <li>
        <b>Termination</b>
        <p>
          If you are found to be in breach of the content or the spirit of this declaration or if you are creating potential other legal risk for us,
          we reserve the right to partially or fully block the access to KICKPros for you.
          We will inform you via email or the next time you are accessing your account.
          You are also able to delete or deactivate your account at any time. In these cases this declaration will be terminated.
        </p>
        <p>
          If an account or content is deleted, it is removed similarly to emptying the bin on your computer.
          You should however be aware that the removed content will remain for an appropriate time frame in security copies (which are however not accessible by others).
        </p>
      </li>
    </ol>
  </div>
  </div>
);

const ContentDe = () => (
  <div className="content-wrapper-text">
  <div className="content-text">
    <h1>Erklärung der Rechte und Pflichten</h1>

    <div style={{fontSize: '12px', color: '#aaa', marginTop: '-5px', marginBottom: '25px'}}>Zuletzt aktualisiert am 25. Mai 2018</div>

    <p>
      Die vorliegende Erklärung der Rechte und Pflichten bilden unsere Nutzungsbedingungen.
      Mit deiner Nutzung von KICKPros oder dem Zugriff darauf stimmst du dieser Erklärung bzw. ihrer jeweils gültigen Aktualisierung gemäß Abschnitt “Änderungen” zu.
    </p>
    <ol>
      <li>
        <b>Privatsphäre</b>
        <p>
          Deine Privatsphäre ist uns sehr wichtig. In unseren <Link to="/privacy" dangerouslySetInnerHTML={{__html: 'Datenschutz&shy;erklärung'}} /> machen wir wichtige Angaben dazu, wie wir deine Informationen sammeln und verwenden können. Wir bitten dich, die Datenverwendungsrichtlinien zu lesen.
        </p>
      </li>
      <li>
        <b>Sicherheit</b>
        <p>
          Wir bemühen uns nach besten Kräften, die Sicherheit auf KICKPros zu wahren, können diese jedoch nicht garantieren. Hierzu gehören auch deine folgenden Verpflichtungen:
        </p>
        <ul>
          <li>Du wirst keine nicht genehmigten Werbekommunikationen (beispielsweise Spam) auf KICKPros posten.</li>
          <li>Du wirst keine Anmeldeinformationen einholen oder auf ein Konto zugreifen, das einer anderen Person gehört.</li>
          <li>Du wirst andere Nutzer weder tyrannisieren noch einschüchtern oder schikanieren.</li>
          <li>Du wirst keine Inhalte posten, die: Hassreden enthalten, bedrohlich oder pornografisch sind oder zu Gewalt auffordern.</li>
          <li>Du wirst KICKPros nicht verwenden, um rechtswidrige, irreführende, bösartige oder diskriminierende Handlungen durchzuführen.</li>
          <li>Du wirst keine Handlungen durchführen, welche das einwandfreie Funktionieren bzw. Erscheinungsbild von KICKPros blockieren, überbelasten oder beeinträchtigen könnten, wie etwa Denial-of-Service-Attacken, oder ein Seitenangebot bzw. eine sonstige Funktionalität von KICKPros stören könnten.</li>
          <li>Du wirst jegliche Verstöße gegen diese Erklärung bzw. unsere Richtlinien weder unterstützen noch fördern.</li>
        </ul>
      </li>
      <li>
        <b>Registrierung und Sicherheit der Konten</b>
        <p>
          Im Folgenden werden einige Verpflichtungen aufgeführt, die du bezüglich der Registrierung und der Wahrung der Sicherheit deines Kontos uns gegenüber eingehst:
        </p>
        <ul>
          <li>Du wirst nur ein einziges persönliches Konto anlegen.</li>
          <li>Du wirst nicht ohne Erlaubnis ein Profil für jemand anderen erstellen.</li>
          <li>Wenn wir dein Konto sperren, wirst du ohne unsere Erlaubnis kein anderes erstellen.</li>
          <li>Du wirst KICKPros nicht verwenden, wenn du unter 13 Jahre alt bist und nicht die Erlaubnis deiner Eltern dafür hast.</li>
          <li>Deine Kontaktinformationen sind korrekt und du wirst sie auf dem neuesten Stand halten.</li>
          <li>Du wirst dein Konto an niemanden übertragen, ohne vorher unsere Erlaubnis einzuholen.</li>
        </ul>
      </li>
      <li>
        <b>Schutz der Rechte anderer Personen</b>
        <p>
          Wir respektieren die Rechte anderer und erwarten von dir, dass du dies auch tust.
        </p>
        <ul>
          <li>Du wirst keine Inhalte auf KICKPros posten oder Handlungen auf KICKPros durchführen, welche die Rechte einer anderen Person oder das Gesetz verletzen.</li>
          <li>Wir können sämtliche Inhalte und Informationen, die du auf KICKPros gepostet hast, entfernen, wenn wir der Ansicht sind, dass diese gegen diese Erklärung bzw. unsere Richtlinien verstoßen.</li>
        </ul>
      </li>
      <li>
        <b>Änderungen</b>
        <p>
          Wir können diese Erklärung jederzeit ändern, werden dich aber mindestens 2 Wochen zuvor auf dieser Seite darüber informieren.
        </p>
        </li>
        <li>
        <b>Beendigung</b>
        <p>
          Wenn du gegen den Inhalt oder den Geist dieser Erklärung verstößt oder anderweitig mögliche rechtliche Risiken für uns erzeugst, können wir die Bereitstellung von KICKPros für dich ganz oder teilweise einstellen.
          Wir werden dich per E-Mail oder wenn du dich das nächste Mal für dein Konto anmeldest darüber informieren.
          Du kannst außerdem jederzeit dein Konto löschen oder deine Anwendung deaktivieren.
          In all diesen Fällen wird diese Erklärung beendet.
        </p>
        <p>
          Wenn ein Konto oder Inhalte gelöscht werden, werden sie auf eine Weise entfernt, die dem Leeren des Papierkorbs auf einem Computer gleichkommt.
          Allerdings sollte dir bewusst sein, dass entfernte Inhalte für eine angemessene Zeitspanne in Sicherheitskopien fortbestehen (die für andere jedoch nicht zugänglich sind).
        </p>
      </li>
    </ol>
  </div>
  </div>
);

class Page extends React.PureComponent {

  componentDidMount() {
    const { location } = this.props;

    window.scrollTo(0, 0);
    this.props.setLocation(location);
  }

  render() {
    const { txt } = this.props;

    switch (txt.id) {
      case 'de':
        return <ContentDe />;
      default:
        return <ContentEn />;
    }
  }
}

export default Page;
