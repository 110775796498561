const subNav = (state = null, action) => {
  switch (action.type) {
    case 'OPEN_SUBNAV':
      return action.id;
    case 'CLOSE_SUBNAV':
      return null;
    case 'TOGGLE_SUBNAV':
      return state === action.id ? null : action.id;
    default:
      return state;
  }
};

export default subNav;