import { connect } from 'react-redux';
import { setLanguage, setLocation, setLoginLocation, setServerTime, addMessage, setNetworkError, getUser, setUser } from '../../../actions';
import mapToComponent from './AccountComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league,
  serverTime: state.serverTime
});

const mapDispatchToProps = dispatch => ({
  setLanguage: language => dispatch(setLanguage(language)),
  setLocation: location => dispatch(setLocation(location)),
  setLoginLocation: location => dispatch(setLoginLocation(location)),
  setServerTime: serverTime => dispatch(setServerTime(serverTime)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  getUser: () => dispatch(getUser()),
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
