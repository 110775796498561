const userScore = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER_SCORE':
      return action.score || {
        place: 0,
        points: 0,
        medal_bronze: 0,
        medal_gold: 0,
        medal_silver: 0,
        season_players: 0
      };
    default:
      return state;
  }
};

export default userScore;