import { connect } from 'react-redux';
import { closeSubNav, closeNav } from '../../actions';
import mapToComponent from './SubNavComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  league: state.league,
  location: state.location,
  activeSubNav: state.subNav
});

const mapDispatchToProps = dispatch => ({
  closeNav: () => dispatch(closeNav()),
  closeSubNav: () => dispatch(closeSubNav())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);