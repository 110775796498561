const userGroups = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER_GROUPS':
      return action.groups || {};
    default:
      return state;
  }
};

export default userGroups;
