import React from 'react';
import ContentDE from './ImprintContentDE';
import ContentEN from './ImprintContentEN';

class Page extends React.PureComponent {
  
  componentDidMount() {
    const { location, setLocation, setLoginLocation } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(null);
  }

  render() {
    const { txt } = this.props;

    switch (txt.id) {
      case 'de':
        return <ContentDE />;
      default:
        return <ContentEN />;
    }
  }
}

export default Page;
