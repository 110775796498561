import React from 'react';
import IsolatedScroll from 'react-isolated-scroll';
import { Link } from 'react-router-dom';
import Helper from '../../util/Helper';

class NavComponent extends React.PureComponent
{
  render() {
    const { txt, auth, setUser, league, location, closeNav, subNavId, toggleSubNav, closeSubNav, closeUserNav } = this.props;

    return (
      <IsolatedScroll className="nav-wrapper">

        <div className="nav-overlay"></div>

        {auth && (
          <Link to={'/user/' + auth.username} onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-user nav-item' + (Helper.isLocation(location, 'user') ? ' active' : '')}>
            <img src={Helper.avatarImage(auth)} alt="" />{auth.nickname}
          </Link>
        )}

        <Link to="/league" onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-item' + (Helper.isLocation(location, ['league', '/', 'index.html']) ? ' active' : '')}>
          <div className="nav-icon icon-home"></div>{txt.nav.league}
        </Link>

        <Link to="/highscores" onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-item' + (Helper.isLocation(location, 'highscores') ? ' active' : '')}>
          <div className="nav-icon icon-highscores"></div>{txt.nav.highscores}
        </Link>

        <Link to="/matches"  onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-item' + (Helper.isLocation(location, 'matches') ? ' active' : '')}>
          <div className="nav-icon icon-pitch"></div>{txt.nav.matches}
        </Link>

        {league && league.League.type === 'league' ? (
          <Link to="/table"  onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-item' + (Helper.isLocation(location, 'table') ? ' active' : '')}>
            <div className="nav-icon icon-list"></div>{txt.nav.table}
          </Link>
        ) : null}

        <div className={'nav-item has-subnav' + (subNavId === 'leagues' ? ' subnav-active active' : '')} onClick={() => { closeUserNav(); toggleSubNav('leagues'); }}>
          <div className="nav-icon icon-football"></div>{league ? league.League['name_' + txt.id] : txt.nav.leagues}<span className="icon icon-right"></span>
        </div>

        <div className={'nav-item has-subnav' + (subNavId === 'more' ? ' subnav-active active' : '') + (Helper.isLocation(location, ['about', 'contact', 'privacy', 'terms', 'imprint']) ? ' active' : '')} onClick={() => { closeUserNav(); toggleSubNav('more'); }}>
          <div className="nav-icon icon-dots"></div>{txt.nav.more}<span className="icon-right"></span>
        </div>

        <div className="nav-separator" />

        {auth ? (
          <Link to="/account" onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-item' + (Helper.isLocation(location, 'account') ? ' active' : '')}>
            <div className="nav-icon icon-options"></div>{txt.nav.settings}
          </Link>
        ) : (
          <Link to="/settings" onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-item' + (Helper.isLocation(location, 'settings') ? ' active' : '')}>
            <div className="nav-icon icon-options"></div>{txt.nav.settings}
          </Link>
        )}

        {auth ? (
          <div onClick={() => { setUser(null); closeNav(); closeSubNav(); closeUserNav(); window.location.href = "/"; }} className={'nav-item' + (Helper.isLocation(location, 'signout') ? ' active' : '')}>
            <div className="nav-icon icon-off"></div>{txt.nav.signOut}
          </div>
        ) : (
          <Link to="/signin" onClick={() => { closeNav(); closeSubNav(); closeUserNav(); }} className={'nav-item' + (Helper.isLocation(location, 'signin') ? ' active' : '')}>
            <div className="nav-icon icon-signin"></div>{txt.nav.signIn}
          </Link>
        )}

      </IsolatedScroll>
    );
  }
}

export default NavComponent;
