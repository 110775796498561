import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import ContentError from '../../ContentError/ContentErrorContainer';
import API from '../../../util/API';
import Helper from '../../../util/Helper';
import Config from '../../../config';
import ContentLoading from '../../../util/ContentLoading';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      contentError: false,
      keyIsValid: false,
      password: '',
      password2: '',
      error: '',
      errorClassName: '',
      errorAnimation: false,
      success: '',
      inputErrorPassword: false,
      inputErrorPassword2: false
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePassword2Change = this.handlePassword2Change.bind(this);
  }

  componentDidMount() {
    const { txt, setLocation, location, setContentLoading, match } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);

    API.fetch({
      url: Config.apiURL + '/newPasswordCheck',
      data: {
        hash: match && match.params ? match.params.hash : ''
      },
      txt,
      beforeSend: () => {
        ContentLoading.startContentLoading(setContentLoading);
      },
      complete: () => {
        ContentLoading.endContentLoading(setContentLoading);
      },
      error: () => {
        this.setState({contentError: true});
      },
      success: (response) => {
        this.setState({contentError: false, keyIsValid: !!response.success});
      }
    });
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value, inputErrorPassword: false});
  }

  handlePassword2Change(event) {
    this.setState({password2: event.target.value, inputErrorPassword2: false});
  }

  showError(error_code) {
    const { txt } = this.props;

    this.setState({
      errorAnimation: true,
      errorClassName: this.state.error ? 'shake' : 'popin',
      error: txt.newPassword[error_code] || txt.error,
      inputError: true
    });
    setTimeout(() => { this.setState({errorAnimation: false}); }, 400);
  }

  setNewPassword() {
    const { txt, addMessage } = this.props;

    if (!this.state.password) {
      this.setState({inputErrorPassword: true});
      this.showError('LoginErrorPassword');
      return;
    }

    API.fetch({
      url: Config.apiURL + '/newPassword',
      data: {
        hash: this.props.match.params.hash,
        password: this.state.password,
        password2: this.state.password2
      },
      txt,
      beforeSend: () => {
        this.setState({requesting: true});
      },
      complete: () => {
        this.setState({requesting: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      success: (response) => {
        if (response.success) {
          this.setState({
            error: '',
            success: txt.newPassword.NewPasswordMessageSuccess
          });
        } else {
          this.showError(response.error);
          response.error_input === 'password' && this.setState({inputErrorPassword: true});
          response.error_input === 'password2' && this.setState({inputErrorPassword2: true});
        }
      }
    });
  }

  render() {
    const { txt } = this.props;

    return (
      <div className="content-wrapper">
        {this.state.contentError ? (
          <ContentError />
        ) : (
          !this.state.keyIsValid ? (
            <div className="content-error-container content-error-container--light">
              <h1>{txt.newPassword.errorLinkExpiredHeadline}</h1>
              <h3>{Helper.replace(txt.newPassword.errorLinkExpiredSubline, <Link to="/recover-password">{txt.newPassword.errorLinkExpiredSublinePart0}</Link>)}</h3>
            </div>
          ) : (
            <div className="signin-wrapper">
              <div className="content-container">

                <h1>{txt.newPassword.headline}</h1>
                <h3 className={this.state.success ? 'success' : ''}>{this.state.success || txt.newPassword.subline}</h3>

                <CSSTransition
                  in={this.state.errorAnimation}
                  timeout={{
                    enter: 300,
                    exit: 0
                  }}
                  classNames={this.state.errorClassName}
                >
                  <div className={'signin-message error' + (this.state.error ? ' active' : '')}>{this.state.error}</div>
                </CSSTransition>

                <div className="signin-textfields">
                  <input type="password" onChange={this.handlePasswordChange} disabled={this.state.success} value={this.state.password} className={'textfield signin-textfield' + (this.state.inputErrorPassword ? ' input-error' : '')} placeholder={txt.newPassword.textfieldPasswordPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
                  <input type="password" onChange={this.handlePassword2Change} disabled={this.state.success} value={this.state.password2} className={'textfield signin-textfield' + (this.state.inputErrorPassword2 ? ' input-error' : '')} placeholder={txt.newPassword.textfieldPassword2Placeholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
                </div>

                <button type="button" onClick={() => { this.setNewPassword(); }} disabled={this.state.requesting || this.state.success} className={'button new-password-button' + (this.state.requesting ? ' loading-bar' : '')}>{txt.newPassword.submitButton}</button>

                <div className="margin30" />

                <div className="signin-links">
                  <Link to="/signin">{txt.recoverPassword.linkSignUp}</Link>
                </div>

              </div>
            </div>
          )
        )}
      </div>
    );
  }
}

export default Page;
