import React from 'react';

class ContentErrorComponent extends React.PureComponent {

  render() {
    const { txt } = this.props;

    return (
      <div className="content-error-container">
        <h1>{txt.contentErrorHeadline}</h1>
        <h3>{txt.contentErrorSubline}</h3>
        <div className="content-error-reload" onClick={() => { window.location.reload(); }}>
          <div className="content-error-reload-icon icon-reload" />
        </div>
      </div>
    );
  }
}

export default ContentErrorComponent;
