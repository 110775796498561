import React from 'react';
import Config from '../../../config';
import API from '../../../util/API';
import Helper from '../../../util/Helper';
import ContentError from '../../ContentError/ContentErrorContainer';
import ContentLoading from '../../../util/ContentLoading';
import BetBox from '../../BetBox/BetBoxContainer';
import News from '../../News/NewsComponent';
import Posts from '../../Posts/PostsContainer';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      match_id: null,
      news: null,
      contentError: false
    };
  }

  componentDidMount() {
    const { location, setLocation, setLoginLocation, match } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);

    this.fetchMatch(match.params.match_id);
  }

  fetchMatch(match_id) {
    const { txt, auth, league, setLeague, setMatches, setServerTime, setNetworkError, setContentLoading, setAppLoading } = this.props;

    API.fetch({
      url: Config.apiURL + '/getMatch/' + match_id,
      txt,
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        ContentLoading.startContentLoading(league ? setContentLoading : setAppLoading);
      },
      complete: () => {
        ContentLoading.endContentLoading(league ? setContentLoading : setAppLoading);
      },
      error: () => {
        this.setState({contentError: true});
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        response.league && setLeague(response.league);

        if (response.success) {
          setMatches([response.match]);
          this.setState({
            match_id,
            news: response.news || null,
            contentError: false
          });
        } else {

          // TODO show match not found error

        }
      }
    });
  }

  FeedIcon(index, feed, match) {
    const homeOrGuest = (feed.club_id === match.home_id) ? 'home' : 'guest';
    const elapsed = parseInt(feed.elapsed, 10);
    const position = elapsed / 90 * 100;

    return (
      <div className={this.FeedIconClassName(feed, 'match-feed-icon-' + homeOrGuest)} style={{left: position + '%'}} key={index}>
        {this.FeedIconInnerHTML(feed)}
      </div>
    );
  }

  FeedIconClassName(feed, addClass) {
    let classNameContainer = 'match-feed-icon' + (addClass ? ' ' + addClass : '');

    switch (feed.type) {
      case 'RegularGoal':
      case 'Penalty':
      case 'OwnGoal':
      case 'MissedPenalty':
        classNameContainer += ' match-feed-icon-football football-icon';
        break;
      case 'YellowCard':
      case 'YellowCard2':
      case 'RedCard':
        classNameContainer += ' match-feed-icon-card match-feed-icon-card-';
        if (feed.type === 'RedCard') {
          classNameContainer += 'red';
        } else if (feed.type === 'YellowCard2') {
          classNameContainer += 'yellow2';
        } else {
          classNameContainer += 'yellow';
        }
        break;
      case 'Substitutes':
        classNameContainer += ' match-feed-icon-substitutes substitutes-icon';
        break;
      default:
        break;
    }

    return classNameContainer;
  }

  FeedIconInnerHTML(feed) {
    const { txt } = this.props;

    switch (feed.type) {
      case 'RegularGoal':
        return (
          <img src="/img/football.svg" alt=""/>
        );
      case 'MissedPenalty':
        return (
          <div>
            <div className="match-feed-icon-football-penalty-missed icon-x"></div>
            <div className="match-feed-icon-football-penalty">{txt.pages.match.matchFeedPenaltyShort}</div>
          </div>
        );
      case 'Penalty':
        return (
          <div>
            <img src="/img/football.svg" alt=""/>
            <div className="match-feed-icon-football-penalty">{txt.pages.match.matchFeedPenaltyShort}</div>
          </div>
        );
      case 'OwnGoal':
        return (
          <div>
            <img src="/img/football.svg" alt=""/>
            <div className="match-feed-icon-football-own-goal">{txt.pages.match.matchFeedOwnGoalShort}</div>
          </div>
        );
      default:
        return '';
    }
  }

  FeedRow(index, feed, match) {
    return (
      <div className={'match-feed-incident match-feed-incident-' + (feed.type === 'Halftime' ? 'only-text' : (feed.club_id === match.home_id ? 'home' : 'guest'))} key={index}>
        {feed.type !== 'Halftime' ? (
          <div className="match-feed-incident-elapsed">{parseInt(feed.elapsed, 10) + '\''}{feed.elapsed_extra ? (<span className="match-feed-incident-elapsed-extra">+{feed.elapsed_extra}</span>) : null}</div>
        ) : null}
        {feed.type !== 'Halftime' ? this.FeedRowIcon(feed) : null}
        {this.FeedRowText(feed)}
      </div>
    );
  }

  FeedRowIcon(feed) {
    return (
      <div className="match-feed-incident-icon">
        <div className={this.FeedIconClassName(feed)}>
          {this.FeedIconInnerHTML(feed)}
        </div>
      </div>
    );
  }

  FeedRowText(feed) {
    const { txt } = this.props;

    let incidentText = '';

    switch (feed.type) {
      case 'RegularGoal':
      case 'Penalty':
      case 'OwnGoal':
      case 'MissedPenalty':
      case 'YellowCard':
      case 'YellowCard2':
      case 'RedCard':
        incidentText = !feed.player ? txt.pages.match.incident[feed.type + 'NoPlayer'] :
          txt.pages.match.incident[feed.type]
            .replace('{0}', '<span class="match-feed-incident-player-name">' + feed.player + '</span>');
        break;
      case 'Substitutes':
        if (!feed.player) {
          incidentText = txt.pages.match.incident[feed.type + 'NoPlayer'];
        } else {
          try {
            let incidentPlayers = JSON.parse(feed.player);
            Object.keys(incidentPlayers).forEach(type => {
              incidentPlayers[type].forEach((playerName, key) => {
                incidentPlayers[type][key] = Helper.lastName(playerName);
              });
            });
            incidentText = txt.pages.match.incident[feed.type]
              .replace('{0}', '<span class="match-feed-incident-player-name">' + incidentPlayers.in.join(', ') + '</span>')
              .replace('{1}', '<span class="match-feed-incident-player-name">' + incidentPlayers.out.join(', ') + '</span>');
          } catch (e) {}
        }
        break;
      case 'Halftime':
        incidentText = txt.pages.match.incident[feed.type];
        break;
      default:
        break;
    }

    return (
      <div className="match-feed-incident-text" dangerouslySetInnerHTML={{__html: incidentText}}></div>
    );
  }

  getElapsed(match, serverTime) {
    let elapsed = 0;

    if (!Helper.matchIsLive(match, serverTime)) {
      return elapsed;
    }

    if (match.elapsed > 0) {
      return match.elapsed;
    }

    const now = (new Date()).getTime();
    const matchTime = Helper.getDate(match.match_time).getTime();

    // If time started value from livescoreshop is missing, add it after 4 minute delay
    if (!match.match_time_started && now > matchTime + (4 * 60)) {
      match.match_time_started = match.match_time;
    }

    // If halftime started value from livescoreshop is missing, add it after 45 + 8 minute delay
    if (!match.match_time_started_ht && now > matchTime + ((45 + 15 + 8) * 60)) {
      match.match_time_started_ht = Helper.getIsoDate(new Date(matchTime + ((45 + 15) * 60)));
    }

    // Get elapsed time
    if (match.match_time_started_ht) {
      elapsed = Math.min(90, Math.floor(((now - Helper.getDate(match.match_time_started_ht).getTime()) / 60) + 45));
    } else if (match.match_time_started) {
      elapsed = Math.min(45, Math.floor((now - Helper.getDate(match.match_time_started).getTime()) / 60));
    }

    return elapsed ? Math.max(match.elapsed, elapsed) : 0;
  }

  getElapsedExtra(match, serverTime) {
    return (parseInt(match.elapsed, 10) === 90 || parseInt(match.elapsed, 10) === 45) && parseInt(match.elapsed_extra, 10) !== 0 ? '+' + match.elapsed_extra : '';
  }

  render() {
    const { txt, auth, league, matches, serverTime } = this.props;

    const match = matches[this.state.match_id];

    return (
      <div className="content-wrapper-align-top">
        {this.state.contentError || !league ? (
          <ContentError />
        ) : (
          <div>
            {match ? (
              <div>
                <h1 className="match-headline">
                  <div className="content-container">
                    {serverTime && Helper.fullDate(match.match_time, serverTime, txt, true, true)}
                  </div>
                </h1>
                <div className={'match-details-container' +
                  (match.overtime ? ' match-details-overtime' : '') +
                  (match.penalty_shootout ? ' match-details-penalty-shootout' : '')
                }>
                  <div className="match-details-club-titles">
                    <div className="match-details-club-title">
                      <div className={'match-details-club-title-short' + (match.home.id === 0 ? ' club-tbd' : '')}>{match.home['name_short_' + txt.id]}</div>
                      <div className={'match-details-club-title-long' + (match.home.id === 0 ? ' club-tbd' : '')}>{match.home['name_' + txt.id]}</div>
                    </div>
                    <div className="match-details-club-title">
                      <div className={'match-details-club-title-short' + (match.guest.id === 0 ? ' club-tbd' : '')}>{match.guest['name_short_' + txt.id]}</div>
                      <div className={'match-details-club-title-long' + (match.guest.id === 0 ? ' club-tbd' : '')}>{match.guest['name_' + txt.id]}</div>
                    </div>
                  </div>
                  <div className={'match-details-club-icon match-details-club-icon-home club-icon club' + match.home.id + (Helper.favClub(auth, match.home.id) ? ' fav' : '')} dangerouslySetInnerHTML={{__html: match.home.icon + '<i></i>'}}></div>
                  <div className={'match-details-club-icon match-details-club-icon-guest club-icon club' + match.guest.id + (Helper.favClub(auth, match.guest.id) ? ' fav' : '')} dangerouslySetInnerHTML={{__html: match.guest.icon + '<i></i>'}}></div>
                  <div className={'match-details-result' + (Helper.matchIsLive(match, serverTime) ? ' match-live' : '')}>
                    <div className="match-details-result-home">{Helper.teamGoals('home', match)}</div>
                    <div className="match-details-result-halftime">({match.goals_home_ht} : {match.goals_guest_ht})</div>
                    <div className="match-details-result-guest">{Helper.teamGoals('guest', match)}</div>
                  </div>
                  {match.home_id && match.guest_id && Helper.matchStarted(match, serverTime) && (match.overtime || match.penalty_shootout) ? (
                    <div className={'match-details-overtime-notice' + (Helper.matchIsLive(match, serverTime) ? ' match-live' : '')} dangerouslySetInnerHTML={{__html: txt.placeBet[(match.penalty_shootout ? 'penaltyShootOut' : 'overtime') + 'Notice' + (match.finished ? 'FT' : '')]}} />
                  ) : null}
                  <div className="match-details-bet-box bet-box-medium">
                    <BetBox type={parseInt(match.finished, 10) === 0 ? 'bet' : 'points'} matchObj={match} bet={match.bets && match.bets.length ? match.bets[0] : null} clickable={true} user_id={auth ? auth.id : null} />
                  </div>
                </div>
                {Helper.matchStarted(match, serverTime) ? (
                  <div className={'match-feed-wrapper' + (Helper.matchIsLive(match, serverTime) ? ' match-live' : '')}>
                    <div className="match-feed-pitch-container">
                      <div className="match-feed-pitch-clubs">
                        <div className={'match-feed-pitch-club-icon club-icon club' + match.home.id} dangerouslySetInnerHTML={{__html: match.home.icon + '<i></i>'}}></div>
                        <div className={'match-feed-pitch-club-icon club-icon club' + match.guest.id} dangerouslySetInnerHTML={{__html: match.guest.icon + '<i></i>'}}></div>
                      </div>
                      <div className="match-feed-pitch">
                        <div className="match-feed-pitch-gradient">
                          <div className="match-feed-elapsed-container" style={{width: Math.min(100, (this.getElapsed(match, serverTime) / 90 * 100)) + '%'}}>
                            <span className="match-feed-elapsed">
                              <span className="match-elapsed25992 match-feed-elapsed-time">{this.getElapsed(match, serverTime)}</span>
                              <span className="match-feed-elapsed-indicator">{'\''}</span>
                              <span className="match-feed-elapsed-extra">{this.getElapsedExtra(match, serverTime)}</span>
                            </span>
                          </div>
                        </div>
                        {match.match_feeds && match.match_feeds.length ? (
                          match.match_feeds.map((feed, index) => {
                            return (
                              this.FeedIcon(index, feed, match)
                            );
                          })
                        ) : null}
                      </div>
                    </div>
                    {match.match_feeds && match.match_feeds.length ? (
                      <div className="match-feed-incidents">
                        {match.finished ? (
                          <div className="match-feed-result" dangerouslySetInnerHTML={
                            {__html:
                              txt.pages.match.result[match.goals_home > match.goals_guest ? 'home' : (match.goals_home < match.goals_guest ? 'guest' : 'draw')]
                                .replace('{0}', match.home['name_short_' + txt.id])
                                .replace('{1}', match.guest['name_short_' + txt.id])
                                .replace('{2}', match.goals_home)
                                .replace('{3}', match.goals_guest)}
                          }></div>
                        ) : null}
                        {match.match_feeds.slice(0).reverse().map((feed, index) => {
                          return (
                            this.FeedRow(index, feed, match)
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="match-feed-wrapper match-feed-wrapper-kickoff">
                    <div className="match-feed-wrapper-kickoff-time">
                      {txt.pages.match.kickoffIn} {Helper.timeLeft(match.match_time, serverTime, txt, 'long')}
                    </div>
                  </div>
                )}
                <News news={this.state.news} />
                <div className="container">
                  <Posts postsMatch={match} />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Page;
