import { connect } from 'react-redux';
import { closeUserNav } from '../../actions';
import mapToComponent from './NotificationsComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  serverTime: state.serverTime,
  userNotifications: state.userNotifications
});

const mapDispatchToProps = {
  closeUserNav
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
