import { connect } from 'react-redux';
import { addMessage, setNetworkError } from '../../actions';
import mapToComponent from './NetworkErrorComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  networkError: state.networkError
});

const mapDispatchToProps = dispatch => ({
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
