import React from 'react';
import Transition from 'react-transition-group/Transition';

class NavOverlayComponent extends React.PureComponent {

  render() {
    const { navIsOpen, closeNav, closeSubNav } = this.props;

    return (
      <Transition in={navIsOpen} timeout={200}>
        {state => (
          <div className={'nav-overlay ' + state} onClick={() => { closeNav(); closeSubNav(); }}></div>
        )}
      </Transition>
    );
  }
}

export default NavOverlayComponent;
