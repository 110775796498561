const placeBet = (state = null, action) => {
  switch (action.type) {
    case 'OPEN_PLACE_BET':
      return {
        active: true,
        match_id: action.match_id
      };
    case 'CLOSE_PLACE_BET':
      if (!state) {
        return state;
      }
      return Object.assign({}, state, { active: false });
    default:
      return state;
  }
};

export default placeBet;