import { connect } from 'react-redux';
import { setLocation, addMessage, setNetworkError, setLeague, setUser } from '../../../actions';
import mapToComponent from './AvatarCreatorComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league
});

const mapDispatchToProps = dispatch => ({
  setLeague: league => dispatch(setLeague(league)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  setLocation: location => dispatch(setLocation(location)),
  setUser: user => dispatch(setUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
