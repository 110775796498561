import React from 'react';
import Config from '../../../config';
import API from '../../../util/API';
import ContentError from '../../ContentError/ContentErrorContainer';
import ContentLoading from '../../../util/ContentLoading';
import News from '../../News/NewsComponent';
import Posts from '../../Posts/PostsContainer';
import Highscores from '../../Highscores/HighscoresContainer';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      group: null,
      news: null,
      highscores: null,
      list: 'posts'
    };

    this.setListType = this.setListType.bind(this);
  }

  setListType(list) {
    this.setState({ list });
  }

  componentDidMount() {
    const { location, setLocation, setLoginLocation, match } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);
    this.fetchGroup(match.params.group_id);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { match, setLocation } = this.props;

    if (match.params.group_id !== nextProps.match.params.group_id) {
      setLocation(nextProps.location);
      this.fetchGroup(nextProps.match.params.group_id);
    }
  }

  fetchGroup(group_id) {
    const { txt, auth, league, setLeague, setServerTime, addMessage, setNetworkError, setContentLoading, setAppLoading } = this.props;

    this.setState({
      group: null,
      news: null,
      highscores: null,
      list: 'posts'
    });

    API.fetch({
      url: Config.apiURL + '/getGroup/' + group_id,
      txt,
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        ContentLoading.startContentLoading(league ? setContentLoading : setAppLoading);
      },
      complete: () => {
        ContentLoading.endContentLoading(league ? setContentLoading : setAppLoading);
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        response.league && setLeague(response.league);

        if (response.success) {
          this.setState({
            group: response.group,
            news: response.news || null,
            highscores: response.highscores || null
          });
        } else {

          // TODO show group not found error

        }
      }
    });
  }

  render() {
    const { txt, league } = this.props;

    return (
      <div className="content-wrapper">
        {!league ? (
          <ContentError />
        ) : (
          <div>
            {this.state.group ? (
              <div>
                <h1 className="centered">
                {this.state.group.type === 'club' ? this.state.group['name_' + txt.id] : this.state.group.name}
                </h1>
                {this.state.news && this.state.news.length ? (
                  <News news={this.state.news} />
                ) : null}
                <div className="list-wrapper" data-active={this.state.list}>
                  <div className="list-header">
                    <div className="list-headline list-headline-posts" onClick={ () => { this.setListType('posts'); }}>{txt.comments}</div>
                    <div className="list-headline list-headline-highscores" onClick={ () => { this.setListType('highscores'); }}>{txt.highscores}</div>
                  </div>
                  <div className="list-container list-posts">
                    <Posts postsGroupId={this.state.group.id} disabled={this.state.list !== 'posts'} />
                  </div>
                  <div className="list-container list-highscores">
                    <Highscores highscores={this.state.highscores} groupId={this.state.group.id} disabled={this.state.list !== 'highscores'} />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Page;
