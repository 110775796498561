import React from 'react';
import API from '../../../util/API';
import Config from '../../../config';
import ContentLoading from '../../../util/ContentLoading';
import Helper from '../../../util/Helper';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidMount() {
    const { setLocation, location, txt, addMessage, setNetworkError, setUser, match, setAppLoading } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);

    if (match && match.params.hash) {

      API.fetch({
        url: Config.apiURL + '/activate',
        data: {
          hash: match.params.hash
        },
        txt,
        beforeSend: () => {
          ContentLoading.startContentLoading(setAppLoading);
        },
        complete: () => {
          ContentLoading.endContentLoading(setAppLoading);
        },
        error: () => {
          addMessage(txt.error, 'error');
        },
        networkError: (error) => {
          setNetworkError(error);
        },
        success: (response) => {
          if (response.success) {
            setUser(response.auth);
            Helper.setNotice('AccountEmailActivated', 'ok');
            window.location.href = '/';
          } else {
            this.setState({error: true});
          }
        }
      });
    }
  }

  render() {
    const { txt } = this.props;

    return (
      <div className="content-wrapper">
        {this.state.error ? (
          <div className="content-error-container content-error-container--light">
            <h1>{txt.activateEmail.ActivateEmailError}</h1>
            <h3 dangerouslySetInnerHTML={{__html: txt.activateEmail.ActivateEmailError2}}></h3>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Page;
