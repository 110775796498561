import { connect } from 'react-redux';
import { closePlaceBet } from '../../actions';
import mapToComponent from './PlaceBetOverlayComponent';

const mapStateToProps = state => ({
  placeBet: state.placeBet
});

const mapDispatchToProps = dispatch => ({
  closePlaceBet: () => dispatch(closePlaceBet())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
