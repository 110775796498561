import { connect } from 'react-redux';
import { setUser, addMessage, setNetworkError, closeUserNav, setUserScore, setUserGroups, setUserNotifications } from '../../actions';
import mapToComponent from './UserNavComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  serverTime: state.serverTime,
  league: state.league,
  userNav: state.userNav,
  userScore: state.userScore,
  userGroups: state.userGroups
});

const mapDispatchToProps = {
  setUser,
  addMessage,
  setNetworkError,
  closeUserNav,
  setUserScore,
  setUserGroups,
  setUserNotifications
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
