import React from 'react';
import { CSSTransition } from 'react-transition-group';
import API from '../../../util/API';
import Config from '../../../config';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      email: '',
      message: '',
      error: '',
      messageError: false,
      success: '',
      messageClassName: '',
      messageAnimation: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
  }

  componentDidMount() {
    const { location, setLocation, setLoginLocation } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleMessageChange(event) {
    this.setState({message: event.target.value, messageError: false});
  }

  showMessage(type, message) {
    const { txt } = this.props;

    this.setState({
      messageAnimation: true,
      messageClassName: this.state.error && type === 'error' ? 'shake' : 'popin',
      error: type !== 'error' ? '' : (txt.contact[message] ? txt.contact[message] : txt.error),
      messageError: type === 'error',
      success: type !== 'success' ? '' : txt.contact[message]
    });
    setTimeout(() => { this.setState({messageAnimation: false}); }, 400);
  }

  sendMessage() {
    const { txt, addMessage, setNetworkError } = this.props;

    this.setState({});

    if (!this.state.message) {
      this.showMessage('error', 'errorNoMessage');
      return;
    }

    API.fetch({
      url: Config.apiURL + '/contact',
      data: {
        email: this.state.email,
        message: this.state.message
      },
      txt,
      beforeSend: () => {
        this.setState({requesting: true});
      },
      complete: () => {
        this.setState({requesting: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        if (response.success) {
          this.showMessage('success', 'successMessageSent');
        } else {
          this.showMessage('error');
        }
      }
    });
  }

  render() {
    const { txt } = this.props;

    return (
      <div className="content-wrapper">
        <div className="content-container">

          <h1>{txt.contact.headline}</h1>
          <h4>
            <div>{txt.contact.sublineLine1}</div>
            <div dangerouslySetInnerHTML={{__html: txt.contact.sublineLine2}} />
          </h4>

            <CSSTransition
              in={this.state.messageAnimation}
              timeout={{
                enter: 400,
                exit: 0
              }}
              classNames={this.state.messageClassName}
            >
              <div className={'contact-message' + (this.state.error ? ' error active' : '') + (this.state.success ? ' success active' : '')}>{this.state.error || this.state.success}</div>
            </CSSTransition>

            <div className="contact-form">
              <input type="email" onChange={this.handleEmailChange} value={this.state.email} disabled={this.state.success} className="textfield" placeholder={txt.contact.emailPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
              <textarea onChange={this.handleMessageChange} value={this.state.message} disabled={this.state.success} className={'textfield' + (this.state.messageError ? ' input-error' : '')} placeholder={txt.contact.messagePlaceholder} rows="4" autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
              <button type="button" onClick={() => { this.sendMessage(); }} disabled={this.state.requesting || this.state.success} className={'button signin-button' + (this.state.requesting ? ' loading-bar' : '')}>{txt.contact.buttonSubmit}</button>
            </div>

        </div>
      </div>
    );
  }
}

export default Page;
