import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

class MessagesComponent extends React.PureComponent {
  
  render() {
    const { messages, removeMessages, removeMessage } = this.props;

    return (
      <TransitionGroup className={'messages-wrapper' + (messages.length ? ' active' : '')}>

        {messages.map(message => (
          <CSSTransition
            key={message.id}
            timeout={{enter: 300, exit: 200}}
            classNames='message'
            onEntered={() => {
              removeMessages(message.id);
              setTimeout(() => { removeMessage(message.id); }, 5000);
            }}
          >
            <div className={'message ' + (message.className || 'error')} onClick={() => { removeMessage(message.id); }}>
              {message.message}
            </div>
          </CSSTransition>
        ))}

      </TransitionGroup>
    );
  }
}

export default MessagesComponent;
