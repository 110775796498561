import { connect } from 'react-redux';
import { setUser, toggleSubNav, closeSubNav, closeNav, closeUserNav } from '../../actions';
import mapToComponent from './NavComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league,
  location: state.location,
  subNavId: state.subNav
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  closeNav: () => dispatch(closeNav()),
  closeSubNav: () => dispatch(closeSubNav()),
  toggleSubNav: id => dispatch(toggleSubNav(id)),
  closeUserNav: () => dispatch(closeUserNav())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
