import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './reducers';
import ReactGA from 'react-ga';
import App from './components/App/AppContainer';
import * as serviceWorker from './serviceWorker';
import './css/main.scss';

ReactGA.initialize('UA-37223022-1');
ReactGA.set({ anonymizeIp: true });
ReactGA.pageview(window.location.pathname);

ReactDOM.render(
  <Provider store={createStore(reducers)}>
    <App />
  </Provider>,
 document.getElementById('root')
);

serviceWorker.unregister();