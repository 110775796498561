import { connect } from 'react-redux';
import { toggleNav, closeUserNav, toggleUserNav } from '../../actions';
import mapToComponent from './HeaderComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  location: state.location,
  serverTime: state.serverTime,
  userNav: state.userNav
});

const mapDispatchToProps = dispatch => ({
  toggleNav: id => dispatch(toggleNav(id)),
  closeUserNav: () => dispatch(closeUserNav()),
  toggleUserNav: id => dispatch(toggleUserNav(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
