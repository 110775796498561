import { connect } from 'react-redux';
import { openPlaceBet } from '../../actions';
import mapToComponent from './BetBoxComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  serverTime: state.serverTime
});

const mapDispatchToProps = dispatch => ({
  openPlaceBet: match_id => dispatch(openPlaceBet(match_id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
