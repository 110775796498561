const matchdayChips = (state = {}, action) => {
  switch (action.type) {
    case 'SET_MATCHDAY_CHIPS':
      !state[action.season_id] && (state[action.season_id] = {});
      state[action.season_id][action.matchday] = parseInt(action.chips, 10) || 0;
      return state;
    default:
      return state;
  }
};

export default matchdayChips;