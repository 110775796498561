const userNav = (state = null, action) => {
  switch (action.type) {
    case 'OPEN_USERNAV':
      return action.id;
    case 'CLOSE_USERNAV':
      return null;
    case 'TOGGLE_USERNAV':
      return state === action.id ? null : action.id;
    default:
      return state;
  }
};

export default userNav;
