import React from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../util/Helper';
import UserNav from '../Nav/UserNavContainer';

class HeaderComponent extends React.PureComponent {

  render() {
    const { txt, location, auth, toggleNav, closeUserNav, toggleUserNav, userNav } = this.props;

    return (
      <div className="header-wrapper">
        <div className="menu-toggler icon-menu" onClick={() => { closeUserNav(); toggleNav(); }} />
        {auth && (
          <div className="header-container-auth">
            <div className="header-nav-icon icon-trophy" onClick={() => { toggleUserNav('score'); }} />
            <div className="header-nav-icon icon-groups" onClick={() => { toggleUserNav('groups'); }}  />
            <div className="header-nav-icon icon-bell" onClick={() => { toggleUserNav('notifications'); }}  />
            <Link to={'/user/' + auth.username} onClick={() => { closeUserNav(); }} className="header-nav-user">
              <div className="header-nav-user-avatar"><img src={Helper.avatarImage(auth)} alt="" /></div>
            </Link>
          </div>
        )}
        {auth && userNav && <UserNav />}
        {!auth && (
          location === 'signin' ? (
            <Link to="/signup" className="header-container-auth">
              <div className="header-nav-signin-text">{txt.nav.signUp}</div>
              <div className="header-nav-signin-icon icon-signin" />
            </Link>
          ) : (
            <Link to="/signin" className="header-container-auth">
              <div className="header-nav-signin-text">{txt.nav.signIn}</div>
              <div className="header-nav-signin-icon icon-signin" />
            </Link>
          )
        )}
      </div>
    );
  }
}

export default HeaderComponent;
