import React from 'react';
import Transition from 'react-transition-group/Transition';
import Config from '../../config';
import API from '../../util/API';

class NetworkErrorComponent extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isChecking: false
    };
  }

  checkNetwork() {
    const { txt, addMessage, setNetworkError } = this.props;

    API.fetch({
      url: Config.apiURL + '/checkNetwork',
      beforeSend: () => {
        this.setState({isChecking: true});
      },
      complete: () => {
        this.setState({isChecking: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      success: (response) => {
        response.success ? setNetworkError(false) : addMessage(txt.error, 'error');
      }
    });
  };

  render() {
    const { txt, networkError } = this.props;

    return (
      <Transition
        in={networkError}
        timeout={200}
      >
      {state => (
        <div className={'network-error-container ' + state} onClick={() => { this.checkNetwork(); }}>
          <div>{txt.networkError}</div>
          <div className={'icon-reload' + (this.state.isChecking ? ' rotating' : '')} />
        </div>
      )}
      </Transition>
    );
  }
};

export default NetworkErrorComponent;