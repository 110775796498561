import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import API from '../../../util/API';
import Config from '../../../config';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      email: '',
      error: '',
      errorClassName: '',
      errorAnimation: false,
      success: '',
      inputError: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  componentDidMount() {
    const { setLocation, location } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value, inputError: false});
  }

  showError(error_code) {
    const { txt } = this.props;

    this.setState({
      errorAnimation: true,
      errorClassName: this.state.error ? 'shake' : 'popin',
      error: txt.recoverPassword[error_code] || txt.error,
      inputError: true
    });
    setTimeout(() => { this.setState({errorAnimation: false}); }, 400);
  }

  recoverPassword() {
    const { txt, addMessage } = this.props;

    if (!this.state.email) {
      this.showError('ResetPasswordErrorEmptyEmail');
      return;
    }

    API.fetch({
      url: Config.apiURL + '/recoverPassword',
      data: {
        email: this.state.email
      },
      txt,
      beforeSend: () => {
        this.setState({requesting: true});
      },
      complete: () => {
        this.setState({requesting: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      success: (response) => {
        if (response.success) {
          this.setState({
            error: '',
            success: txt.recoverPassword.ResetPasswordMessageSuccess
          });
        } else {
          this.showError(response.error);
        }
      }
    });
  }

  render() {
    const { txt } = this.props;

    return (
      <div className="content-wrapper">
        <div className="signin-wrapper recover-password-wrapper">
          <div className="content-container">

            <h1>{txt.recoverPassword.headline}</h1>
            <h3 className={this.state.success ? 'success' : ''}>{this.state.success || txt.recoverPassword.subline}</h3>

            <CSSTransition
              in={this.state.errorAnimation}
              timeout={{
                enter: 300,
                exit: 0
              }}
              classNames={this.state.errorClassName}
            >
              <div className={'signin-message error' + (this.state.error ? ' active' : '')}>{this.state.error}</div>
            </CSSTransition>

            <div className="signin-textfields signin-textfields-single">
              <input type="email" onChange={this.handleEmailChange} disabled={this.state.success} value={this.state.email} className={'textfield signin-textfield signin-textfield-single' + (this.state.inputError ? ' input-error' : '')} placeholder={txt.recoverPassword.textfieldEmailPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
            </div>

            <button type="button" onClick={() => { this.recoverPassword(); }} disabled={this.state.requesting || this.state.success} className={'button recover-password-button' + (this.state.requesting ? ' loading-bar' : '')}>{txt.recoverPassword.submitButton}</button>

            <div className="margin30" />

            <div className="signin-links">
              <Link to="/signin">{txt.recoverPassword.linkSignUp}</Link>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Page;
