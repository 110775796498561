const serverTime = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SERVER_TIME':
      return {
        date: action.serverTime.date,
        timezoneOffset: action.serverTime.timezoneOffset
      };
    default:
      return state;
  }
};

export default serverTime;