import React from 'react';
import { Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import API from '../../../util/API';
import Config from '../../../config';
import Helper from '../../../util/Helper';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showLanguages: false,
      saving: null,
      savingValue: null,
      savingTimeout: null,
      savingLoadingBars: null,
      savingSuccess: null,
      editing: null,
      nickname: '',
      username: '',
      email: '',
      emailPassword: '',
      password: '',
      passwordNew: '',
      error: null,
      errorAnimation: false,
      errorClassName: '',
      successEmail: null,
      successPassword: null,
      deleteAccountModal: false,
      deleteAccountPassword: '',
      deleteAccountError: null,
      deleteAccountRequesting: null
    };

    this.handleDeleteAccountLinkClick = this.handleDeleteAccountLinkClick.bind(this);
    this.handleBackToAccountLinkClick = this.handleBackToAccountLinkClick.bind(this);
    this.handleDeleteAccountSubmitClick = this.handleDeleteAccountSubmitClick.bind(this);
  }

  componentDidMount() {
    const { location, setLocation, setLoginLocation } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(null);
  }

  handleDeleteAccountLinkClick() {
    this.setState({
      deleteAccountPassword: '',
      deleteAccountError: null,
      deleteAccountModal: true
    });
  }

  handleBackToAccountLinkClick() {
    this.setState({
      deleteAccountPassword: '',
      deleteAccountError: null,
      deleteAccountModal: false
    });
  }

  handleDeleteAccountSubmitClick() {
    if (this.state.deleteAccountRequesting) {
      return;
    }

    const { txt, auth, setUser, addMessage, setNetworkError } = this.props;

    API.fetch({
      url: Config.apiURL + '/deleteAccount',
      data: {
        password: this.state.deleteAccountPassword
      },
      txt,
      auth,
      beforeSend: () => {
        this.setState({deleteAccountRequesting: true});
      },
      complete: () => {
        this.setState({deleteAccountRequesting: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        if (!response.success) {
          if (response.error) {
            this.setState({deleteAccountError: txt.pages.account[response.error]});
            return;
          }
          addMessage(txt.error, 'error');
        }
        setUser(null);
        window.location.href = '/';
      }
    });
  }

  saveSetting(type, value = null, password = null, success = null) {
    if (this.state.saving) {
      return;
    }

    const { txt, auth, setUser, league, setServerTime, addMessage, setNetworkError } = this.props;

    API.fetch({
      url: Config.apiURL + '/saveAccount',
      data: {
        type,
        value,
        password
      },
      txt,
      auth,
      league,
      setUser,
      setServerTime,
      beforeSend: () => {
        this.setState({
          saving: type,
          savingValue: value,
          savingTimeout: setTimeout(() => { this.setState({savingLoadingBars: type}); }, 400)
        });
      },
      complete: () => {
        this.state.savingTimeout && clearTimeout(this.state.savingTimeout);
        this.setState({
          savingTimeout: null,
          savingLoadingBars: null
        });
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        if (!response.success) {
          switch (this.state.saving) {
            case 'nickname':
            case 'username':
            case 'email':
            case 'password':
              this.setState({
                error: txt.signIn[response.error] || txt.pages.account[response.error] || txt.error,
                errorAnimation: true,
                errorClassName: this.state.error ? 'shake' : ''
              });
              setTimeout(() => { this.setState({errorAnimation: false}); }, 400);
            break;
            default:
              addMessage(txt.error, 'error');
            break;
          }
          this.setState({
            saving: false,
            savingValue: null
          });
          return;
        }
        response.auth && this.updateUser(response.auth);
        success && success(response);

        switch (this.state.saving) {
          case 'nickname':
          case 'username':
          case 'email':
          case 'password':
            this.setState({savingSuccess: this.state.saving});
            setTimeout(() => {
              this.setState({savingSuccess: null});
            }, 2500);
            if (this.state.saving === 'email') {
              this.setState({successEmail: txt.pages.account.emailChangeSuccess});
            }
            break;
          default:
            break;
        }

        this.setState({
          saving: false,
          savingValue: null,
          editing: null,
          savingLoadingBars: null,
          error: null
        });
      }
    });
  }

  updateUser(auth) {
    this.props.setUser(auth);
    this.setState({auth});
  }

  toggleShowLanguage() {
    if (this.state.saving) {
      return;
    }
    this.setState({showLanguages: !this.state.showLanguages});
  }

  cancelEditing() {
    if (this.state.saving) {
      return;
    }
    this.setState({
      editing: null,
      error: null
    });
  }

  startEditing(id) {
    this.cancelEditing();

    const { auth } = this.props;

    let state = {
      editing: id,
      successEmail: null,
      successPassword: null
    };

    switch (id) {
      case 'nickname':
      case 'username':
        state[id] = auth[id];
        break;
      case 'email':
        state[id] = state.emailPassword = '';
        break;
      case 'password':
        state[id] = state.passwordNew = '';
        break;
      default:
        break;
    }
    this.setState(state);
    this.focusElement(id);
  }

  focusElement(id) {
    const textfield = document.querySelector('.textfield-focus-' + id);

    if (!textfield) {
      return;
    }
    textfield.focus();
    const length = textfield.value.length;
    textfield.setSelectionRange(length, length);
  }

  emailAbbr(email) {
    const email_split = email.split('@');
    return email_split[0].substr(0, 2) + '...@' + email_split[1];
  }

  passwordLastChanged() {
    const { txt, auth, serverTime } = this.props;

    let passwordLastChanged = txt.pages.account.passwordChangedNever;

    if (!auth || !serverTime) {
      return passwordLastChanged;
    }

    if (auth.password_changed) {
      if (Helper.getDate(auth.password_changed).getTime() > (Helper.getDate(Helper.getIsoDateT(new Date())).getTime() - (60 * 5 * 1000))) {
        passwordLastChanged = txt.pages.account.passwordChangedJustNow;

      // Changed today
      } else if (Helper.isToday(auth.password_changed, serverTime)) {
        passwordLastChanged = txt.pages.account.passwordChangedToday;

      // Changed on date
      } else {
        passwordLastChanged = txt.pages.account.passwordChangedDate.replace('{0}', Helper.dateAgo(auth.password_changed, txt));
      }
    }
    return passwordLastChanged;
  }

  render() {
    const { txt, auth, setLanguage } = this.props;

    return (
      <div className="content-wrapper">
        {!Helper.isLoggedIn(auth) ? (
          <Redirect to="/" />
        ) : (
          <div className="content-container">
            <h1>{txt.pages.account.headline}</h1>
            {auth ? (
              <div>
                <div className="settings-wrapper">
                  <h4>{txt.pages.account.headlineEmailNotifications}</h4>
                  <div className="settings-container">
                    <div className="settings-item">
                      <div className="settings-text truncate">
                        <div className="settings-text-to-600">{txt.pages.account.emailNotificationsReminderShort}</div>
                        <div className="settings-text-from-600">{txt.pages.account.emailNotificationsReminder}</div>
                      </div>
                      <div className="settings-button" onClick={() => { this.saveSetting('email_bet_reminder'); }}>
                        <div className={'checkbox' + (this.state.savingLoadingBars === 'email_bet_reminder' ? ' loading' : '')} data-status={auth.email_bet_reminder ? 'active' : 'inactive'}>
                          {auth.email_bet_reminder ? (
                            <div className="icon-ok" />
                          ) : (
                            <div className="icon-x" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="settings-item">
                      <div className="settings-text truncate">
                        <div className="settings-text-to-600">{txt.pages.account.emailNotificationsPromotionShort}</div>
                        <div className="settings-text-from-600">{txt.pages.account.emailNotificationsPromotion}</div>
                      </div>
                      <div className="settings-button" onClick={() => { this.saveSetting('email_promotion'); }}>
                        <div className={'checkbox' + (this.state.savingLoadingBars === 'email_promotion' ? ' loading' : '')} data-status={auth.email_promotion ? 'active' : 'inactive'}>
                          {auth.email_promotion ? (
                            <div className="icon-ok" />
                          ) : (
                            <div className="icon-x" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="settings-item">
                      <div className="settings-text truncate">
                        <div className="settings-text-to-600">{txt.pages.account.emailNotificationsSummaryShort}</div>
                        <div className="settings-text-from-600">{txt.pages.account.emailNotificationsSummary}</div>
                      </div>
                      <div className="settings-button" onClick={() => { this.saveSetting('email_summary'); }}>
                        <div className={'checkbox' + (this.state.savingLoadingBars === 'email_summary' ? ' loading' : '')} data-status={auth.email_summary ? 'active' : 'inactive'}>
                          {auth.email_summary ? (
                            <div className="icon-ok" />
                          ) : (
                            <div className="icon-x" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="settings-wrapper">
                  <h4>{txt.pages.account.headlineLanguage}</h4>
                  <div className={'settings-container' + (this.state.showLanguages ? ' active' : '')}>
                    <div className="settings-item settings-item-dropdown" onClick={() => { this.toggleShowLanguage(); }}>
                      <div className="settings-text truncate">
                        {this.state.showLanguages ? txt.pages.account.chooseLanguage : txt.name}
                      </div>
                      <div className="settings-icon icon-down"></div>
                    </div>
                    <div className="settings-container-dropdown" style={{height: this.state.showLanguages ? (2 * 50) + 'px' : 0}}>
                      <div className="settings-item">
                        <div className="settings-text truncate">
                          English
                        </div>
                        <div className="settings-button" onClick={() => { txt.id !== 'en' && this.saveSetting('language', 'en', null, () => { setLanguage('en'); }); }}>
                          <div className={'checkbox' + (this.state.savingLoadingBars === 'language' && this.state.savingValue === 'en' ? ' loading' : '')} data-status={txt.id === 'en' ? 'active' : 'neutral'}>
                            {txt.id === 'en' ? (
                              <div className="icon-ok" />
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="settings-item">
                        <div className="settings-text truncate">
                          Deutsch
                        </div>
                        <div className="settings-button" onClick={() => { txt.id !== 'de' && this.saveSetting('language', 'de', null, () => { setLanguage('de'); }); }}>
                          <div className={'checkbox' + (this.state.savingLoadingBars === 'language' && this.state.savingValue === 'de' ? ' loading' : '')} data-status={txt.id === 'de' ? 'active' : 'neutral'}>
                            {txt.id === 'de' ? (
                              <div className="icon-ok" />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'settings-wrapper' + (this.state.editing === 'nickname' ? ' active' : '') + (this.state.savingSuccess === 'nickname' ? ' saving-success' : '')}>
                  <div className="settings-cancel" onClick={() => { this.cancelEditing(); }}>{txt.pages.account.cancel}</div>
                  <h4>{txt.pages.account.headlineNickname}</h4>
                  <CSSTransition
                    in={this.state.errorAnimation && this.state.editing === 'nickname'}
                    timeout={{
                      enter: 400,
                      exit: 0
                    }}
                    classNames={this.state.errorClassName}
                  >
                    <div className={'settings-error error' + (this.state.editing === 'nickname' && this.state.error ? ' active' : '')}>{this.state.error}</div>
                  </CSSTransition>
                  <div className="settings-container">
                    <div className="settings-item">
                      <div className="settings-text truncate">
                        {auth.nickname}
                      </div>
                      <input type="text" className="textfield textfield-settings textfield-focus-nickname" value={this.state.nickname} onChange={event => { this.setState({nickname: event.target.value}); }} spellCheck="false" autoComplete="false" maxLength="30" />
                      <div className="settings-icon-edit icon-edit" onClick={() => { this.startEditing('nickname'); }}></div>
                      <button className={'settings-button button icon-ok' + (this.state.savingLoadingBars === 'nickname' ? ' loading-bar' : '')} disabled={this.state.savingLoadingBars === 'nickname'} onClick={() => { this.saveSetting('nickname', this.state.nickname); }}></button>
                      <div className="settings-icon-ok icon-ok"></div>
                    </div>
                  </div>
                </div>
                <div className={'settings-wrapper' + (this.state.editing === 'username' ? ' active' : '') + (this.state.savingSuccess === 'username' ? ' saving-success' : '')}>
                  <div className="settings-cancel" onClick={() => { this.cancelEditing(); }}>{txt.pages.account.cancel}</div>
                  <h4>{txt.pages.account.headlineUsername}</h4>
                  <CSSTransition
                    in={this.state.errorAnimation && this.state.editing === 'username'}
                    timeout={{
                      enter: 400,
                      exit: 0
                    }}
                    classNames={this.state.errorClassName}
                  >
                    <div className={'settings-error error' + (this.state.editing === 'username' && this.state.error ? ' active' : '')}>{this.state.error}</div>
                  </CSSTransition>
                  <div className="settings-container">
                    <div className="settings-item">
                      <div className="settings-text truncate">
                        {auth.username}
                      </div>
                      <input type="text" className="textfield textfield-settings textfield-focus-username" value={this.state.username} onChange={event => { this.setState({username: event.target.value}); }} spellCheck="false" autoComplete="false" maxLength="30" />
                      <div className="settings-icon-edit icon-edit" onClick={() => { this.startEditing('username'); }}></div>
                      <button className={'settings-button button icon-ok' + (this.state.savingLoadingBars === 'username' ? ' loading-bar' : '')} disabled={this.state.savingLoadingBars === 'username'} onClick={() => { this.saveSetting('username', this.state.username); }}></button>
                      <div className="settings-icon-ok icon-ok"></div>
                    </div>
                  </div>
                </div>
                <div className={'settings-wrapper settings-wrapper-rows2' + (this.state.editing === 'email' ? ' active' : '') + (this.state.savingSuccess === 'email' ? ' saving-success' : '')}>
                  <div className="settings-cancel" onClick={() => { this.cancelEditing(); }}>{txt.pages.account.cancel}</div>
                  <h4>{txt.pages.account.headlineEmail}</h4>
                  <CSSTransition
                    in={this.state.errorAnimation && this.state.editing === 'email'}
                    timeout={{
                      enter: 400,
                      exit: 0
                    }}
                    classNames={this.state.errorClassName}
                  >
                    <div className={'settings-error error' + (this.state.editing === 'email' && this.state.error ? ' active' : '')}>{this.state.error}</div>
                  </CSSTransition>
                  <div className={'settings-error success' + (auth.email_changed_hash && this.state.successEmail ? ' active' : '')}>{this.state.successEmail}</div>
                  <div className={'settings-error warning' + (!this.state.error && !this.state.successEmail && this.state.editing !== 'email' && (auth.email_changed_hash || auth.email_activate_hash) ? ' active' : '')}>{txt.pages.account.confirmEmailAddress}</div>
                  <div className="settings-container">
                    <div className="settings-item">
                      <div className="settings-text truncate">
                        {this.emailAbbr(auth.email_changed ? auth.email_changed : auth.email)}
                      </div>
                      <input type="password" className="textfield textfield-settings textfield-focus-email" value={this.state.emailPassword} onChange={event => { this.setState({emailPassword: event.target.value}); }} placeholder={txt.pages.account.placeholderYourPassword} spellCheck="false" autoComplete="false" maxLength="50" />
                      <input type="text" className="textfield textfield-settings textfield-settings-row2" value={this.state.email} onChange={event => { this.setState({email: event.target.value}); }} placeholder={txt.pages.account.placeholderNewEmailAddress} spellCheck="false" autoComplete="false" maxLength="255" />
                      <div className="settings-icon-edit icon-edit" onClick={() => { this.startEditing('email'); }}></div>
                      <button className={'settings-button button icon-ok' + (this.state.savingLoadingBars === 'email' ? ' loading-bar' : '')} disabled={this.state.savingLoadingBars === 'email'} onClick={() => { this.saveSetting('email', this.state.email, this.state.emailPassword); }}></button>
                      <div className="settings-icon-ok icon-ok"></div>
                    </div>
                  </div>
                </div>
                <div className={'settings-wrapper settings-wrapper-rows2' + (this.state.editing === 'password' ? ' active' : '') + (this.state.savingSuccess === 'password' ? ' saving-success' : '')}>
                  <div className="settings-cancel" onClick={() => { this.cancelEditing(); }}>{txt.pages.account.cancel}</div>
                  <h4>{txt.pages.account.headlinePassword}</h4>
                  <CSSTransition
                    in={this.state.errorAnimation && this.state.editing === 'password'}
                    timeout={{
                      enter: 400,
                      exit: 0
                    }}
                    classNames={this.state.errorClassName}
                  >
                    <div className={'settings-error error' + (this.state.editing === 'password' && this.state.error ? ' active' : '')}>{this.state.error}</div>
                  </CSSTransition>
                  <div className="settings-container">
                    <div className="settings-item">
                      <div className="settings-text truncate">
                        {this.passwordLastChanged()}
                      </div>
                      <input type="password" className="textfield textfield-settings textfield-focus-password" value={this.state.password} onChange={event => { this.setState({password: event.target.value}); }} placeholder={txt.pages.account.placeholderPassword} spellCheck="false" autoComplete="false" maxLength="50" />
                      <input type="password" className="textfield textfield-settings textfield-settings-row2" value={this.state.passwordNew} onChange={event => { this.setState({passwordNew: event.target.value}); }} placeholder={txt.pages.account.placeholderPasswordNew} spellCheck="false" autoComplete="false" maxLength="50" />
                      <div className="settings-icon-edit icon-edit" onClick={() => { this.startEditing('password'); }}></div>
                      <button className={'settings-button button icon-ok' + (this.state.savingLoadingBars === 'password' ? ' loading-bar' : '')} disabled={this.state.savingLoadingBars === 'password'} onClick={() => { this.saveSetting('password', this.state.passwordNew, this.state.password); }}></button>
                      <div className="settings-icon-ok icon-ok"></div>
                    </div>
                  </div>

                  <div className="delete-account-link" onClick={() => { this.handleDeleteAccountLinkClick(); }}>
                    {txt.pages.account.deleteAccountLink}
                  </div>

                  <div className={'delete-account-modal-wrapper' + (this.state.deleteAccountModal ? ' active' : '')}>
                    <div className="delete-account-modal-container">
                      <div className="delete-account-modal-h1">{txt.pages.account.deleteAccountHeadline1}</div>
                      <div className="delete-account-modal-content">
                        <div className="delete-account-modal-icon"><span role="img" aria-label="Sad emoji">😢</span></div>
                        <div className="delete-account-modal-h2">{txt.pages.account.deleteAccountHeadline2}</div>
                        <div className="delete-account-modal-text">{txt.pages.account.deleteAccountText}</div>
                        {this.state.deleteAccountError ? (
                          <div className="delete-account-error">
                            {this.state.deleteAccountError}
                          </div>
                        ) : null}
                        <div className="delete-account-modal-password-container">
                          <input type="password" value={this.state.deleteAccountPassword} onChange={event => { this.setState({deleteAccountPassword: event.target.value, deleteAccountError: ''}); }} placeholder={txt.pages.account.deleteAccountPasswordPlaceholder} className="delete-account-password-textfield textfield" spellCheck="false" autoComplete="false" />
                        </div>
                        <div className="delete-account-forgot-password">
                          <Link to="/recover-password">{txt.signIn.linkForgotPassword}</Link>
                        </div>
                      </div>
                      <div className="delete-account-modal-button">
                        <div className="delete-account-modal-button-cancel" onClick={() => { this.handleBackToAccountLinkClick(); }}>{txt.pages.account.deleteAccountBackButton}</div>
                        <div className="delete-account-modal-button-submit" onClick={() => { this.handleDeleteAccountSubmitClick(); }}>{txt.pages.account.deleteAccountSubmitButton}</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Page;
