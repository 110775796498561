import { connect } from 'react-redux';
import mapToComponent from './ContentErrorComponent';

const mapStateToProps = state => ({
  txt: state.txt
});

export default connect(
  mapStateToProps
)(mapToComponent);
