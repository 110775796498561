import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import API from '../../../util/API';
import Config from '../../../config';
import Helper from '../../../util/Helper';

class SignUp extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      requestingFB: false,
      requestingInstagram: false,
      nickname: '',
      email: '',
      password: '',
      linkAccountsPassword: '',
      error: '',
      errorClassName: '',
      errorAnimation: false,
      errorLinkAccounts: '',
      errorClassNameLinkAccounts: '',
      errorAnimationLinkAccounts: false,
      success: '',
      successClassName: '',
      successAnimation: false,
      inputErrorNickname: false,
      inputErrorEmail: false,
      inputErrorPassword: false,
      inputErrorLinkAccountsPassword: false,
      instagramId: false,
      instagramAccessToken: false,
      instagramUsername: false,
      instagramEmailAlreadyRegistred: false
    };

    this.handleNicknameChange = this.handleNicknameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLinkAccountsPasswordChange = this.handleLinkAccountsPasswordChange.bind(this);
  }

  componentDidMount() {
    const { setLocation, location, txt, addMessage, setNetworkError, setUser } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);

    const urlParams = new URLSearchParams(window.location.search);
    const platform = urlParams.get('platform');
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    if (platform) {
      if (code && state && state === localStorage.getItem('loginState')) {
        API.fetch({
          url: Config.apiURL + '/signupWithPlatform',
          data: {
            platform,
            code
          },
          txt,
          beforeSend: () => {
            platform === 'instagram' ? this.setState({requestingInstagram: true}) : this.setState({requestingFB: true});
          },
          complete: () => {
            platform === 'instagram' ? this.setState({requestingInstagram: false}) : this.setState({requestingFB: false});
          },
          error: () => {
            addMessage(txt.error, 'error');
          },
          networkError: error => {
            setNetworkError(error);
          },
          success: response => {
            if (response.error) {
              this.showError('LoginError' + (platform === 'instagram' ? 'Instagram' : 'Facebook'));
            } else if (response.success) {
              if (response.auth) {
                setUser(response.auth) || addMessage(txt.error, 'error');
              } else if (response.instagram_profile_info) {
                this.showSuccess('LoginSuccessInstagram');
                this.setState({
                  nickname: response.instagram_profile_info.nickname,
                  instagramId: response.instagram_profile_info.id,
                  instagramAccessToken: response.instagram_profile_info.access_token,
                  instagramUsername: response.instagram_profile_info.username
                });
              }
            }
          }
        });
      } else {
        this.showError('LoginError' + (platform === 'instagram' ? 'Instagram' : 'Facebook'));
      }
    }
  }

  handleNicknameChange(event) {
    this.setState({nickname: event.target.value, inputErrorNickname: false});
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value, inputErrorEmail: false});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value, inputErrorPassword: false});
  }

  handleLinkAccountsPasswordChange(event) {
    this.setState({linkAccountsPassword: event.target.value, inputErrorLinkAccountsPassword: false});
  }

  showError(error_code) {
    const { txt } = this.props;

    this.setState({
      successAnimation: false,
      successClassName: '',
      success: '',
      errorAnimation: true,
      errorClassName: this.state.error ? 'shake' : 'popin',
      error: txt.signIn[error_code] || txt.error
    });
    setTimeout(() => { this.setState({errorAnimation: false}); }, 400);
  }

  showErrorLinkAccounts(error_code) {
    const { txt } = this.props;

    this.setState({
      errorAnimationLinkAccounts: true,
      errorClassNameLinkAccounts: this.state.errorLinkAccounts ? 'shake' : 'popin',
      errorLinkAccounts: txt.signIn[error_code] || txt.error
    });
    setTimeout(() => { this.setState({errorAnimationLinkAccounts: false}); }, 400);
  }

  showSuccess(success_code) {
    const { txt } = this.props;

    this.setState({
      successAnimation: true,
      successClassName: 'popin',
      success: txt.signIn[success_code],
      errorAnimation: false,
      errorClassName: '',
      error: ''
    });
    setTimeout(() => { this.setState({successAnimation: false}); }, 400);
  }

  signUp() {
    const { txt, setUser, addMessage, setNetworkError } = this.props;

    if (!this.state.nickname && !this.state.email && !this.state.password) {
      this.setState({
        inputErrorNickname: true,
        inputErrorEmail: true,
        inputErrorPassword: true
      });
      this.showError('LoginErrorSignUpDefault');
      return;
    }

    API.fetch({
      url: Config.apiURL + '/signUp',
      data: {
        nickname: this.state.nickname,
        email: this.state.email,
        password: this.state.password,
        instagramId: this.state.instagramId,
        instagramAccessToken: this.state.instagramAccessToken,
        instagramUsername: this.state.instagramUsername
      },
      txt,
      beforeSend: () => {
        this.setState({requesting: true});
      },
      complete: () => {
        this.setState({requesting: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: error => {
        setNetworkError(error);
      },
      success: response => {
        if (!response.success) {
          this.showError(response.error);
          response.error_input === 'nickname' && this.setState({inputErrorNickname: true});
          response.error_input === 'password' && this.setState({inputErrorPassword: true});

          if (response.error_input === 'email') {
            if (this.state.instagramId) {
              this.setState({
                instagramEmailAlreadyRegistred: true
              });
            }
          }
        } else {
          setUser(response.auth) || addMessage(txt.error, 'error');
        }
      }
    });
  }

  signInAndLinkAccounts() {
    const { txt, setUser, addMessage, setNetworkError } = this.props;

    if (!this.state.linkAccountsPassword) {
      this.setState({
        inputErrorLinkAccountsPassword: true
      });
      this.showErrorLinkAccounts('LoginErrorSignUpDefaultLinkAccounts');
      return;
    }

    API.fetch({
      url: Config.apiURL + '/signInAndConnectInstagramAccount',
      data: {
        email: this.state.email,
        password: this.state.linkAccountsPassword,
        instagramId: this.state.instagramId,
        instagramAccessToken: this.state.instagramAccessToken,
        instagramUsername: this.state.instagramUsername
      },
      txt,
      beforeSend: () => {
        this.setState({requestingLinkAccounts: true});
      },
      complete: () => {
        this.setState({requestingLinkAccounts: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: error => {
        setNetworkError(error);
      },
      success: response => {
        if (!response.success) {
          this.showErrorLinkAccounts(response.error);
        } else {
          setUser(response.auth) || addMessage(txt.error, 'error');
        }
      }
    });
  }

  signInWithFacebook() {
    var state = Helper.getHash();
    localStorage.setItem('loginState', state);
    window.location = 'https://www.facebook.com/v4.0/dialog/oauth?client_id=393704100749267&scope=public_profile,email&response_type=code&state=' + state + '&redirect_uri=' + Config.facebookRedirect + '/signup?platform=facebook';
  }

  signInWithInstagram() {
    var state = Helper.getHash();
    localStorage.setItem('loginState', state);
    window.location = 'https://api.instagram.com/oauth/authorize/?client_id=47b8053acdf14bb2b0dc80ebf6f3f83b&response_type=code&state=' + state + '&redirect_uri=' + Config.instagramRedirect + '/signup?platform=instagram';
  }

  render() {
    const { txt, auth, loginLocation } = this.props;

    return (
      <div className="content-wrapper">
        {auth ? (
          <Redirect to={loginLocation || '/'} />
        ) : (
          <div className="signin-wrapper">
            <div className="content-container">
              {!this.state.instagramEmailAlreadyRegistred ? (
                <div>
                  <div disabled={this.state.requestingFB} className={'third-party-login-button facebook-button' + (this.state.requestingFB ? ' loading-bar' : '')} onClick={() => { this.signInWithFacebook(); }}>
                    <div className="icon icon-facebook third-party-login-button-icon"></div>
                    <div className="third-party-login-button-text">{txt.signIn.buttonSignInWithFacebook}</div>
                  </div>

                  <div disabled={this.state.requestingInstagram} className={'third-party-login-button instagram-button' + (this.state.requestingInstagram ? ' loading-bar' : '')} onClick={() => { this.signInWithInstagram(); }}>
                    <div className="icon icon-instagram third-party-login-button-icon"></div>
                    <div className="third-party-login-button-text">{txt.signIn.buttonSignInWithInstagram}</div>
                  </div>

                  <CSSTransition
                    in={this.state.errorAnimation}
                    timeout={{
                      enter: 400,
                      exit: 0
                    }}
                    classNames={this.state.errorClassName}
                  >
                    <div className={'signin-message error' + (this.state.error ? ' active' : '')}>{this.state.error}</div>
                  </CSSTransition>

                  <CSSTransition
                    in={this.state.successAnimation}
                    timeout={{
                      enter: 400,
                      exit: 0
                    }}
                    classNames={this.state.successClassName}
                  >
                    <div className={'signin-message success' + (this.state.success ? ' active' : '')}>{this.state.success}</div>
                  </CSSTransition>

                  <div className="signin-textfields">
                    <input type="text" onChange={this.handleNicknameChange} className={'textfield signin-textfield' + (this.state.inputErrorNickname ? ' input-error' : '')} value={this.state.nickname} placeholder={txt.signIn.textfieldNicknamePlaceholder} autoCorrect="off" autoCapitalize="on" spellCheck="false" maxLength="30" />
                    <input type="email" onChange={this.handleEmailChange} className={'textfield signin-textfield' + (this.state.inputErrorEmail ? ' input-error' : '')} value={this.state.email} placeholder={txt.signIn.textfieldEmailPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
                    <input type="password" onChange={this.handlePasswordChange} className={'textfield signin-textfield' + (this.state.inputErrorPassword ? ' input-error' : '')} value={this.state.password} placeholder={txt.signIn.textfieldPasswordPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
                  </div>

                  <button type="button" onClick={() => { this.signUp(); }} disabled={this.state.requesting} className={'button signin-button' + (this.state.requesting ? ' loading-bar' : '')}>{txt.signIn.buttonSignUp}</button>

                  <div className="signup-terms">{Helper.replace(txt.signIn.terms, <Link to="/terms" dangerouslySetInnerHTML={{__html: txt.signIn.termsPart0}} />, <Link to="/privacy" dangerouslySetInnerHTML={{ __html: txt.signIn.termsPart1}} />)}</div>

                  <hr />

                  <div className="signin-links">
                    <Link to="/signin">{txt.signIn.linkSignIn}</Link>
                  </div>
                </div>
              ) : (
                <div className="signin-link-instagram">

                  <h1>{txt.signIn.LoginLinkInstagramAccountHeadline}</h1>

                  <p className="signin-link-instagram__message-headline green" dangerouslySetInnerHTML={{__html: txt.signIn.LoginLinkInstagramAccountMessageLine1}} />

                  <p className="signin-link-instagram__message-subline">
                    {txt.signIn.LoginLinkInstagramAccountMessageLine2}
                  </p>

                  <CSSTransition
                    in={this.state.errorAnimationLinkAccounts}
                    timeout={{
                      enter: 400,
                      exit: 0
                    }}
                    classNames={this.state.errorClassNameLinkAccounts}
                  >
                    <div className={'signin-message error' + (this.state.errorLinkAccounts ? ' active' : '')}>{this.state.errorLinkAccounts}</div>
                  </CSSTransition>

                  <div className="signin-textfields">
                    <input type="email" className="textfield signin-textfield" readOnly={true} disabled={true} value={this.state.email} spellCheck="false" maxLength="255" />
                    <input type="password" onChange={this.handleLinkAccountsPasswordChange} className={'textfield signin-textfield' + (this.state.inputErrorLinkAccountsPassword ? ' input-error' : '')} value={this.state.linkAccountsPassword} placeholder={txt.signIn.textfieldPasswordPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
                  </div>

                  <button type="button" onClick={() => { this.signInAndLinkAccounts(); }} disabled={this.state.requestingLinkAccounts} className={'button signin-button' + (this.state.requestingLinkAccounts ? ' loading-bar' : '')}>{txt.signIn.buttonSignIn}</button>

                  <hr />

                  <div className="signin-links">
                    <Link to="/recover-password">{txt.signIn.linkForgotPassword}</Link><br />
                    <Link to="/signin">{txt.signIn.ConnecWithInstagramCancelLink}</Link>
                  </div>

                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SignUp;
