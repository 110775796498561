import React from 'react';
import API from '../../../util/API';
import Config from '../../../config';
import ContentLoading from '../../../util/ContentLoading';
import Helper from '../../../util/Helper';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidMount() {
    const { setLocation, location, txt, addMessage, setNetworkError, setUser, match, setAppLoading } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);

    if (match && match.params.hash && match.params.type) {

      API.fetch({
        url: Config.apiURL + '/unsubscribe',
        data: {
          hash: match.params.hash,
          type: match.params.type
        },
        txt,
        beforeSend: () => {
          ContentLoading.startContentLoading(setAppLoading);
        },
        complete: () => {
          ContentLoading.endContentLoading(setAppLoading);
        },
        error: () => {
          addMessage(txt.error, 'error');
        },
        networkError: (error) => {
          setNetworkError(error);
        },
        success: (response) => {
          if (response.success) {
            setUser(response.auth);
            Helper.setNotice('AccountEmailUnsubscribed', 'ok');
            window.location.href = '/';
          } else {
            Helper.setNotice('AccountEmailUnsubscribedError', 'x');
          }
          window.location.href = '/';
        }
      });
    }
  }

  render() {
    return (
      <div className="content-wrapper"></div>
    );
  }
}

export default Page;
