let loadingTimeout = null;

const ContentLoading = {
  
  startContentLoading: setContentLoading => {
    loadingTimeout && clearTimeout(loadingTimeout);
    setContentLoading(true);
    loadingTimeout = setTimeout(() => { setContentLoading('spinner'); }, 400);
  },
  
  endContentLoading: setContentLoading => {
    loadingTimeout && clearTimeout(loadingTimeout);
    setContentLoading(false);
  }
};

export default ContentLoading;
