import React from 'react';
import { Swipeable } from 'react-swipeable';
import Helper from '../../util/Helper';

class Element extends React.PureComponent {
  
  constructor(props) {
    super(props);

    this.state = {
      activeNewsItem: 1,
      newsSwiping: 0,
      newsHeight: null,
    };

    this.setNewsHeight = this.setNewsHeight.bind(this);
    this.swipingNews = this.swipingNews.bind(this);
    this.swipedNewsLeft = this.swipedNewsLeft.bind(this);
    this.swipedNewsRight = this.swipedNewsRight.bind(this);
    this.swipingNewsUpOrDown = this.swipingNewsUpOrDown.bind(this);
  }

  componentDidMount() {
    this.setNewsHeight();
    window.addEventListener('resize', this.setNewsHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setNewsHeight);
  }

  setNewsHeight() {
    this.setState({newsHeight: this.getNewsHeight(this.state.activeNewsItem)});
  }

  getNewsHeight(activeNewsItem) {
    const item1 = document.querySelector('#news-link-wrapper' + (activeNewsItem || 1) + ' .news-link-container');
    let height = item1 ? item1.clientHeight : 0;
    if (window.innerWidth >= 501) {
      const item2 = document.querySelector('#news-link-wrapper' + ((activeNewsItem + 1) || 2) + ' .news-link-container');
      if (item2 && item2.clientHeight > height) {
        height = item2.clientHeight;
      }
    }
    return height || null;
  }

  swipingNewsUpOrDown() {
    this.setState({newsSwiping: 0});
  }

  swipingNews(ev) {
    if (ev.dir === 'Up' || ev.dir === 'Down') {
      return;
    }
    this.setState({newsSwiping: ev.absX * (ev.dir === 'Left' ? -1 : 1)});
  }

  swipedNewsLeft(ev) {
    const { news } = this.props;
    const activeNewsItem = Math.max(1, Math.min(news.length - (window.innerWidth >= 501 ? 1 : 0), ev.deltaX > 50 ? this.state.activeNewsItem + 1 : this.state.activeNewsItem));
    const newsHeight = this.getNewsHeight(activeNewsItem);
    this.setState({
      newsSwiping: 0,
      activeNewsItem,
      newsHeight
    });
  }

  swipedNewsRight(ev) {
    const { news } = this.props;
    const activeNewsItem = Math.max(1, Math.min(news.length, ev.deltaX < -50 ? this.state.activeNewsItem - 1 : this.state.activeNewsItem));
    const newsHeight = this.getNewsHeight(activeNewsItem);
    this.setState({
      newsSwiping: 0,
      activeNewsItem,
      newsHeight
    });
  }

  render() {
    const { news } = this.props;

    return (
      <div className="news-wrapper">
        {news && Object.keys(news).length ? (
            <Swipeable
              className="news-container"
              style={{
                height: this.state.newsHeight ? this.state.newsHeight + 'px' : null,
                marginLeft: 'calc(((' + (window.innerWidth >= 501 ? '50vw - 10px' : '100vw - 20px') + ') * ' + (this.state.activeNewsItem - 1) + ' * -1) + ' + this.state.newsSwiping + 'px)'
              }}
              onSwiping={this.swipingNews}
              onSwipedLeft={this.swipedNewsLeft}
              onSwipedRight={this.swipedNewsRight}
              onSwipedUp={this.swipingNewsUpOrDown}
              onSwipedDown={this.swipingNewsUpOrDown}
            >
              {Object.keys(news).map((news_item, index) => {
                return (
                  <a className="news-link-wrapper" href={'https://twitter.com/' + news[news_item].twitter_screen_name + '/status/' + news[news_item].twitter_tweet_id} id={'news-link-wrapper' + (index + 1)} target="_blank" rel="noopener noreferrer" key={index}>
                    <div className="news-link-container">
                      <div className="news-image" style={{backgroundImage: 'url(' + Helper.twitterMedia(news[news_item].twitter_media) + ')'}}>
                        {news[news_item].twitter_media_type === 'video' || news[news_item].twitter_media_type === 'animated_gif' ? (
                          <div className="play-button"></div>
                        ) : null}
                      </div>
                      <div className="news-header">
                        <div className="news-username">{news[news_item].twitter_username}</div>
                        <div className="news-source">via Twitter</div>
                      </div>
                      <div className="news-message">{news[news_item].text}</div>
                    </div>
                  </a>
                );
              })}
            </Swipeable>
        ) : null}
      </div>
    );
  }
}

export default Element;
