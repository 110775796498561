const matches = (state = {}, action) => {
  switch (action.type) {
    case 'SET_MATCHES':
      const obj = {};
      action.matches.forEach(match => {
        obj[match.id] = match;
      });
      return Object.assign({}, state, obj);
    default:
      return state;
  }
};

export default matches;