import { connect } from 'react-redux';
import mapToComponent from './MatchComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league,
  serverTime: state.serverTime
});

export default connect(
  mapStateToProps
)(mapToComponent);
