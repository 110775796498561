import { connect } from 'react-redux';
import { setLocation, setLoginLocation } from '../../../actions';
import mapToComponent from './ImprintComponent';

const mapStateToProps = state => ({
  txt: state.txt
});

const mapDispatchToProps = dispatch => ({
  setLocation: location => dispatch(setLocation(location)),
  setLoginLocation: location => dispatch(setLoginLocation(location))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
