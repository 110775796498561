import Localize from 'react-localization';
import de from './de.js';
import en from './en.js';

const availableLanguages = ['de', 'en'];
const defaultLanguage = 'en';

let txt = new Localize({
  en,
  de
});

txt._changeLanguage = (lang) => {
  if (
    lang &&
    lang !== localStorage.getItem('appLanguage') &&
    availableLanguages.indexOf(lang) !== -1
  ) {
    localStorage.setItem('appLanguage', lang);
    txt.setLanguage(lang);
  }
  return txt;
};

if (!localStorage.getItem('appLanguage')) {
  const systemLanguage = txt.getInterfaceLanguage().split('-')[0];
  localStorage.setItem('appLanguage', availableLanguages.indexOf(systemLanguage) !== -1 ? systemLanguage : defaultLanguage);
}

txt.setLanguage(localStorage.getItem('appLanguage'));

export default txt;