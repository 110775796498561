import { connect } from 'react-redux';
import { setUser, setLocation, addMessage, setNetworkError, setAppLoading } from '../../../actions';
import mapToComponent from './ActivateComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  loginLocation: state.loginLocation
});

const mapDispatchToProps = dispatch => ({
  setUser: location => dispatch(setUser(location)),
  setLocation: location => dispatch(setLocation(location)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  setAppLoading: loading => dispatch(setAppLoading(loading))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
