import React from 'react';
import Helper from '../../util/Helper';
import Match from '../Match/MatchContainer';

class Element extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      league: null,
      cupData: {},
      activeGroupId: 'A'
    };
  }

  componentDidMount() {
    const { cupData, league_prop } = this.props;

    if (!cupData || !cupData.matches || !league_prop) {
      return;
    }

    this.setState({
      league: league_prop,
      cupData,
      activeGroupId: this.getActiveGroupId(cupData)
    });
  }

  getActiveGroupId(cupData) {
    let activeGroupId = 'A';

    if (!cupData) {
      return activeGroupId;
    }

    let liveGoupFound = false;
    cupData.groups.some(group_id => {
      liveGoupFound = parseInt(cupData.groups_status[group_id].matches_live, 10) > 0 ? group_id : false;
      return liveGoupFound;
    });

    if (liveGoupFound) {
      return liveGoupFound;
    }

    let soonGoupFound = false;
    cupData.groups.some(group_id => {
      soonGoupFound = parseInt(cupData.groups_status[group_id].matches_soon, 10) > 0 ? group_id : false;
      return soonGoupFound;
    });

    if (soonGoupFound) {
      return soonGoupFound;
    }

    return activeGroupId;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update with initial matches or when league changes

    if (nextProps.cupData && nextProps.cupData.matches && nextProps.league_prop && (!this.state.cupData || !this.state.cupData.matches || !this.state.league || (this.state.league && nextProps.league_prop.Season.id !== this.state.league.Season.id))) {
      this.setState({
        league: nextProps.league_prop,
        cupData: nextProps.cupData
      });
    }
  }

  setActiveGroup(activeGroupId) {
    this.setState({
      activeGroupId
    });
  }

  checkRoundAvailable(groupId) {
    if (!this.state.cupData.matches || !this.state.cupData.matches[groupId]) {
      return false;
    }

    if (this.state.cupData.rounds_status && parseInt(this.state.cupData.rounds_status[groupId].clubs_tbd, 10) > 0) {
      return true;
    }
    return false;
  }


  render() {
    const { txt, league, matches } = this.props;

    return (
      <div className="cup-data-wrapper">
        {this.checkRoundAvailable('final') ? (
          <div className="cup-data-container">
            <div className="cup-data-title-container">
              <div className="cup-data-title">
                {Helper.getMatchdayName(txt, 8, league)}
              </div>
            </div>
            <div className="matchday-matches">
              {this.state.cupData.matches['final'].map((match, index) => {
                return (
                  <Match match={matches[match.id]} key={index} />
                );
              })}
            </div>
          </div>
        ) : null}
        {this.checkRoundAvailable('place-3') ? (
          <div className="cup-data-container">
            <div className="cup-data-title-container">
              <div className="cup-data-title">
                {Helper.getMatchdayName(txt, 7, league)}
              </div>
            </div>
            <div className="matchday-matches">
              {this.state.cupData.matches['place-3'].map((match, index) => {
                return (
                  <Match match={matches[match.id]} key={index} />
                );
              })}
            </div>
          </div>
        ) : null}
        {this.checkRoundAvailable('semi-finals') ? (
          <div className="cup-data-container">
            <div className="cup-data-title-container">
              <div className="cup-data-title">
                {Helper.getMatchdayName(txt, 6, league)}
              </div>
            </div>
            <div className="matchday-matches">
              {this.state.cupData.matches['semi-finals'].map((match, index) => {
                return (
                  <Match match={matches[match.id]} key={index} />
                );
              })}
            </div>
          </div>
        ) : null}
        {this.checkRoundAvailable('quarter-finals') ? (
          <div className="cup-data-container">
            <div className="cup-data-title-container">
              <div className="cup-data-title">
                {Helper.getMatchdayName(txt, 5, league)}
              </div>
            </div>
            <div className="matchday-matches">
              {this.state.cupData.matches['quarter-finals'].map((match, index) => {
                return (
                  <Match match={matches[match.id]} key={index} />
                );
              })}
            </div>
          </div>
        ) : null}
        {this.checkRoundAvailable('round-of-16') ? (
          <div className="cup-data-container">
            <div className="cup-data-title-container">
              <div className="cup-data-title">
                {Helper.getMatchdayName(txt, 4, league)}
              </div>
            </div>
            <div className="matchday-matches">
              {this.state.cupData.matches['round-of-16'].map((match, index) => {
                return (
                  <Match match={matches[match.id]} key={index} />
                );
              })}
            </div>
          </div>
        ) : null}
        {this.state.cupData.groups && this.state.cupData.groups.length ? (
          <div className="cup-data-container cup-data-groups-container">
            <div className="cup-data-title-container">
              <div className="cup-data-title">
                {this.state.cupData.groups.map((groupId, index) => {
                  return (
                    <div className={'cup-data-title-button' + (groupId === this.state.activeGroupId ? ' active' : '') + (parseInt(this.state.cupData.groups_status[groupId].matches_live, 10) > 0 ? ' live-match' : '')} key={index} onClick={() => { this.setActiveGroup(groupId); }}>
                      {groupId}
                    </div>
                  );
                })}
              </div>
            </div>
            {this.state.cupData.groups.map((groupId, index) => {
              return (
                <div className={'cup-matches-wrapper' + (groupId === this.state.activeGroupId ? ' active' : '')} key={index}>
                  <div className="cup-standings-header-container">
                    <div className="cup-standings-content">
                      <div className="cup-standings-title">{txt.pages.standings.headerGroup.replace('{0}', groupId)}</div>
                      <div className="cup-standings-won">{txt.pages.standings.headerWon}</div>
                      <div className="cup-standings-draw">{txt.pages.standings.headerDraw}</div>
                      <div className="cup-standings-lost">{txt.pages.standings.headerLost}</div>
                      <div className="cup-standings-points">{txt.pages.standings.headerPoints}</div>
                    </div>
                  </div>
                  <div className="cup-standings-wrapper">
                    {this.state.cupData.standings[groupId].map((standing, index2) => {
                      return (
                        <div className="cup-standings-container" key={index2}>
                          <div className="cup-standings-content">
                            <div className="cup-standings-title">{standing.place}. {standing.club['name_' + txt.id]}</div>
                            <div className="cup-standings-won">{standing.won}</div>
                            <div className="cup-standings-draw">{standing.draw}</div>
                            <div className="cup-standings-lost">{standing.lost}</div>
                            <div className="cup-standings-points">{standing.points}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="matchday-matches">
                    {this.state.cupData.matches['group_' + groupId].map((match, index3) => {
                      return (
                        <Match match={matches[match.id]} key={index3} />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Element;
