import React from 'react';

const ContentDE = () => (
  <div className="content-wrapper-text">
  <div className="content-text">
    <h1>KICKPros Punktesystem</h1>

    <p>
      Für deine Tipps erhältst du nach Spielende <b>Spiel-Punkte</b> und <b>Tendenz-Punkte</b>.
    </p>

    <h2>Spiel-Punkte</h2>

    <p>
      Du kannst bis zu 15 Spiel-Punkte für einen Tipp erhalten
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left point-system-points green">8 P</div>
    <div className="point-system-col-right">
        <b>Spielausgang</b>
        <p>
          Du erhältst 8 Punkte wenn du den Ausgang des Spiels richtig getippt hast, also Heimsieg, Gastsieg oder Unentschieden
        </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left point-system-points green">3 P</div>
    <div className="point-system-col-right">
        <b>Tordifferenz</b>
        <p>
          Du erhältst 3 Punkte wenn du die Tordifferenz des Spiels richtig getippt hast (z.B. Getippt 2 : 3 und Ergebnis 1 : 2)
        </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left point-system-points green">2 P</div>
    <div className="point-system-col-right">
        <b>Toranzahl</b>
        <p>
          Wenn du die Anzahl der Tore für das Heimteam oder Gastteam richtig getippt hast bekommst du jeweils 2 Punkte
        </p>
    </div>
    </div>

    <h2>Tendenz-Punkte</h2>

    <p>
      Die Tipp-Tendenzen zeigen dir wie viele Mitspieler auf welchen Spielausgang getippt haben.
    </p>
    <p>
      Tippst du auf ein Ergebnis, auf welches nur wenige andere Spieler getippt haben, kannst du Bonus-Punkte erhalten.
      Je weniger Spieler auf den gleichen Spielausgang wie du getippt haben, desto mehr Punkte erhältst du.
    </p>
    <p>
      Maximal kannst du 8 Tendenz-Punkte erhalten.
    </p>

    <h2>Medaillen</h2>
    <p>
      Gute Tipps werden mit Medaillen belohnt
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-medal medal-gold"></div></div>
    <div className="point-system-col-right">
        <b>Gold-Medaille</b>
        <p>
          Erhältst du für einen komplett richtigen Tipp
        </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-medal medal-silver"></div></div>
    <div className="point-system-col-right">
        <b>Silber-Medaille</b>
        <p>
          Erhältst du, wenn du die Tordifferenz richtig getippt hast
        </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-medal medal-bronze"></div></div>
    <div className="point-system-col-right">
        <b>Bronze-Medaille</b>
        <p>
          Wenn der Gewinner und eine Toranzahl richtig getippt wurden
        </p>
    </div>
    </div>

    <h2>Schilder</h2>

    <p>
      Verstecke deine Tipps mit einem Schild
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-shield shield"></div></div>
    <div className="point-system-col-right">
        <p>
          Deine Tipps können von anderen Spielern angesehen werden. Alle 20 Stunden hast du die Möglichkeit einen deiner Tipps mit einem Shild zu verstecken.
        </p>
    </div>
    </div>

    <h2>K.O.-Spiele</h2>

    <p>
      Alle Spiele auf KICKPros werden auf das Ergebnis <b className="b500">nach 90 Minuten</b> getippt
    </p>

    <p>
      Auch K.O.-Spiele, in welchen es Verlängerung oder Elfmeterschießen geben kann, werden auf das Ergebnis nach 90 Minuten getippt.
    </p>

    <h2>Chips</h2>

    <p>
      In <b className="b500">Liga-Spielen</b> (z.B. Bundesliga) erhältst du an jedem Spieltag 5 gratis Chips.
      <b className="b500">Meisterschafts-Spiele</b> (z.B. Champions League, Weltmeisterschaft) startest du mit 10 Chips.
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-chip chip"></div></div>
    <div className="point-system-col-right">
        <b>Erhalte weitere Chips</b>
        <p>
          Weitere Chips erhältst du für deine Medaillen (5 für Gold, 3 für Silber und 1 für Bronze).
        </p>

        <p>
          In Liga-Spielen sind die Chips nur für den jeweiligen Spieltag gültig und können nicht angesammelt werden.
        </p>
    </div>
    </div>

    <h2>Joker</h2>

    <p>
      Deine Chips kannst du für Joker ausgeben
    </p>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-joker joker joker-x2">×2</div></div>
    <div className="point-system-col-right">
        <b>Doppel-Joker (5 Chips)</b>
        <p>
          Du erhältst die doppelte Gesamt-Punktzahl für deinen Tipp (inklusive Tendenz-Punkte und Halbzeit-Joker-Punkte)
        </p>
    </div>
    </div>

    <div className="point-system-cols">
    <div className="point-system-col-left"><div className="point-system-joker joker joker-ht">Hz</div></div>
    <div className="point-system-col-right">
        <b>Halbzeit-Joker (1 bis 3 Chips)</b>
        <p>
          Du kannst bis zu 3 Chips auf das Halbzeit-Ergebnis setzen und bekommst bei richtigem Halbzeit Ergebnis für jeden gesetzten Chip einen Punkt.
          Bei einer Tipp-Tendenz von bis zu 10% erhältst du 2 Punkte pro gesetzten Chip
        </p>
    </div>
    </div>
  </div>
  </div>
);

export default ContentDE;
