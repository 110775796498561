import React from 'react';
import { Link } from 'react-router-dom';
import Config from '../../../config';
import API from '../../../util/API';
import Helper from '../../../util/Helper';
import ContentError from '../../ContentError/ContentErrorContainer';
import ContentLoading from '../../../util/ContentLoading';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      badges: [],
      userSeasons: [],
      userGroups: {}
    };
  }

  componentDidMount() {
    const { location, setLocation, setLoginLocation, match } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);

    this.fetchUser(match.params.username);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { match, setLocation } = this.props;

    if (match.params.username !== nextProps.match.params.username) {
      setLocation(nextProps.location);
      this.fetchUser(nextProps.match.params.username);
    }
  }

  fetchUser(username) {
    const { txt, auth, league, setLeague, setUser, setServerTime, addMessage, setNetworkError, setContentLoading, setAppLoading } = this.props;

    // TODO
    // Fetch is called before league (or auth) is set from local storage
    // Think of better solution!
    // Maybe check in API.fetch
    const appLeague = Helper.getLeague(league);

    API.fetch({
      url: Config.apiURL + '/getProfile',
      data: {
        username
      },
      txt,
      auth,
      league: appLeague,
      setLeague,
      setUser,
      setServerTime,
      beforeSend: () => {
        ContentLoading.startContentLoading(league ? setContentLoading : setAppLoading);
      },
      complete: () => {
        ContentLoading.endContentLoading(league ? setContentLoading : setAppLoading);
      },
      error: () => {
        addMessage(txt.error, 'error'); // TODO Page error?
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        if (response.success) {
          this.setState({
            user: response.data.user,
            badges: response.data.badges,
            userSeasons: response.data.user_seasons,
            userGroups: response.data.user_groups
          });
        } else {
          // TODO user not found
        }
      }
    });
  }

  render() {
    const { txt, auth, league } = this.props;

    return (
      <div className="content-wrapper-align-top">
        {!league ? (
          <ContentError />
        ) : (
          <div className="user-wrapper">
            {!this.state.user ? (
              <div className="user-not-found-container">
                <div className="user-avatar-image">
                   <img src={Config.imgURL + '/avatar.svg'} alt="" />
                </div>
                <div className="user-nickname">
                   {txt.pages.user.userNotFoundHeadline}
                   <br/>
                   {txt.pages.user.userNotFoundSubline}
                </div>
              </div>
            ) : (
              this.state.user.deleted ? (
                <div className="user-deleted-container">
                  <div className="user-avatar-image">
                     <img src={Helper.avatarImage(this.state.user)} alt="" />
                  </div>
                  <div className="user-nickname">
                     {this.state.user.nickname}
                  </div>
                  <div className="user-deleted-notice">
                     {txt.pages.user.userDeletedSubline}
                  </div>
                </div>
              ) : (
                <div className="user-container">
                  <div className="user-avatar-image">
                    {auth && this.state.user.id === auth.id ? (
                      <Link to="/avatar-creator">
                        <img src={Helper.avatarImage(this.state.user)} alt="" />
                      </Link>
                    ) : (
                      <img src={Helper.avatarImage(this.state.user)} alt="" />
                    )}
                  </div>
                  <div className="user-nickname">
                     {this.state.user.nickname}
                  </div>
                  <div className="user-username">
                     @{this.state.user.username}
                  </div>
                  {/*
                  <div className="user-rows-wrapper">
                    <h2 className="user-rows-title">
                       Badges
                    </h2>
                    <div className="user-rows-container">
                      TODO
                    </div>
                  </div>
                  */}
                  {this.state.userSeasons && this.state.userSeasons.length ? (
                    <div className="user-rows-wrapper">
                      <h2 className="user-rows-title">
                         Leagues
                      </h2>
                      <div className="user-rows-container">
                         {this.state.userSeasons.map((userSeason, index) => {
                          return (
                            <Link to={'/league/' + userSeason.league['name_url']} className="user-row user-row-league" key={index}>
                              <div className={'user-row-icon club-icon club-' + userSeason.league['name_tiny']}></div>
                              <div className="user-row-title">{userSeason.league['name_' + txt.id]}</div>
                              <div className="user-row-info user-row-info-bets">{userSeason.bets} / {userSeason.matches}<span>{txt.bets}</span></div>
                              <div className="user-row-info">{userSeason.points}<span>{txt.pointsShort}</span></div>
                              <div className="user-row-info">{userSeason.place}.<span>{txt.place}</span></div>
                            </Link>
                          );
                         })}
                      </div>
                    </div>
                  ) : null}
                  {this.state.userGroups && (this.state.userGroups.private.length || this.state.userGroups.public.length) ? (
                    <div className="user-rows-wrapper">
                      <div className="app-content">
                        <h2 className="user-rows-title">
                          Groups
                        </h2>
                      </div>
                      <div className="user-rows-container">
                        {this.state.userGroups.private.length ? (
                          <div className="user-rows-subtitle-container">
                            <div className="user-rows-subtitle app-content">
                              {txt.pages.user.privateGroupsTitle}
                            </div>
                          </div>
                        ) : null}
                        {this.state.userGroups.private.length ? this.state.userGroups.private.map((userGroup, index) => {
                          return (
                            <Link to={'/group/' + userGroup.id} className="user-row-container user-row-group" key={index}>
                              <div className="user-row app-content">
                                <div className="user-row-icon group-icon group-icon-private icon-groups group-icon-color1"></div>
                                <div className="user-row-title">{userGroup.name}</div>
                                <div className="user-row-info">{userGroup.users}<span>{userGroup.users === 1 ? txt.user : txt.users}</span></div>
                                <div className="user-row-info">{userGroup.place}.<span>{txt.place}</span></div>
                              </div>
                            </Link>
                          );
                        }) : null}
                        {this.state.userGroups.public.length ? (
                          <div className="user-rows-subtitle-container">
                            <div className="user-rows-subtitle app-content">
                              {txt.pages.user.publicGroupsTitle}
                            </div>
                          </div>
                        ) : null}
                        {this.state.userGroups.public.length ? this.state.userGroups.public.map((userGroup, index) => {
                          return (
                            <Link to={'/group/' + userGroup.id} className="user-row-container user-row-group" key={index}>
                              <div className="user-row app-content">
                                <div className={'user-row-icon club-icon club' + userGroup.club_id}><img src={'/img/clubs/' + userGroup.club_id + '.svg'} alt="" /><i /></div>
                                <div className="user-row-title">{userGroup['name_' + txt.id]}</div>
                                <div className="user-row-info">{userGroup.users}<span>{userGroup.users === 1 ? txt.user : txt.users}</span></div>
                                <div className="user-row-info">{userGroup.place}.<span>{txt.place}</span></div>
                              </div>
                            </Link>
                          );
                        }) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Page;
