import { connect } from 'react-redux';
import { closeUserNav } from '../../actions';
import mapToComponent from './FooterComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  location: state.location
});

const mapDispatchToProps = dispatch => ({
  closeUserNav: () => dispatch(closeUserNav())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
