import { connect } from 'react-redux';
import { setLocation } from '../../../actions';
import mapToComponent from './PageNotFoundComponent';

const mapStateToProps = state => ({
  txt: state.txt
});

const mapDispatchToProps = dispatch => ({
  setLocation: location => dispatch(setLocation(location))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
