import React from 'react';
import Config from '../../config';
import API from '../../util/API';
import Helper from '../../util/Helper';

class FetchComponent extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      league: null,
      matches: {},
      auth: null
    };
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this.setState({
      league: nextProps.league,
      matches: nextProps.matches || {},
      serverTime: nextProps.serverTime,
      auth: nextProps.auth
    });
  }

  componentDidMount() {
    const { txt, setServerTime, setUser, setNetworkError, setMatches } = this.props;

    const checkInterval = setInterval(() => {


      // TODO Why from state and not from redux?


      const league = this.state.league;
      const matches = this.state.matches;
      const serverTime = this.state.serverTime;
      const auth = this.state.auth;

      if (!league || !serverTime || !serverTime.date) {
        return null;
      }

      // Pass live matches from state matches, cause once a match is finished it won't get grabbed by the API
      let liveMatches = [];

      Object.keys(matches).forEach((match_id) => {
        if (Helper.matchIsLive(matches[match_id], serverTime)) {
          liveMatches.push(match_id);
        }
      });

      API.fetch({
        url: Config.apiURL + '/fetch',
        data: {
          league_id: league.League.id,
          season_id: league.Season.id,
          live_matches: liveMatches
        },
        txt,
        auth,
        setUser,
        setServerTime,
        networkError: error => {
          setNetworkError(error);
        },
        success: (response) => {
          response.matches && response.matches.length && setMatches(response.matches);

          // TODO Posts

          // TODO setUserGroups

          // TODO setLeagueScores when there are finished matches

        }
      });

    }, Config.checkInterval * 1000);

    this.setState({checkInterval});
  }

  componentWillUnmount() {
    this.state.checkInterval && clearInterval(this.state.checkInterval);
  }

  render() {
    return (
      null
    );
  }
}

export default FetchComponent;
