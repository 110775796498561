import React from 'react';
import ContentError from '../../ContentError/ContentErrorContainer';
import Highscores from '../../Highscores/HighscoresContainer';

class Page extends React.PureComponent {

  componentDidMount() {
    const { location, setLocation, setLoginLocation } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(location);
  }

  render() {
    const { txt, league } = this.props;

    return (
      <div className="content-wrapper">
        {!league ? (
          <ContentError />
        ) : (
          <div>
            <div className="content-container">
              <h1>{league.League['name_' + txt.id]}</h1>
            </div>
            <div className="container">
              <Highscores />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Page;
