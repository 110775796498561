import { connect } from 'react-redux';
import { setLocation, addMessage, setContentLoading } from '../../../actions';
import mapToComponent from './NewPasswordComponent';

const mapStateToProps = state => ({
  txt: state.txt
});

const mapDispatchToProps = dispatch => ({
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setLocation: location => dispatch(setLocation(location)),
  setContentLoading: loading => dispatch(setContentLoading(loading))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);