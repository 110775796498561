import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Linkify from 'react-linkify';
import { Swipeable } from 'react-swipeable';
import Config from '../../config';
import API from '../../util/API';
import Helper from '../../util/Helper';

class Element extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      posts: null,
      postsError: null,
      morePosts: false,
      postSwipingId: null,
      postSwipingIdCache: null,
      postSwiped: null,
      postSwiping: 0,
      updatingPost: false,
      reportedPosts: [],
      fetchingPosts: false,
      fetchingMorePosts: false,
      fetchingMorePostsError: false,
      comment: '',
      commentLoadingBars: '',
      textareaRows: 1
    };

    this.autoLoadPosts = this.autoLoadPosts.bind(this);
    this.swipingPost = this.swipingPost.bind(this);
    this.swipingPostLeft = this.swipingPostLeft.bind(this);
    this.swipedPostLeft = this.swipedPostLeft.bind(this);
    this.swipingPostRight = this.swipingPostRight.bind(this);
    this.swipedPostRight = this.swipedPostRight.bind(this);
  }

  componentDidMount() {
    this.fetchPosts();

    try {
      const reportedPosts = JSON.parse(localStorage.getItem('reportedPosts'));
      reportedPosts && this.setState({reportedPosts});
    } catch (e) {}

    window.addEventListener('resize', this.autoLoadPosts);
    window.addEventListener('scroll', this.autoLoadPosts);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.autoLoadPosts);
    window.removeEventListener('scroll', this.autoLoadPosts);
  }

  swipingPost(ev, id) {
    if (ev.dir === 'Up' || ev.dir === 'Down') {
      return;
    }
    this.setState({postSwipingIdCache: id});
    this['swipingPost' + ev.dir](ev.absX);
  }

  swipingPostLeft(absX) {
    this.setState({
      postSwipingId: this.state.postSwipingIdCache,
      postSwiping: this.state.postSwiped ? 120 : Math.min(absX, 120)
    });
  }

  swipingPostRight(absX) {
    if (!this.state.postSwiped) {
      return;
    }
    this.setState({
      postSwiping:  Math.max(0, 120 - absX)
    });
  }

  swipedPostLeft(ev) {
    const isSwiped = ev.deltaX > 50;
    this.setState({
      postSwipingIdCache: null,
      postSwiping: isSwiped ? 120 : 0,
      postSwiped: isSwiped ? this.state.postSwipingId : null
    });
  }

  resetSwiping() {
    this.setState({
      postSwipingId: null,
      postSwipingIdCache: null,
      postSwiping: 0,
      postSwiped: null
    });
  }

  swipedPostRight(ev) {
    if (!this.state.postSwiped) {
      return;
    }
    const isSwiped = ev.deltaX < -50;
    this.setState({
      postSwiping: isSwiped ? 0 : 120,
      postSwipingId: isSwiped ? null : this.state.postSwipingId,
      postSwipingIdCache: null,
      postSwiped: isSwiped ? null : this.state.postSwiped
    });
  }

  updatePost(type, post, undo = false) {

    const { txt, auth, league, setServerTime, addMessage, setNetworkError } = this.props;

    switch (type) {
      case 'delete':
        if (!undo && post.deleted) {
          this.resetSwiping();
          return;
        }
        break;
      case 'report':
        if (!undo && ((auth && post.reported_user === auth.id) || (!auth && this.state.reportedPosts.indexOf(post.id) !== -1))) {
          this.resetSwiping();
          return;
        }
        break;
      default:
        break;
    }

    API.fetch({
      url: Config.apiURL + '/' + type + 'Comment/' + post.id + (undo ? '/1' : ''),
      txt,
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        this.setState({updatingPost: true});
      },
      complete: () => {
        this.setState({updatingPost: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        if (!response.success) {
          return addMessage(txt.error, 'error');
        }
        this.resetSwiping();

        if (type === 'report' && !auth) {
          let reportedPosts = this.state.reportedPosts.slice(0);
          if (undo) {
            reportedPosts = Helper.removeFromArray(reportedPosts, post.id);
          } else {
            reportedPosts.push(post.id);
          }
          localStorage.setItem('reportedPosts', JSON.stringify(reportedPosts));
          this.setState({reportedPosts});
        }

        let posts = this.state.posts.slice(0);
        posts.forEach(function (index, item) {
          if (item.id === post.id) {
            switch(type) {
              case 'delete':
                posts[index].deleted = undo ? 0 : 1;
                break;
              case 'report':
                posts[index].post_reports = undo ? [] : [{
                  post_id: post.id,
                  user_id: auth ? auth.id : null
                }];
                break;
              default:
                break;
            }
          }
        });
        this.setState({posts});
      }
    });
  }

  autoLoadPosts() {
    if (
      this.props.disabled ||
      this.state.postsError ||
      this.state.fetchingMorePostsError ||
      this.state.posts === null ||
      !this.state.posts.length ||
      !this.state.morePosts)
    {
      return;
    }

    const loading_row = document.querySelector('.posts-loading-row');

    if (!loading_row) {
      return;
    }

    const loading_row_top = Helper.offsetTop(loading_row);
    const window_top = window.pageYOffset || document.documentElement.scrollTop;
    const window_height = window.innerHeight || document.documentElement.clientHeight;
    const window_bottom = window_top + window_height;

    if (window_bottom > loading_row_top) {
      this.fetchMorePosts();
    }
  }

  fetchPosts() {
    if (this.state.fetchingPosts) {
      return;
    }

    const { txt, auth, postsMatch, postsGroupId, postsLeagueId, setServerTime, setNetworkError } = this.props;

    API.fetch({
      url: Config.apiURL + '/comments',
      data: {
        league_id: postsLeagueId || null,
        match_id: postsMatch ? postsMatch.id : null,
        group_id: postsGroupId || null,
        home_twitter_id: postsMatch && postsMatch.home ? postsMatch.home.twitter_id : null,
        guest_twitter_id: postsMatch && postsMatch.guest ? postsMatch.guest.twitter_id : null
      },
      txt,
      auth,
      setServerTime,
      beforeSend: () => {
        this.setState({fetchingPosts: true});
      },
      complete: () => {
        this.setState({fetchingPosts: false});
      },
      error: () => {
        this.setState({postsError: true});
      },
      networkError: (error) => {
        this.setState({postsError: true});
        setNetworkError(error);
      },
      success: (response) => {
        if (response.success && response.posts && response.posts.posts) {
          this.setState({
            postsError: false,
            posts: response.posts.posts || [],
            morePosts: parseInt(response.posts.more_posts, 10) === 1
          });
        }
        this.autoLoadPosts();
      }
    });
  }

  fetchMorePosts(manually) {
    if (this.state.fetchingPosts || this.state.fetchingMorePosts) {
      return;
    }

    const { txt, auth, postsMatch, postsGroupId, postsLeagueId, setServerTime, addMessage, setNetworkError } = this.props;

    API.fetch({
      url: Config.apiURL + '/comments',
      data: {
        offset: this.state.posts.length,
        league_id: postsLeagueId,
        match_id: postsMatch ? postsMatch.id : null,
        group_id: postsGroupId,
        home_twitter_id: postsMatch && postsMatch.home ? postsMatch.home.twitter_id : null,
        guest_twitter_id: postsMatch && postsMatch.guest ? postsMatch.guest.twitter_id : null
      },
      txt,
      auth,
      setServerTime,
      beforeSend: () => {
        this.setState({fetchingMorePosts: true});
      },
      complete: () => {
        this.setState({fetchingMorePosts: false});
      },
      error: () => {
        manually && addMessage(txt.error, 'error');
        this.setState({fetchingMorePostsError: true});
      },
      networkError: (error) => {
        if (error) {
          manually && addMessage(txt.error, 'error');
          this.setState({fetchingMorePostsError: true});
        }
        setNetworkError(error);
      },
      success: (response) => {
        if (response.success && response.posts && response.posts.posts) {
          this.setState({
            posts: this.state.posts.concat(response.posts.posts),
            morePosts: parseInt(response.posts.more_posts, 10) === 1,
            fetchingMorePostsError: false
          });
        } else {
          addMessage(txt.error, 'error');
        }
        this.autoLoadPosts();
      }
    });
  }

  savePost() {
    const { txt, auth, postsMatch, postsGroupId, postsLeagueId, setServerTime, addMessage, setNetworkError } = this.props;

    if (this.savingPost) {
      return;
    }

    API.fetch({
      url: Config.apiURL + '/saveComment',
      data: {
        league_id: postsLeagueId || null,
        match_id: postsMatch ? postsMatch.id : null,
        group_id: postsGroupId || null,
        comment: this.state.comment
      },
      txt,
      auth,
      setServerTime,
      beforeSend: () => {
        this.savingPost = true;
        this.setState({
          commentSaved: 0,
          savingTimeout: setTimeout(() => { this.setState({commentLoadingBars: true}); }, 400)
        });
      },
      complete: () => {
        this.savingPost = false;
        this.state.savingTimeout && clearTimeout(this.state.savingTimeout);
        this.setState({
          savingTimeout: null,
          commentLoadingBars: false
        });
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        if (response.success && response.post) {
          this.state.posts.unshift(response.post);
          this.setState({
            commentSaved: response.post.id,
            comment: '',
            textareaRows: 1,
            posts: this.state.posts
          });
        } else {
          addMessage(txt.error, 'error');
        }
      }
    });
  }

  textareaChange(event) {
    this.setState({comment: event.target.value});
    this.setTextareaHeight();
  }

  setTextareaHeight() {
    const textarea = document.querySelector('.posts-input');

    if (textarea) {
      let rows = 1;
      textarea.setAttribute('rows', rows);
      while (textarea.clientHeight < textarea.scrollHeight) {
        rows++;
        textarea.setAttribute('rows', rows);
      }
      this.setState({textareaRows: rows});
    }
  }

  render() {

    const { txt, auth, serverTime } = this.props;

    return (
      <div className="posts-wrapper">
        {this.state.posts === null ? (
          <div className={'posts-container-loading' + (this.state.fetchingPosts && !this.state.postsError ? ' spinner' : '')}>
            {this.state.postsError ? (
              <div className="posts-loading-error">
                <div className="posts-loading-error-text">{txt.posts.postsError}</div>
                <div className={'posts-loading-error-retry icon-reload' + (this.state.fetchingPosts ? ' rotating' : '')} onClick={() => { this.fetchPosts(); }}></div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="posts-container-has-posts">
            <div className="posts-input-container">
              <textarea className="posts-input textfield" rows={this.state.textareaRows} cols="20" disabled={!auth} placeholder={txt.posts.postsInputPlaceholder} value={this.state.comment} onChange={event => { this.textareaChange(event); }}></textarea>
              <button className={'posts-submit-button button icon-pen' + (this.state.commentLoadingBars ? ' loading' : '')} disabled={!auth || this.state.comment === '' || this.state.commentLoadingBars} onClick={() => { this.savePost(); }}></button>
            </div>

            <div className="posts-container">
              {this.state.posts && this.state.posts.length ? (
                this.state.posts.map((post, index) => {
                  return (
                    <CSSTransition
                      in={this.state.commentSaved === post.id}
                      key={index}
                      classNames="post"
                      timeout={{enter: 200, exit: 0}}
                      className={'post-wrapper' + (auth && auth.id === post.user_id ? ' post-highlight' : '') + (this.state.postSwiped === post.id ? ' post-swiped' : '')}
                      >
                      <div>
                        <Swipeable
                          className="post-swiper"
                          style={{
                            marginLeft: this.state.postSwipingId === post.id ? this.state.postSwiping * -1 : 0
                          }}
                          onSwiping={ev => { this.swipingPost(ev, post.id); }}
                          onSwipedLeft={this.swipedPostLeft}
                          onSwipedRight={this.swipedPostRight}>

                          {post.source === 'kickpros' ? (
                            <div className="post-container post-wrapper-kickpros">
                              <Link to={'/user/' + post.user.username}>
                                <img className="post-avatar" src={Helper.avatarImage(post.user)} alt="" />
                              </Link>
                              <div className="post-message-wrapper">
                                <div className="post-header">
                                  <Link to={'/user/' + post.user.username} className="post-username">{post.user.nickname}</Link>
                                  {' '}
                                  {post.user.admin ? (
                                    <span className="post-message-admin">Admin</span>
                                  ) : null}
                                  {' '}
                                  <span className="post-time">{Helper.timeAgo(post.created, txt, serverTime)}</span>
                                </div>
                                <div className="post-message-container">
                                  {post.deleted ? (
                                    <div className="post-message post-message-deleted">
                                      <i>{txt.posts.youDeletedThisPost}</i>
                                      {auth && post.user.id === auth.id ? (
                                        <div className="post-undo" onClick={() => { this.updatePost('delete', post, true); }}>
                                          <span className="post-undo-icon icon-undo"></span>{txt.posts.undo}
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                  {(auth && post.post_reports.length && post.post_reports[0].user_id === auth.id) || (!auth && this.state.reportedPosts.indexOf(post.id) !== -1) ? (
                                    <div className="post-message post-message-reported">
                                      <i>{txt.posts.youReportedThisPost}</i>
                                      <div className="post-undo" onClick={() => { this.updatePost('report', post, true); }}>
                                        <span className="post-undo-icon icon-undo"></span>{txt.posts.undo}
                                      </div>
                                    </div>
                                  ) : null}
                                  {!post.deleted && !((auth && post.post_reports.length && post.post_reports[0].user_id === auth.id) || (!auth && this.state.reportedPosts.indexOf(post.id) !== -1)) ? (
                                    <Linkify className="post-message" properties={{target: '_blank'}}>
                                      {post.text.replace(/\n\s*\n/g, '\n').split('\n').map((item, index) => {
                                        return <span key={index}>{item}<br /></span>;
                                      })}
                                    </Linkify>
                                  ) : null}
                                </div>
                                <div className="post-options-container">
                                  {!auth || post.user.id !== auth.id ? (
                                    <div className="post-options-icon icon-flag" onClick={() => { this.updatePost('report', post); }}></div>
                                  ) : null}
                                  {auth && post.user.id === auth.id ? (
                                    <div className="post-options-icon icon-x" onClick={() => { this.updatePost('delete', post); }}></div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {post.source === 'twitter' ? (
                            <div className="post-container post-wrapper-twitter">
                              <a href={'https://mobile.twitter.com/' + post.twitter_screen_name} target="_blank" rel="noopener noreferrer" className="post-twitter-img-container">
                                <img className="post-twitter-img" src={Helper.twitterProfileImage(post.twitter_profile_image_url)} alt="" />
                              </a>
                              <div className="post-message-wrapper">
                                <div className="post-header">
                                  <a href={'https://mobile.twitter.com/' + post.twitter_screen_name} target="_blank" rel="noopener noreferrer" className="post-username">{post.twitter_username}</a>
                                  <a href={'https://mobile.twitter.com/' + post.twitter_screen_name + '/status/' + post.twitter_tweet_id} target="_blank" rel="noopener noreferrer" className="post-message-twitter">{txt.posts.viaTwitter}</a>
                                  <span className="post-time">{Helper.timeAgo(post.created, txt, serverTime)}</span>
                                </div>
                                <div className="post-message-container">
                                  <Linkify className="post-message" properties={{target: '_blank',  rel: 'noopener noreferrer'}}>
                                    {post.text.replace(/\n\s*\n/g, '\n').split('\n').map((item, index) => {
                                      return <span key={index}>{item}<br /></span>;
                                    })}
                                  </Linkify>
                                  {post.twitter_media ? (
                                    <div className="post-media">
                                      {post.twitter_media_type === 'video' || post.twitter_media_type === 'animated_gif' ? (
                                        <div className="play-button"></div>
                                      ) : null}
                                        <a href={'https://mobile.twitter.com/' + post.twitter_screen_name + '/status/' + post.twitter_tweet_id} target="_blank" rel="noopener noreferrer">
                                          <img src={Helper.twitterProfileImage(post.twitter_media)} alt="" />
                                        </a>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="post-options-container">
                                  <a href={'https://twitter.com/intent/tweet?in_reply_to=' + post.twitter_tweet_id} target="_blank" rel="noopener noreferrer">
                                    <div className="post-options-icon icon-reply"></div>
                                  </a>
                                  <a href={'https://twitter.com/intent/retweet?tweet_id=' + post.twitter_tweet_id} target="_blank" rel="noopener noreferrer">
                                    <div className="post-options-icon icon-retweet"></div>
                                  </a>
                                  <a href={'https://twitter.com/intent/favorite?tweet_id=' + post.twitter_tweet_id} target="_blank" rel="noopener noreferrer">
                                    <div className="post-options-icon icon-star"></div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </Swipeable>
                      </div>
                    </CSSTransition>
                  );
                })
              ) : (
                <div className="no-posts-yet">{txt.posts.noPostsYet}</div>
              )}
            </div>
            {this.state.morePosts ? (
              <div className={'posts-loading-row' + (!this.state.fetchingMorePostsError ? ' spinner' : '')} onClick={() => { this.state.fetchingMorePostsError && this.fetchMorePosts(true); }}>
                <span>{this.state.fetchingMorePostsError ? 'Load more' : ''}</span>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Element;
