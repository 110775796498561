import { connect } from 'react-redux';
import { setServerTime, addMessage, setNetworkError } from '../../actions';
import mapToComponent from './PostsComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league,
  serverTime: state.serverTime
});

const mapDispatchToProps = dispatch => ({
  setServerTime: serverTime => dispatch(setServerTime(serverTime)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
