import { combineReducers } from 'redux';
import txt from './txt';
import auth from './auth';
import placeBet from './placeBet';
import serverTime from './serverTime';
import league from './league';
import matches from './matches';
import matchdayChips from './matchdayChips';
import appLoading from './appLoading';
import contentLoading from './contentLoading';
import messages from './messages';
import networkError from './networkError';
import location from './location';
import loginLocation from './loginLocation';
import nav from './nav';
import subNav from './subNav';
import userNav from './userNav';
import userScore from './userScore';
import userGroups from './userGroups';
import userNotifications from './userNotifications';

export default combineReducers({
  txt,
  auth,
  placeBet,
  serverTime,
  league,
  matches,
  matchdayChips,
  appLoading,
  contentLoading,
  messages,
  networkError,
  location,
  loginLocation,
  nav,
  subNav,
  userNav,
  userScore,
  userGroups,
  userNotifications
});
