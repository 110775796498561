import React from 'react';
import Transition from 'react-transition-group/Transition';

class PlaceBetOverlayComponent extends React.PureComponent {

  render() {
    const { placeBet, closePlaceBet } = this.props;

    return (
      <Transition in={placeBet && placeBet.active} timeout={200}>
      {state => (
        <div className={'place-bet-overlay ' + state} onClick={closePlaceBet}>
          <div className="icon-x"></div>
        </div>
      )}
      </Transition>
    );
  }
}

export default PlaceBetOverlayComponent;