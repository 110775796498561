import { connect } from 'react-redux';
import { setLocation, addMessage } from '../../../actions';
import mapToComponent from './RecoverPasswordComponent';

const mapStateToProps = state => ({
  auth: state.auth,
  txt: state.txt
});

const mapDispatchToProps = dispatch => ({
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setLocation: location => dispatch(setLocation(location))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
