let Config = {
  env: 'production',
  apiVersion: 'v3.0.0',
  apiURL: {
    development: 'http://localhost:8765/api',
    staging: 'https://qa.kickpros.com/api',
    production: 'https://kickpros.com/api'
  },
  imgURL: {
    development: 'http://localhost:8765/img',
    staging: 'https://qa.kickpros.com/img',
    production: 'https://kickpros.com/img'
  },
  facebookRedirect: {
    development: 'https://qa.kickpros.app',
    staging: 'https://qa.kickpros.app',
    production: 'https://kickpros.app'
  },
  instagramRedirect: {
    development: 'https://qa.kickpros.app',
    staging: 'https://qa.kickpros.app',
    production: 'https://kickpros.app'
  },
  checkInterval: 8,
  points: {
    result: 8,
    goals_diff: 3,
    goals: 2,
    ht_margin: 0.1
  }
};

Config.debug = Config.env !== 'production';

if (window.location.host === 'localhost:3000' || window.location.host === 'kickpros:3000') {
  Config.env = 'development';
} else if (window.location.host.substr(0, 2) === 'qa') {
  Config.env = 'staging';
}

Config.apiURL = Config.apiURL[Config.env];
Config.imgURL = Config.imgURL[Config.env];
Config.facebookRedirect = Config.facebookRedirect[Config.env];
Config.instagramRedirect = Config.instagramRedirect[Config.env];

export default Config;
