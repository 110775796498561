const auth = (state = null, action) => {
  switch (action.type) {
    case 'SET_USER':
      if (action.user === null) {
        localStorage.removeItem('auth');
        return null;
      }
      try {
        localStorage.setItem('auth', JSON.stringify(action.user));
        return action.user;
      } catch (e) {
        return null;
      }
    case 'GET_USER':
      try {
        return state || JSON.parse(localStorage.getItem('auth'));
      } catch (e) {
        return null;
      }
    default:
      return state;
  }
};

export default auth;