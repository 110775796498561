import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import API from '../../../util/API';
import Helper from '../../../util/Helper';
import Config from '../../../config';
import ContentLoading from '../../../util/ContentLoading';

class Page extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      requestingFB: false,
      email: '',
      password: '',
      error: '',
      errorClassName: '',
      errorAnimation: false,
      inputErrorEmail: false,
      inputErrorPassword: false
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {
    const { setLocation, location, txt, addMessage, setNetworkError, setUser, match, setAppLoading } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);

    if (match && match.params.hash) {
      const redirect = match.params.redirect ? match.params.redirect : '';

      API.fetch({
        url: Config.apiURL + '/autoLogin',
        data: {
          hash: match.params.hash
        },
        txt,
        beforeSend: () => {
          ContentLoading.startContentLoading(setAppLoading);
        },
        complete: () => {
          ContentLoading.endContentLoading(setAppLoading);
        },
        error: () => {
          addMessage(txt.error, 'error');
        },
        networkError: (error) => {
          setNetworkError(error);
        },
        success: (response) => {
          if (response.success) {
            setUser(response.auth);
            if (redirect) {
              window.location.href = '/' + this.getRedirectUrl(redirect);
            }
          }
        }
      });
    }
  }

  getRedirectUrl (url) {
    if (!url) {
      return '';
    }
    url = decodeURIComponent(url);
    const redirects = {
      'PremierLeague': 'league/PremierLeague',
      '1.Bundesliga': 'league/1.Bundesliga',
      'LaLiga': 'league/LaLiga',
      'WorldCup': 'league/WorldCup',
      'ChampionsLeague': 'league/ChampionsLeague'
    };

    return redirects[url] || url;
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value, inputErrorEmail: false});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value, inputErrorPassword: false});
  }

  showError(error_code) {
    const { txt } = this.props;

    this.setState({
      errorAnimation: true,
      errorClassName: this.state.error ? 'shake' : 'popin',
      error: txt.signIn[error_code] || txt.error
    });
    setTimeout(() => { this.setState({errorAnimation: false}); }, 400);
  }

  signIn() {
    const { txt, setUser, addMessage, setNetworkError } = this.props;

    if (!this.state.email || !this.state.password) {
      this.setState({inputErrorEmail: !this.state.email, inputErrorPassword: !this.state.password});
      this.showError('signInErrorNoEmailAndPassword');
      return;
    }

    API.fetch({
      url: Config.apiURL + '/signInWithPassword',
      data: {
        email: this.state.email,
        password: this.state.password
      },
      txt,
      beforeSend: () => {
        this.setState({requesting: true});
      },
      complete: () => {
        this.setState({requesting: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        if (!response.success) {
          this.showError('LoginErrorEmailPassword');
        } else {
          setUser(response.auth) || addMessage(txt.error, 'error');
        }
      }
    });
  }

  signInWithFacebook() {
    var state = Helper.getHash();
    localStorage.setItem('loginState', state);
    window.location = 'https://www.facebook.com/v4.0/dialog/oauth?client_id=393704100749267&scope=public_profile,email&response_type=code&state=' + state + '&redirect_uri=' + Config.facebookRedirect + '/signup?platform=facebook';
  }

  signInWithInstagram() {
    var state = Helper.getHash();
    localStorage.setItem('loginState', state);
    window.location = 'https://api.instagram.com/oauth/authorize/?client_id=47b8053acdf14bb2b0dc80ebf6f3f83b&response_type=code&state=' + state + '&redirect_uri=' + Config.instagramRedirect + '/signup?platform=instagram';
  }

  render() {
    const { txt, auth, loginLocation } = this.props;

    return (
      <div className="content-wrapper">
        {auth ? (
          <Redirect to={loginLocation || '/'} />
        ) : (
          <div className="signin-wrapper">
            <div className="content-container">

              <div disabled={this.state.requestingFB} className={'third-party-login-button facebook-button' + (this.state.requestingFB ? ' loading-bar' : '')} onClick={() => { this.signInWithFacebook(); }}>
                <div className="icon icon-facebook third-party-login-button-icon"></div>
                <div className="third-party-login-button-text">{txt.signIn.buttonSignInWithFacebook}</div>
              </div>

              <div disabled={this.state.requestingInstagram} className={'third-party-login-button instagram-button' + (this.state.requestingInstagram ? ' loading-bar' : '')} onClick={() => { this.signInWithInstagram(); }}>
                <div className="icon icon-instagram third-party-login-button-icon"></div>
                <div className="third-party-login-button-text">{txt.signIn.buttonSignInWithInstagram}</div>
              </div>

              <CSSTransition
                in={this.state.errorAnimation}
                timeout={{
                  enter: 400,
                  exit: 0
                }}
                classNames={this.state.errorClassName}
              >
                <div className={'signin-message error' + (this.state.error ? ' active' : '')} dangerouslySetInnerHTML={{__html: this.state.error}} />
              </CSSTransition>

              <div className="signin-textfields">
                <input type="email" onChange={this.handleEmailChange} value={this.state.email} className={'textfield signin-textfield' + (this.state.inputErrorEmail ? ' input-error' : '')} placeholder={txt.signIn.textfieldEmailPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
                <input type="password" onChange={this.handlePasswordChange} value={this.state.password} className={'textfield signin-textfield' + (this.state.inputErrorPassword ? ' input-error' : '')} placeholder={txt.signIn.textfieldPasswordPlaceholder} autoCorrect="off" autoCapitalize="off" spellCheck="false" maxLength="255" />
              </div>

              <button type="button" onClick={() => { this.signIn(); }} disabled={this.state.requesting} className={'button signin-button' + (this.state.requesting ? ' loading-bar' : '')}>{txt.signIn.buttonSignIn}</button>

              <div className="margin30" />

              <div className="signin-links">
                <Link to="/signup">{txt.signIn.linkSignUp}</Link><br />
                <Link to="/recover-password">{txt.signIn.linkForgotPassword}</Link>
              </div>

            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Page;
