import React from 'react';
import { Link } from 'react-router-dom';
import Config from '../../config';
import API from '../../util/API';
import Helper from '../../util/Helper';
import ContentLoading from '../../util/ContentLoading';

class Element extends React.PureComponent {
  
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      highscores_loading: false,
      highscores_more_loading: false,
      highscores: [],
      highscores_matchday: null,
      user_highscore: null,
      show_user_highscore: false,
      highscores_count: 0,
      more_highscores: null
    };
  }

  componentDidMount() {
    window.onscroll = window.onresize = () => {
      this.setUserRow();
      this.autoLoadHighscores();
    };

    if (this.props.highscores) {
      this.parseHighscoresResponse('Highscores', 1, false, this.props.highscores);
    } else {
      this.fetchHighscores();
    }
  }

  componentWillUnmount() {
    window.onscroll = window.onresize = null;
  }

  fetchHighscores(type = 'Highscores', matchday = null, offset = 0, limit = 20, load_more = false) {
    if (this.state.highscores_loading || this.state.highscores_more_loading || offset > this.state.highscores.length) {
      return;
    }

    const { txt, auth, league, setLeague, setServerTime, addMessage, setNetworkError, setContentLoading, setAppLoading, groupId } = this.props;

    if (matchday !== null && league) {
      matchday = Math.min(league.Season.matchdays, Math.max(1, matchday));
    }

    if (this.state.type === 'Matchday' && this.state.highscores_matchday === matchday) {
      return;
    }

    API.fetch({
      url: Config.apiURL + '/highscores',
      data: {
        type,
        offset,
        limit,
        matchday,
        groupId
      },
      txt,
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        load_more ? this.setState({highscores_more_loading: true}) : this.setState({highscores_loading: true});
        !this.state.type && ContentLoading.startContentLoading(league ? setContentLoading : setAppLoading);
      },
      complete: () => {
        load_more ? this.setState({highscores_more_loading: false}) : this.setState({highscores_loading: false});
        !this.state.type && ContentLoading.endContentLoading(league ? setContentLoading : setAppLoading);
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        response.league && setLeague(response.league);
        response.highscores && offset <= this.state.highscores.length && this.parseHighscoresResponse(type, matchday, load_more, response.highscores);
      }
    });
  }

  parseHighscoresResponse(type, matchday, load_more, response) {
    response.highscores && this.setState({
      type,
      highscores_matchday: matchday,
      highscores: !load_more ? response.highscores : this.state.highscores.concat(response.highscores),
      highscores_count: parseInt(response.highscores_count, 10),
      more_highscores: parseInt(response.more_highscores, 10) === 1,
      user_highscore: response.user_highscore || null
    });

    this.setUserRow();
    this.autoLoadHighscores();
  }

  setUserRow() {
    if (!this.state.highscores.length || !this.state.user_highscore) {
      return;
    }

    const user_row_fixed = document.querySelector('.highscore-row-user-fixed');

    if (!user_row_fixed) {
      return;
    }

    const user_row = document.querySelector('.highscore-row-user');
    const show_user_row = !user_row || Helper.offsetTop(user_row_fixed) < Helper.offsetTop(user_row);

    if (show_user_row !== this.state.show_user_highscore) {
      this.setState({show_user_highscore: show_user_row});
    }
  }

  autoLoadHighscores() {
    if (
      this.props.disabled ||
      !this.state.highscores.length ||
      !this.state.more_highscores ||
      !this.state.type
    ) {
      return;
    }

    const loading_row = document.querySelector('.highscore-loading-row');

    if (!loading_row) {
      return;
    }

    const loading_row_top = Helper.offsetTop(loading_row);
    const window_top = window.pageYOffset || document.documentElement.scrollTop;
    const window_height = window.innerHeight || document.documentElement.clientHeight;
    const window_bottom = window_top + window_height;

    if (window_bottom > loading_row_top) {
      this.fetchHighscores(this.state.type, null, this.state.highscores.length, null, true); // TODO MATCHDAY
    }
  }

  render() {
    const { txt, auth, league } = this.props;

    return (
      <div className="highscores-outer-wrapper">
        {this.state.highscores_count > 0 ? (
          <div className="highscores-amount">{this.state.highscores_count} {txt.pages.highscores.players}</div>
        ) : null}
        <div className={'highscores-wrapper' + (this.state.type ? ' highscores-wrapper-' + this.state.type.toLowerCase() : '') + (this.state.highscores_loading ? ' highscores-loading' : '')}>
          {this.state.type ? (
            <div className="highscores-header">
              <div className="highscores-header-title-container">
                <div className="highscores-header-title">
                  {txt.pages.highscores['header' + this.state.type].replace('{0}', this.state.highscores_matchday)}
                </div>
                {this.state.type === 'Matchday' ? (
                  <div className="highscores-header-nav-container">
                    <div className={'highscores-header-nav icon-left' + (this.state.highscores_matchday <= 1 ? ' disabled' : '')} onClick={() => { this.fetchHighscores('Matchday', this.state.highscores_matchday - 1); }}></div>
                    <div className={'highscores-header-nav icon-right' + (this.state.highscores_matchday >= league.Season.matchdays ? ' disabled' : '')} onClick={() => { this.fetchHighscores('Matchday', this.state.highscores_matchday + 1); }}></div>
                  </div>
                ) : null}

              <div className={'highscores-nav highscores-nav-highscores' + (this.state.type === 'Highscores' ? ' active' : '')} onClick={() => { this.fetchHighscores('Highscores'); }}>
                <span className="highscores-nav-icon icon-trophy"></span>
              </div>
              <div className={'highscores-nav highscores-nav-medaltally' + (this.state.type === 'MedalTally' ? ' active' : '')} onClick={() => { this.fetchHighscores('MedalTally'); }}>
                <span className="highscores-nav-icon icon-medal"></span>
              </div>
              {league.League.type === 'league' ? (
                <div className={'highscores-nav highscores-nav-matchdays' + (this.state.type === 'Matchdays' ? ' active' : '')} onClick={() => { this.fetchHighscores('Matchdays'); }}>
                  <span className="highscores-nav-icon icon-calendar"></span>
                  <span className="highscores-nav-matchday-star icon-star"></span>
                </div>
              ) : null}
              {league.League.type === 'league' ? (
                <div className={'highscores-nav highscores-nav-matchday' + (this.state.type === 'Matchday' ? ' active' : '')} onClick={() => { this.fetchHighscores('Matchday', league.Season.active_matchday); }}>
                  <span className="highscores-nav-icon icon-calendar"></span>
                  <span className="highscores-nav-matchday-nr">{league.Season.active_matchday}</span>
                </div>
              ) : null}

            </div>
            </div>
          ) : null}

          {this.state.highscores.length && this.state.user_highscore ? (
            <Link to={'/user/' + this.state.user_highscore.user.username} className={'highscore-row highscore-row-user-fixed highlight' + (this.state.show_user_highscore ? ' active' : '')}>
              <div className={'highscore-row-place' + (this.state.user_highscore.place >= 100000 ? ' highscore-row-place-gt100000' : (this.state.user_highscore.place >= 10000 ? ' highscore-row-place-gt10000' : (this.state.user_highscore.place >= 1000 ? ' highscore-row-place-gt1000' : '')))}>{this.state.user_highscore.place}</div>
              <img className="highscore-row-avatar" src={Helper.avatarImage(this.state.user_highscore.user)} alt="" />
              <div className="highscore-row-nickname">{this.state.user_highscore.user.nickname}</div>
              {this.state.type === 'MedalTally' ? (
                <div className="highscore-row-points highscore-row-points-medaltally">
                  <div className="highscore-row-medal medal-color-gold">{this.state.user_highscore.medal_gold}</div>
                  <div className="highscore-row-medal medal-color-silver">{this.state.user_highscore.medal_silver}</div>
                  <div className="highscore-row-medal medal-color-bronze">{this.state.user_highscore.medal_bronze}</div>
                </div>
              ) : (
                <div className="highscore-row-points">{this.state.user_highscore.points} <span>{txt.pointsShort}</span></div>
              )}
            </Link>
          ) : null}

          <div className="highscore-rows">
            {this.state.highscores.length ? (
              this.state.highscores.map((highscore, index) => {
                return (
                  <Link to={'/user/' + highscore.user.username} className={'highscore-row' + (auth && auth.id === highscore.user.id ? ' highscore-row-user highlight' : '')} key={index}>
                    <div className={'highscore-row-place' + (highscore.place >= 100000 ? ' highscore-row-place-gt100000' : (highscore.place >= 10000 ? ' highscore-row-place-gt10000' : (highscore.place >= 1000 ? ' highscore-row-place-gt1000' : '')))}>{highscore.place}</div>
                    <img className="highscore-row-avatar" src={Helper.avatarImage(highscore.user)} alt="" />
                    <div className="highscore-row-nickname">{highscore.user.nickname}</div>
                    {this.state.type === 'MedalTally' ? (
                      <div className="highscore-row-points highscore-row-points-medaltally">
                        <div className="highscore-row-medal medal-color-gold">{highscore.medal_gold}</div>
                        <div className="highscore-row-medal medal-color-silver">{highscore.medal_silver}</div>
                        <div className="highscore-row-medal medal-color-bronze">{highscore.medal_bronze}</div>
                      </div>
                    ) : (
                      <div className="highscore-row-points">{highscore.points} <span>{txt.pointsShort}</span></div>
                    )}
                  </Link>
                );
              })
            ) : (
              <div className="highscores-no-content">
                {txt.pages.highscores.noHighscores}
              </div>
            )}
          </div>
          {this.state.more_highscores ? (
            <div className={'highscore-loading-row spinner' + (this.state.show_user_highscore ? ' highscore-has-fixed-user' : '')}></div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Element;
