import React from 'react'
import Config from '../config'

const Helper = {

  isLoggedIn: auth => {
    return auth || localStorage.getItem('auth')
  },

  getLeague: league => {
    return league || (localStorage.getItem('league') ? JSON.parse(localStorage.getItem('league')) : null);
  },

  isLocation: (location, is_location) => {

    if (!location) {
      return;
    }

    if (!Array.isArray(is_location)) {
      is_location = [is_location];
    }

    let location_found = false;

    is_location.forEach(item => {
      if (location.substring(0, item.length) === item) {
        location_found = true;
      }
    })

    return location_found;
  },

  replace: (str, ...valuesForPlaceholders) => {
    const placeholderRegex = /(\{\d+\})/;
    let parts = str
      .split(placeholderRegex)
      .filter(textPart => !!textPart)
      .map((textPart, index) => {
        if (textPart.match(placeholderRegex)) {
          const valueForPlaceholder = valuesForPlaceholders[textPart.slice(1, -1)];
          return React.isValidElement(valueForPlaceholder)
            ? React.Children.toArray(valueForPlaceholder).map(component => ({...component, key: index.toString()}))
            : valueForPlaceholder;
        }
        return textPart;
      })
    return parts;
  },

  removeFromArray: function(array, element) {
    return array.filter(e => e !== element);
  },

  avatarImage: function(user) {
    if (user && user.avatar_image) {
      return Config.imgURL + '/avatar/' + user.avatar_image + '?' + (user.avatar_image_hash || 1);
    }
    return Config.imgURL + '/avatar.svg';
  },

  twitterProfileImage: function(img) {
    return img;
  },

  twitterMedia: function(img) {
    return img;
  },

  favClub: function (auth, club_id) {
    return auth && club_id !== 0 && [auth.fav1, auth.fav2, auth.fav3].indexOf(club_id) !== -1;
  },

  getDate: function(isoDate) {
    var arr = isoDate.split(/[- :T+]/);
    return new Date((arr[0] || 0), (arr[1]-1 || 0), (arr[2] || 0), (arr[3] || 0), (arr[4] || 0), (arr[5] || 0));
  },

  getDateTimezone: function(isoDate, serverTime) {
    return new Date(this.getDate(isoDate).getTime() + ((serverTime && serverTime.timezoneOffset ? serverTime.timezoneOffset : 0) * 1000));
  },

  getHash: function(length) {
    !length && (length = 8);
		const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let string = '';
    for (let i = 0; i < length; i++) {
      string += characters.charAt(Math.floor(Math.random() * characters.length));
    }
		return string;
  },

  getIsoDateT: function (date) {
    return date.getUTCFullYear() +
      '-' + ('0' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' + ('0' + date.getUTCDate()).slice(-2) +
      'T' + ('0' + date.getUTCHours()).slice(-2) +
      ':' + ('0' + date.getUTCMinutes()).slice(-2) +
      ':' + ('0' + date.getUTCSeconds()).slice(-2) +
      '+00:00';
  },

  getIsoDate: function (date) {
    return date.getUTCFullYear() +
      '-' + ('0' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' + ('0' + date.getUTCDate()).slice(-2) +
      ' ' + ('0' + date.getUTCHours()).slice(-2) +
      ':' + ('0' + date.getUTCMinutes()).slice(-2) +
      ':' + ('0' + date.getUTCSeconds()).slice(-2);
  },

  matchStarted: function(match, serverTime) {
    if (!serverTime || !serverTime.date) {
      return false;
    }
    return serverTime && !match.postponed && this.getDate(match.match_time) <= this.getDate(serverTime.date);
  },

  matchIsLive: function(match, serverTime) {
    if (!serverTime || !serverTime.date) {
      return false;
    }
    return serverTime && this.matchStarted(match, serverTime) && parseInt(match.finished, 10) === 0;
  },

  fullDate: function(isoDate, serverTime, txt, with_time, full_monthname) {
    const date = this.getDateTimezone(isoDate, serverTime);
    let fullDate = date.getDate() + '. ' + txt.date[full_monthname ? 'monthName' : 'monthNameAbr'][date.getMonth()] + ' ' + date.getFullYear();
    with_time && (fullDate += ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2));
    return fullDate;
  },

  teamGoals: function (ident, match) {
    return match.penalty_shootout ? match['goals_' + ident + '_ps'] : (match.overtime ? match['goals_' + ident + '_ot'] : match['goals_' + ident]);
  },

  teamDefeated: function(ident, match) {
    const identSwap = ident === 'home' ? 'guest' : 'home';
    return this.teamGoals(identSwap, match) > this.teamGoals(ident, match);
  },

  matchTimeOrScore: function(match, serverTime, txt, onlyTime) {
    if (match.postponed) {
      return txt.PostponedShort;
    } else if (this.matchStarted(match, serverTime)) {
      return (
        <div className="match-score">
          <span className="match-score-goals">
            {this.teamGoals('home', match) + ' : ' + this.teamGoals('guest', match)}
          </span>
          {match.overtime || match.penalty_shootout ? (
            <span className="match-score-notice">
              {match.penalty_shootout ? txt.pso : txt.aet}
            </span>
          ) : null}
        </div>
      );
    }
    return this.matchTime(match.match_time, serverTime, txt, onlyTime);
  },

  matchIsStarting: function(match, serverTime) {
    if (!serverTime || !serverTime.date) {
      return false;
    }
    const matchtime = Math.round(this.getDate(match.match_time).getTime() / 1000);
    const timestamp = Math.round(this.getDate(serverTime.date).getTime() / 1000);
    return !match.postponed && (matchtime - timestamp > 0 && (matchtime - timestamp <= 3600 * 16 || this.isToday(match.match_time, serverTime)));
  },

  timeAgo: function(isoDate, txt, serverTime) {
    const date = this.getDateTimezone(isoDate, serverTime);
    const timestamp = Math.round(new Date(date).getTime() / 1000);
    const time = Math.round(new Date().getTime() / 1000);
    const time_diff = time - timestamp;

    // First check if we are within 6 hours
    if (time_diff <= 3600 * 6) {
      // If less than 30 seconds return "just now"
      if (time_diff < 30) {
        return txt.date.justNow;
      }
      // If less than 90 seconds return "1 minute ago"
      else if (time_diff < 90) {
        return txt.date.minuteAgo;
      }
      // If less than 50 minutes seconds return "x minutes ago"
      else if (time_diff < 50 * 60) {
        return txt.date.minutesAgo.replace('{0}', Math.round(time_diff / 60));
      }
      // If less than 90 minutes seconds return "1 hour ago"
      else if (time_diff < 90 * 60) {
        return txt.date.hourAgo;
      }
      // Else return "x hours ago"
      else {
        return txt.date.hoursAgo.replace('{0}', Math.round(time_diff / 3600));
      }
    }
    // If time is greater than 6 hours and day is yesterday, return "yesterday"
    else if (this.isYesterday(isoDate)) {
      return txt.date.yesterday;
    }
    // If time is greater than 6 hours and day is today, return past hours
    else if (this.isToday(isoDate)) {
      return txt.date.hoursAgo.replace('{0}', Math.round(time_diff / 3600));
    }
    // If time is in past 6 days, return weekday name
    else if (time_diff < 3600 * 24 * 6) {
      return txt.date.dayOfWeek[date.getDay()];
    }
    // If time is within 4 month or this year return month and day
    else if (time_diff < 24 * 3600 * 30 * 4) {
      return date.getDate() + '. ' + txt.date.monthNameAbr[date.getMonth()];
    }
    // Else return day month and year
    else {
      return date.getDate() + '. ' + txt.date.monthNameAbr[date.getMonth()] + ' ' + date.getFullYear();
    }
  },

  // Return the short date with full month name and add the year if older than 6 month (and shorten month name if so)
  dateAgo: function (isoDate, txt) {
    const now = new Date();
    const date = this.getDate(isoDate);

    // Have 6 months passed?
    const longDate = (now - date) > (30.5 * 6 * 24 * 3600 * 1000);

    // Day
    let returnDate = date.getDate() + '. ';

    // Month
    returnDate += txt.date[longDate ? 'monthNameAbr' : 'monthName'][date.getMonth()];

    // Year
    longDate && (returnDate += ' ' + date.getFullYear());

    return returnDate;
  },

  timeLeft: function (isoDate, serverTime, txt, labels) {
    if (!serverTime || !serverTime.date) {
      return '';
    }

    const matchtime = Math.round(this.getDate(isoDate).getTime() / 1000);
    const timestamp = Math.round(this.getDate(serverTime.date).getTime() / 1000);
    const timeDiff = matchtime - timestamp;
    const labelShort = labels && labels === 'long' ? '' : 'Short';

    // If time greater than 30 days, return weeks
    if (timeDiff > 3600 * 24 * 30) {
      const weeks = Math.round(timeDiff / (3600 * 24 * 7));
      const label = labels ? (weeks === 1 ? txt.date['Week' + labelShort] : txt.date['Weeks' + labelShort]) : '';
      return weeks + ' ' + label;

    // If time greater than 23 hours, return days
    } else if (timeDiff > 3600 * 23) {
      const days = Math.round(timeDiff / (3600 * 24));
      const label = labels ? (days === 1 ? txt.date['Day' + labelShort] : txt.date['Days' + labelShort]) : '';
      return days + ' ' + label;

    // If time greater than 59 minutes, return hours
    } else if (timeDiff > 59 * 60) {
      const hours = Math.round(timeDiff / 3600);
      const label = labels ? (hours === 1 ? txt.date['Hour' + labelShort] : txt.date['Hours' + labelShort]) : '';
      return hours + ' ' + label;

    // Else return minutes
    } else {
      const minutes = Math.max(1, Math.round(timeDiff / 60));
      const label = labels ? (minutes === 1 ? txt.date['Minute' + labelShort] : txt.date['Minutes' + labelShort]) : '';
      return minutes + ' ' + label;
    }
  },


  // TODO Only time

  matchTime: function (isoDate, serverTime, txt, onlyTime) {
    const matchtime = Math.round(this.getDate(isoDate).getTime() / 1000);
    const timestamp = Math.round(this.getDate(serverTime.date).getTime() / 1000);

    // Force only time
    if (onlyTime) {
      const date_timezone = this.getDateTimezone(isoDate, serverTime);
      return ('0' + date_timezone.getHours()).slice(-2) + ':' + ('0' + date_timezone.getMinutes()).slice(-2);
    }

    // Abort if match started
    if (matchtime - timestamp <= 0) {
      return '';
    }
    // Less than a minute
    //if (matchtime - timestamp < 60) {
    //  return matchtime - timestamp + ' ' + txt.date.secondsShort
    //}
    // Less than an hour
    if (matchtime - timestamp < 3600) {
      return Math.ceil((matchtime - timestamp) / 60) + ' ' + txt.date.minutesShort;
    }
    const date_timezone = this.getDateTimezone(isoDate, serverTime);

    // Less than 16 hours or today show time
    if (matchtime - timestamp <= 3600 * 16 || this.isToday(isoDate, serverTime)) {
      return ('0' + date_timezone.getHours()).slice(-2) + ':' + ('0' + date_timezone.getMinutes()).slice(-2);
    }
    // Day of week (Monday, Tuesday, ...)
    if (this.isThisWeek(isoDate, serverTime)) {
      return txt.date.dayOfWeekAbr[date_timezone.getDay()] + ' ' + ('0' + date_timezone.getHours()).slice(-2) + ':' + ('0' + date_timezone.getMinutes()).slice(-2);
    }
    // Day and month (16. Jan, 1. Feb, ...)
    return date_timezone.getDate() + '. ' + txt.date.monthNameAbr[date_timezone.getMonth()];
  },

  matchTimeTitle: function (isoDateShort, serverTime, txt) {
    if (!serverTime || !txt) {
      return '';
    }
    const isoDate = this.getIsoDateT(new Date(isoDateShort + 'T00:00:00'));

    if (this.isToday(isoDate, serverTime)) {
      return txt.date.today;
    }
    if (this.isTomorrow(isoDate, serverTime)) {
      return txt.date.tomorrow;
    }
    if (this.isYesterday(isoDate, serverTime)) {
      return txt.date.yesterday;
    }
    const date = this.getDateTimezone(isoDate, serverTime);
    return txt.date.dayOfWeek[date.getDay()] + ', ' + date.getDate() + '. ' + txt.date.monthNameAbr[date.getMonth()] + ' ' + date.getFullYear();
  },

  isDate: function (date, isoDate, serverTime) {
    const givenDate = serverTime ? this.getDateTimezone(isoDate, serverTime) : this.getDate(isoDate);
    return givenDate.getFullYear() === date.getFullYear() && givenDate.getDate() === date.getDate() && givenDate.getMonth() === date.getMonth();
  },

  isThisWeek: function (isoDate, serverTime) {
    const now = this.getDate(serverTime.date).getTime();
    const week = now + 6 * 24 * 60 * 60 * 1000;
    const check = this.getDate(isoDate).getTime();
    return check > now && check < week;
  },

  isTomorrow: function (isoDate, serverTime) {
    return this.isDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), isoDate, serverTime);
  },

  isYesterday: function (isoDate, serverTime) {
    return this.isDate(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), isoDate, serverTime);
  },

  isToday: function (isoDate, serverTime) {
    return this.isDate(new Date(), isoDate, serverTime);
  },

  matchResult: function (goals_home, goals_guest) {
    return goals_home === goals_guest ? 'draw' : (goals_home > goals_guest ? 'home' : 'guest');
  },

  tendencyPoints: function(perc) {
    perc = parseFloat(perc, 10);
    if      (perc > 0.45) return 0; // From 45% get 0 P
    else if (perc > 0.40) return 1; // From 40% get 1 P (-5)
    else if (perc > 0.32) return 2; // From 32% get 2 P (-8)
    else if (perc > 0.24) return 3; // From 24% get 3 P (-8)
    else if (perc > 0.16) return 4; // From 16% get 4 P (-8)
    else if (perc > 0.12) return 5; // From 12% get 5 P (-4)
    else if (perc >  0.8) return 6; // From  8% get 6 P (-4)
    else if (perc >  0.4) return 7; // From  4% get 7 P (-4)
    else if (perc >=   0) return 8; // From  0% get 8 P (-4)
    return 0;
  },

  placeBetPoints: function(match, bet, stateBet, serverTime) {
    if (!bet) {
      bet = {
        bet_home: 0,
        bet_guest: 0,
        tendencies: 0,
        joker_x2: 0,
        joker_ht: '',
        joker_ht_chips: 0
      };
    }

    match.started = this.matchStarted(match, serverTime);
    match.result = this.matchResult(match.goals_home, match.goals_guest);
    bet.result = this.matchResult(bet.bet_home, bet.bet_guest);

    let placeBetPoints = {
      result: 8,
      goals_diff: 3,
      goals_home: 2,
      goals_guest: 2,
      tendency: 0,
      joker_ht: '',
      joker_x2: 0,
      tendency_stat: {
        bet: bet.result,
        draw: 0,
        home: 0,
        guest: 0,
        total: 0,
        draw_perc: 0,
        home_perc: 0,
        guest_perc: 0,
        draw_points: 0,
        home_points: 0,
        guest_points: 0,
        bet_draw_perc: 0,
        bet_home_perc: 0,
        bet_guest_perc: 0,
        bet_draw_points: 0,
        bet_home_points: 0,
        bet_guest_points: 0
      }
    }

    if (match.started) {
      placeBetPoints.result = match.result === bet.result ? Config.points.result : 0;
      placeBetPoints.goals_diff = match.goals_home - match.goals_guest === bet.bet_home - bet.bet_guest ? Config.points.goals_diff : 0;
      placeBetPoints.goals_home = match.goals_home === bet.bet_home ? Config.points.goals : 0;
      placeBetPoints.goals_guest = match.goals_guest === bet.goals_guest ? Config.points.goals : 0;
    }

    placeBetPoints.total = placeBetPoints.result + placeBetPoints.goals_diff + placeBetPoints.goals_home + placeBetPoints.goals_guest;

    if (true || parseInt(bet.tendencies, 10) === 1) {
      const stateBetResult = stateBet ? this.matchResult(stateBet.bet_home, stateBet.bet_guest) : bet.result;

      // Get the tendency points
      placeBetPoints.tendency_stat.draw = parseInt(match.tendency_draw, 10);
      placeBetPoints.tendency_stat.home = parseInt(match.tendency_home, 10);
      placeBetPoints.tendency_stat.guest = parseInt(match.tendency_guest, 10);
      placeBetPoints.tendency_stat.total = placeBetPoints.tendency_stat.draw + placeBetPoints.tendency_stat.home + placeBetPoints.tendency_stat.guest;

      // Remove the users own tendency if bet has been saved already
      if (bet.id) {
        placeBetPoints.tendency_stat[bet.result]--;
        placeBetPoints.tendency_stat.total--;
      }

      // Add own tendency
      placeBetPoints.tendency_stat.total++;

      ['home', 'guest', 'draw'].forEach(item => {
        // Without own bet
        placeBetPoints.tendency_stat[item + '_perc'] = placeBetPoints.tendency_stat[item] / Math.max(placeBetPoints.tendency_stat.total, 1);
        placeBetPoints.tendency_stat[item + '_points'] = this.tendencyPoints(placeBetPoints.tendency_stat[item + '_perc']);

        // With own bet
        placeBetPoints.tendency_stat['bet_' + item] = placeBetPoints.tendency_stat[item] + 1;
        placeBetPoints.tendency_stat['bet_' + item + '_perc'] = placeBetPoints.tendency_stat['bet_' + item] / Math.max(placeBetPoints.tendency_stat.total, 1);
        placeBetPoints.tendency_stat['bet_' + item + '_points'] = this.tendencyPoints(placeBetPoints.tendency_stat['bet_' + item + '_perc']);
      })

      if (match.started) {
        placeBetPoints.tendency = stateBetResult === match.result ? placeBetPoints.tendency_stat['bet_' + stateBetResult + '_points'] : 0;
      } else {
        placeBetPoints.tendency = placeBetPoints.tendency_stat['bet_' + stateBetResult + '_points'];
      }

      placeBetPoints.total += placeBetPoints.tendency;
    }

    // Halftime Joker
    placeBetPoints.joker_ht = (
      !bet.joker_ht || (match.started && match.result_ht !== bet.joker_ht)) ? 0 : (
        parseInt(bet.joker_ht_chips, 10) *
          (placeBetPoints.tendency[(bet.result === bet.joker_ht ? 'bet_' : '') + bet.joker_ht + '_perc'] <= Config.points.ht_margin &&
            (match.started || parseInt(bet.tendencies, 10) === 1 || true) ? 2 : 1)
    );

    placeBetPoints.total += placeBetPoints.joker_ht;

    if (bet.joker_x2) {
      placeBetPoints.joker_x2 = placeBetPoints.total;
      placeBetPoints.total += placeBetPoints.total;
    }

    return placeBetPoints;
  },

  offset: function (el) {
    if (!el) {
      return 0;
    }
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
  },

  offsetTop: function (el) {
    return this.offset(el).top;
  },

  offsetLeft: function (el) {
    return this.offset(el).left;
  },

  getMatchdayChips: function (matchdayChips, season_id, matchday) {
    return matchdayChips[season_id] && matchdayChips[season_id][matchday] ? parseInt(matchdayChips[season_id][matchday], 10) : 0;
  },

  firstName: function (name) {
    name = name.split(' ');
    return name.shift();
  },

  lastName: function (name) {
    name = name.split(' ');
    return name.pop();
  },

  setNotice: function (message, icon) {
    if (!message) {
      return;
    }
    sessionStorage.setItem('notice-message', message);
    sessionStorage.setItem('notice-icon', icon);
  },

  showNotice: function (notice, txt) {
    if (!notice) {
      return null;
    }

    return (
      <div className={'notice__wrapper' + (notice.icon === 'ok' ? ' notice__wrapper--success' : (notice.icon === 'x' ? ' notice__wrapper--error' : ''))}>
        <div className="notice__container">
          {notice.icon ? (
            <div className={'notice__icon icon-' + notice.icon}/>
          ) : null}
          <div className="notice__message">
            {txt.notices[notice.message]}
          </div>
        </div>
      </div>
    );
  },

  getNotice: function () {
    const message = sessionStorage.getItem('notice-message');
    const icon = sessionStorage.getItem('notice-icon');

    if (!message) {
      return null;
    }
    sessionStorage.removeItem('notice-message');
    sessionStorage.removeItem('notice-icon');

    return {
      message,
      icon
    };
  },

  getMatchdayName(txt, matchday, league) {
    if (!league || !matchday) {
      return '';
    }

    if (league.League.type === 'worldcup') {
      return txt.matchdayWorldCup['matchday' + matchday];
    }

    if (league.League.type === 'championsleague') {
      return txt.matchdayChampionsLeague['matchday' + matchday];
    }

    return txt.matchdayNr.replace('{0}', matchday);
  },

  shouldLeagueUpdate(nextLeague, currentLeague) {
    if (!nextLeague && !currentLeague) {
      return false;
    }

    if ((currentLeague === null && nextLeague) || (currentLeague && nextLeague === null)) {
      return true;
    }

    if (
      !nextLeague.League  || !currentLeague.League ||
      !nextLeague.Season  || !currentLeague.Season
    ) {
      return false;
    }

    if (
      nextLeague.Season.id !== currentLeague.Season.id ||
      nextLeague.Season.active !== currentLeague.Season.active ||
      nextLeague.Season.active_matchday !== currentLeague.Season.active_matchday ||
      nextLeague.Season.finished_matchday !== currentLeague.Season.finished_matchday ||
      nextLeague.Season.mobile_matchday !== currentLeague.Season.mobile_matchday
    ) {
      return true;
    }

    return false;
  },

  shouldAuthUpdate(nextAuth, currentAuth) {

    // TODO check

    if (!nextAuth && !currentAuth) {
      return false;
    }

    if ((currentAuth === null && nextAuth) || (currentAuth && nextAuth === null)) {
      return true;
    }

    if (
      nextAuth.avatar !== currentAuth.avatar ||
      nextAuth.avatar_image !== currentAuth.avatar_image ||
      nextAuth.avatar_image_hash !== currentAuth.avatar_image_hash ||
      nextAuth.check_group_comments !== currentAuth.check_group_comments ||
      nextAuth.coins !== currentAuth.coins ||
      nextAuth.coins_league_id !== currentAuth.coins_league_id ||
      nextAuth.deleted !== currentAuth.deleted ||
      nextAuth.email !== currentAuth.email ||
      nextAuth.email_activate_hash !== currentAuth.email_activate_hash ||
      nextAuth.email_bet_reminder !== currentAuth.email_bet_reminder ||
      nextAuth.email_bet_reminder_date !== currentAuth.email_bet_reminder_date ||
      nextAuth.email_changed !== currentAuth.email_changed ||
      nextAuth.email_changed_hash !== currentAuth.email_changed_hash ||
      nextAuth.email_summary !== currentAuth.email_summary ||
      nextAuth.facebook_id !== currentAuth.facebook_id ||
      nextAuth.fav1 !== currentAuth.fav1 ||
      nextAuth.fav2 !== currentAuth.fav2 ||
      nextAuth.fav3 !== currentAuth.fav3 ||
      nextAuth.settings !== currentAuth.settings ||
      nextAuth.free_attacks !== currentAuth.free_attacks ||
      nextAuth.free_attacks_claimed !== currentAuth.free_attacks_claimed ||
      nextAuth.free_coins_claimed !== currentAuth.free_coins_claimed ||
      nextAuth.free_power_shields !== currentAuth.free_power_shields ||
      nextAuth.free_power_shields_claimed !== currentAuth.free_power_shields_claimed ||
      nextAuth.free_shields !== currentAuth.free_shields ||
      nextAuth.free_shields_claimed !== currentAuth.free_shields_claimed ||
      nextAuth.language !== currentAuth.language ||
      nextAuth.league_id !== currentAuth.league_id ||
      nextAuth.nickname !== currentAuth.nickname ||
      nextAuth.password_changed !== currentAuth.password_changed ||
      nextAuth.password_reset_hash !== currentAuth.password_reset_hash ||
      nextAuth.title !== currentAuth.title ||
      nextAuth.username !== currentAuth.username
    ) {
      return true;
    }
    return false;
  }
}

export default Helper;
