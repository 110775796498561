import React from 'react';
import Helper from '../../util/Helper';
import { withRouter } from 'react-router-dom';

class Element extends React.PureComponent {

  render() {
    const { txt, auth, serverTime, openPlaceBet, type, matchObj, bet, clickable, user_id } = this.props;

    // TODO when its not your bet make sure the request only gets data that is allowed to show

    const isOwn = auth && auth.id === user_id;
    const matchStarted = Helper.matchStarted(matchObj, serverTime);

    const openLogin = () => {
      this.props.history.push('/signin');
    };

    const viewBet = () => {}; // TODO

    let betDisabled = false;
    let betBoxClassName = '';
    let betBoxContent = '';
    let betBoxAction = () => {};
    let betBoxMedal = false;
    let betBoxJokerX2 = false;
    let betBoxJokerHt = false;
    let betBoxShields = false;

    if (parseInt(matchObj.home_id, 10) === 0 || parseInt(matchObj.guest_id, 10) === 0) {
      betBoxClassName += ' disabled';
      betDisabled = true;
    }

    // If it's your bet or no user and match hasn't started, set place bet
    if (type === 'bet' && !bet && !matchStarted && (isOwn || !user_id)) {
      betBoxClassName += ' bet-box place-bet';
      betBoxContent = txt.bet;
      betBoxAction = () => { isOwn ? openPlaceBet(matchObj.id) : openLogin('placeBet'); };

    // If it's another ones bet and shields are up, open viewBet
    } else if (type === 'bet' && bet && !isOwn && !matchStarted && bet.shields !== 0) {
      betBoxClassName += ' bet-box bet-goals view-bet';
      betBoxAction = () => { auth ? viewBet(bet) : openLogin('viewBet'); };

    // If no bet was placed and the match started, show missing bet icon
    } else if (matchStarted && !bet) {
      betBoxClassName += ' bet-box bet-missed icon-denied';
      isOwn && (betBoxAction = () => { openPlaceBet(matchObj.id); });

    // If a bet was placed, show either points or bet
    } else if (bet && (type === 'bet' || matchStarted)) {

      // Jokers are shown when it's your bet, or the match has started, or all shields are down
      const showJokers = isOwn || matchStarted || bet.shields === 0;

      betBoxClassName += ' bet-box' +
        (type === 'points' ? (bet.points > 0 ? ' bet-scored' : ' bet-zero') : ' bet-goals') +
        (showJokers && bet.joker_x2 && bet.joker_ht ? ' joker-both' : '');

      clickable && (betBoxAction = () => {
        if (isOwn) {
          openPlaceBet(matchObj.id);
        } else {
          auth ? viewBet() : openLogin('viewBet');
        }
      });

      // Show shields
      if (type === 'bet' && !matchStarted && bet.shields !== 0) {
        // betBoxShields = Helper.shieldClass(bet.shields);
      }

      // Show jokers
      if (showJokers) {
        betBoxJokerHt = '';
        bet.joker_ht && (betBoxJokerHt = (parseInt(matchObj.finished, 10) === 1 && bet.joker_ht !== Helper.matchResult(matchObj.goals_home_ht, matchObj.goals_guest_ht)) ? ' joker-off' : true);
        bet.joker_x2 && (betBoxJokerX2 = (parseInt(matchObj.finished, 10) === 1 && bet.points === 0) ? ' joker-off' : true);
      }

      // Show bet or points
      if (type === 'points') {
        betBoxContent = bet.points;
        bet.medal_bronze && (betBoxMedal = 'bronze');
        bet.medal_silver && (betBoxMedal = 'silver');
        bet.medal_gold && (betBoxMedal = 'gold');
      } else {
        betBoxContent = bet.bet_home + ' : ' + bet.bet_guest;
      }
    }

    if (betBoxClassName) {
      return (
        <div className={betBoxClassName} onClick={betDisabled ? null : betBoxAction}>
          {betBoxContent}
          {betBoxMedal ? <div className={'medal medal-' + betBoxMedal} /> : null}
          {betBoxJokerHt ? <div className={'joker joker-ht' + (betBoxJokerHt !== true ? betBoxJokerHt : '')}>{txt.jokerHt}</div> : null}
          {betBoxJokerX2 ? <div className={'joker joker-x2' + (betBoxJokerX2 !== true ? betBoxJokerX2 : '')}>{txt.jokerX2}</div> : null}
          {betBoxShields ? <div className={'shield shield-' + betBoxShields}></div> : null}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(Element);
