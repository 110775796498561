import i18n from '../i18n';

const txt = (state = null, action) => {
  switch (action.type) {
    case 'GET_LANGUAGE':
      return i18n.i18n;
    case 'SET_LANGUAGE':
      return i18n._changeLanguage(action.language).i18n;
    default:
      return i18n.i18n;
  }
};

export default txt;