import { connect } from 'react-redux';
import { setUser, setLocation, addMessage, setNetworkError } from '../../../actions';
import mapToComponent from './SignUpComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  loginLocation: state.loginLocation
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  setLocation: location => dispatch(setLocation(location)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  addMessage: (message, className) => dispatch(addMessage(message, className))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);
