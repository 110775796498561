import React from 'react';
import { Redirect } from 'react-router-dom';

class Page extends React.PureComponent {

  componentDidMount() {
    const { location, setLocation, setLoginLocation } = this.props;

    window.scrollTo(0, 0);
    setLocation(location);
    setLoginLocation(null);
  }

  render() {
    const { txt, auth, setLanguage } = this.props;

    return (
      <div className="content-wrapper">
        {auth ? (
          <Redirect to="/" />
        ) : (
          <div className="content-container">
            <h1>{txt.pages.settings.headline}</h1>
            <div className="settings-wrapper">
              <h4>{txt.pages.settings.headlineLanguage}</h4>
              <div className="settings-container">
                <div className="settings-item">
                  <div className="settings-text">
                    English
                  </div>
                  <div className="settings-button" onClick={() => { setLanguage('en'); }}>
                    <div className="checkbox" data-status={txt.id === 'en' ? 'active' : 'neutral'}>
                      {txt.id === 'en' ? (
                        <div className="icon-ok" />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="settings-item">
                  <div className="settings-text">
                    Deutsch
                  </div>
                  <div className="settings-button" onClick={() => { setLanguage('de'); }}>
                    <div className="checkbox" data-status={txt.id === 'de' ? 'active' : 'neutral'}>
                      {txt.id === 'de' ? (
                        <div className="icon-ok" />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Page;
