import React from 'react';
import Config from '../../config';
import API from '../../util/API';
import Match from '../Match/MatchContainer';

class Element extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      league: null,
      matchday: null,
      matches: [],
      gettingMatchday: false
    };
  }

  componentDidMount() {
    const { matches_prop, league_prop, matchday_prop } = this.props;

    if (!matches_prop || !league_prop || !matchday_prop) {
      return;
    }

    this.setState({
      league: league_prop,
      matches: matches_prop,
      matchday: matchday_prop
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update with initial matches or when league changes

    if (nextProps.matches_prop.length && nextProps.league_prop && (!this.state.matches.length || !this.state.league || (this.state.league && nextProps.league_prop.Season.id !== this.state.league.Season.id))) {
      this.setState({
        league: nextProps.league_prop,
        matches: nextProps.matches_prop,
        matchday: nextProps.matchday_prop
      });

    // Update the matches in the table with new values

    } else if (nextProps.matches && nextProps.matches.length && nextProps.stateMatches && nextProps.stateMatches.length) {
      let nextMatches = [];
      this.state.matches.forEach(match => {
        nextMatches.push(nextProps.stateMatches[match.id] || match);
      });
      this.setState({matches: nextMatches});
    }
  }

  fetchMatchday(matchday) {
    const { txt, auth, league, setServerTime, addMessage, setNetworkError, setMatches, setMatchdayChips } = this.props;

    if (this.state.gettingMatchday || matchday <= 0 || matchday > league.Season.matchdays) {
      return null;
    }

    API.fetch({
      url: Config.apiURL + '/getMatchday/' + matchday,
      data: {
        league_id: league.League.id,
        season_id: league.Season.id
      },
      auth,
      league,
      setServerTime,
      beforeSend: () => {
        this.setState({gettingMatchday: true});
      },
      complete: () => {
        this.setState({gettingMatchday: false});
      },
      error: () => {
        addMessage(txt.error, 'error');
      },
      networkError: (error) => {
        setNetworkError(error);
      },
      success: (response) => {
        setMatches(response.matchday_data.matches);
        setMatchdayChips(league.Season.id, response.matchday, response.matchday_data.chips);
        this.setState({
          matchday: parseInt(response.matchday, 10) || null,
          matches: response.matchday_data.matches || []
        });
      }
    });
  }

  render() {
    const { txt, league, matches } = this.props;

    return (
      <div className={'matchday-wrapper' + (this.state.gettingMatchday ? ' getting-matchday' : '')}>
        {this.state.matches && this.state.matches.length ? (
          <div className="matchday-container">
            <div className="matchday-header-container">
              <div className="matchday-header">
                {txt.matchdayNr.replace('{0}', this.state.matchday)}
                <div className={'matchday-nav matchday-nav-prev icon-left' + (this.state.matchday === 1 ? ' disabled' : '')} onClick={() => { this.fetchMatchday(this.state.matchday - 1); }}></div>
                <div className={'matchday-nav matchday-nav-next icon-right' + (league && league.Season && this.state.matchday === league.Season.matchdays ? ' disabled' : '')} onClick={() => { this.fetchMatchday(this.state.matchday + 1); }}></div>
              </div>
            </div>
            <div className="matchday-matches">
              {this.state.matches.map((match, index) => {
                return (
                  <Match match={matches[match.id] || match} key={index} />
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Element;
