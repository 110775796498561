import { connect } from 'react-redux';
import { setServerTime, setLocation, setLoginLocation, setLeague, addMessage, setNetworkError, setAppLoading, setContentLoading } from '../../../actions';
import mapToComponent from './GroupComponent';

const mapStateToProps = state => ({
  txt: state.txt,
  auth: state.auth,
  league: state.league,
  serverTime: state.serverTime
});

const mapDispatchToProps = dispatch => ({
  setServerTime: serverTime => dispatch(setServerTime(serverTime)),
  setLeague: league => dispatch(setLeague(league)),
  addMessage: (message, className) => dispatch(addMessage(message, className)),
  setNetworkError: error => dispatch(setNetworkError(error)),
  setAppLoading: loading => dispatch(setAppLoading(loading)),
  setContentLoading: loading => dispatch(setContentLoading(loading)),
  setLocation: location => dispatch(setLocation(location)),
  setLoginLocation: location => dispatch(setLoginLocation(location))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mapToComponent);