const nav = (state = false, action) => {
  switch (action.type) {
    case 'OPEN_NAV':
      return true;
    case 'CLOSE_NAV':
      return false;
    case 'TOGGLE_NAV':
      return !state;
    default:
      return state;
  }
};

export default nav;