import React from 'react';
import { Link } from 'react-router-dom';

const ContentDE = () => (
  <div className="content-wrapper-text">
  <div className="content-text">
    <h1>Impressum</h1>
    <p>
      Die Webseiten unter kickpros.com sowie die KICKPros Android und iPhone App und die auf diesen Anwendungen vorgehaltenen Dienste werden dir angeboten von:
    </p>

    <p>
      <b className="b500">Stephan Wagner</b><br />
      Philipp-Häring-Str. 8<br />
      86157 Augsburg<br />
      +49 176 45783473<br />
      <a href="mailto:stephanwagner.me@gmail.com">stephanwagner.me@gmail.com</a>
    </p>

    <h2>Registrierung</h2>

    <p>
      Wenn du dich auf KICKPros registrierst musst du unseren <Link to="/terms" dangerouslySetInnerHTML={{__html: 'Nutzungs&shy;bedingungen'}} /> zustimmen. Außerdem empfehlen wir dir unsere <Link to="/privacy" dangerouslySetInnerHTML={{__html: 'Datenschutz&shy;erklärung'}} /> zu lesen.
    </p>

    <h2>Haftungsausschluss</h2>

    <p>
      Die Webseiten von KICKPros werden mit größtmöglicher Sorgfalt zusammengestellt und laufend aktualisiert. Die Aktualisierung der auf unseren Seiten enthaltenen Informationen (insbesondere Spielergebnisse, Punkte und Platzierungen) erfolgt hauptsächlich durch automatisierte Programme eigener und externer Dienste (wie <a href="https://www.openligadb.de" target="_blank" rel="noopener noreferrer">openLigaDB</a> oder <a href="https://www.sportmonks.com" target="_blank" rel="noopener noreferrer">SportMonks</a> zum Beispiel).
    </p>
    <p>
      Wir können trotz sorgfältiger und regelmäßiger Überprüfung der enthaltenen Informationen für deren Fehlerfreiheit, Vollständigkeit und Genauigkeit nicht garantieren. KICKPros haftet daher nicht für Schäden (materieller oder immaterieller Art), die in direktem oder indirektem Zusammenhang mit der Nutzung dieser Webseiten und Apps entstehen.
    </p>

    <h2>Fußball-Ligen und Vereine</h2>

    <p>
      Wir möchten ausdrücklich darauf hinweisen, dass KICKPros keinerlei Bezug zu offiziellen Fussball-Ligen oder deren Vereine hat. Die Icons und Namen der Vereine auf KICKPros wurden ähnlich der offiziellen Logos und Vereinsnamen gestaltet, um den Besuchern unserer Webseite größtmögliche Benutzerfreundlichkeit zu gewährleisten. Wir möchten aber darauf hinweisen, dass die Vereine auf unseren Seiten und Applikationen, obwohl sehr ähnlich zu den offiziellen Vereinen, als rein fiktional zu betrachten sind.
    </p>
    <p>
      Falls du der Ansicht bist, dass wir Copyright oder Markenrechte verletzen, bitten wir dich uns unter <a href="mailto:mail@kickpros.com">mail@kickpros.com</a> zu kontaktieren.
    </p>
  </div>
  </div>
);

export default ContentDE;
